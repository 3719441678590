<template>
   <div>
      <template v-if="!showFormComponent && isMounted">
         <vx-card v-if="!isMoral" class="mb-base mt-5">
            <div class="vx-row">
               <div class="vx-col w-full">
                  <div class="flex items-end px-3">
                     <feather-icon icon="MapPinIcon" svgClasses="w-5 h-5" class="mr-2 text-primary" />
                     <span class="font-medium text-lg leading-none bold">Domicilio</span>
                     <!-- <vs-button class="ml-auto" size="small" type="border"
                        @click.stop="showFormComponent = true">Editar</vs-button> -->
                  </div>
                  <vs-divider />
               </div>
            </div>
            <div class="vx-row">
               <!-- Information - Col 1 -->
               <div class="vx-col flex-1" id="address-info-col-1">
                  <table>
                     <tr>
                        <td class="font-semibold">País:</td>
                        <td>{{ investorData.country }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Calle:</td>
                        <td>{{ investorData.street }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">No. exterior:</td>
                        <td>{{ investorData.ext_number }}</td>
                     </tr>
                  </table>
               </div>
               <!-- /Information - Col 1 -->
               <!-- Information - Col 2 -->
               <div class="vx-col flex-1" id="address-info-col-2">
                  <table>
                     <tr>
                        <td class="font-semibold">No. interior:</td>
                        <td>{{ investorData.int_number }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Código postal:</td>
                        <td>{{ investorData.zipcode }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Estado:</td>
                        <td>{{ investorData.state }}</td>
                     </tr>

                  </table>
               </div>
               <!-- Information - Col 3 -->
               <div class="vx-col flex-1" id="address-info-col-3">
                  <table>
                     <tr>
                        <td class="font-semibold">Ciudad o población:</td>
                        <td>{{ investorData.city }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Colonia o urbanización:</td>
                        <td>{{ investorData.neighborhood }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Delegación o municipio:</td>
                        <td>{{ investorData.municipality }}</td>
                     </tr>
                  </table>
               </div>
            </div>
         </vx-card>
         <vx-card v-else class="mb-base mt-5">
            <div class="vx-row">
               <div class="vx-col w-full">
                  <div class="flex items-end px-3">
                     <feather-icon icon="MapPinIcon" svgClasses="w-5 h-5" class="mr-2 text-primary" />
                     <span class="font-medium text-lg leading-none bold">Domicilio</span>
                     <!-- <vs-button class="ml-auto" size="small" type="border"
                        @click.stop="showFormComponent = true">Editar</vs-button> -->
                  </div>
                  <vs-divider />
               </div>
            </div>
            <div class="vx-row">
               <!-- Information - Col 1 -->
               <div class="vx-col flex-1" id="address-info-col-1">
                  <table>
                     <tr>
                        <td class="font-semibold">País de residencia:</td>
                        <td>{{ investorData.country }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Calle:</td>
                        <td>{{ investorData.street }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">No. exterior:</td>
                        <td>{{ investorData.ext_number }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Télefono celular:</td>
                        <td>{{ investorData.phone }}</td>
                     </tr>
                  </table>
               </div>
               <!-- /Information - Col 1 -->
               <!-- Information - Col 2 -->
               <div class="vx-col flex-1" id="address-info-col-2">
                  <table>
                     <tr>
                        <td class="font-semibold">No. interior:</td>
                        <td>{{ investorData.int_number }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Código postal:</td>
                        <td>{{ investorData.zipcode }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Estado:</td>
                        <td>{{ investorData.state }}</td>
                     </tr>
                  </table>
               </div>
               <!-- /Information - Col 2 -->
               <!-- Information - Col 3 -->
               <div class="vx-col flex-1" id="address-info-col-3">
                  <table>
                     <tr>
                        <td class="font-semibold">Ciudad o población:</td>
                        <td>{{ investorData.city }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Colonia o urbanización:</td>
                        <td>{{ investorData.neighborhood }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Delegación o municipio:</td>
                        <td>{{ investorData.municipality }}</td>
                     </tr>
                  </table>
               </div>
               <!-- /Information - Col 3 -->
            </div>
         </vx-card>
      </template>
      <template v-else>
         <vx-card class="mb-base mt-5">
            <div class="vx-row">
               <div class="vx-col w-full">
                  <div class="flex items-end px-3">
                     <feather-icon icon="MapPinIcon" svgClasses="w-5 h-5" class="mr-2 text-primary" />
                     <span class="font-medium text-lg leading-none bold">Domicilio</span>
                     <vs-button class="ml-auto" size="small" type="border"
                        @click.stop="showFormComponent = false">Cancelar</vs-button>
                  </div>
                  <vs-divider />
               </div>
            </div>
            <forms-container :investor_id="InvestorId" :investor_status="InvestorStatus" :user_id="UserId"
               :moral-tabs="moralTabs" :pfae-tabs="pfaeTabs" @on-form-saved="onFormSaved" />
         </vx-card>
      </template>
   </div>
</template>
  
<script>
import FormsContainer from "@components/profile_shared/FormsContainer";
import investorInfoHelper from "@/helpers/investorInfoHelper";

export default {
   name: "InvestorAddressData",
   components: {
      FormsContainer
   },
   mixins: [investorInfoHelper],
   data() {
      return {
         isMounted: false,
         pfaeTabs: ['address'],
         moralTabs: ['address'],
         investorData: {},
         showFormComponent: false,
         isMoral: null
      }
   },
   async mounted() {
      this.isMounted = false
      this.showLoading(true)
      this.isMoral = this.userIsMoral(this.UserPersonType);
      await this.getInvestorData();
      this.isMounted = true
      this.showLoading(false)
   },
   methods: {
      async getInvestorData() {
         try {
            let response = await axios.get(`/api/v2/investor/${this.InvestorId}/getAddressDataProfile`);
            this.investorData = response.data;
         }
         catch (e) {
            this.warn(e);
            this.failed = true;
         }
      },
      async onFormSaved() {
         this.isMounted = false
         this.showFormComponent = false
         this.showLoading(true)
         await this.getInvestorData();
         this.isMounted = true
         this.showLoading(false)
      },
   }
}
</script>

<style scoped>
table {
   border-collapse: separate;
   border-spacing: .5em;
}
</style>
  