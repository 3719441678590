<template>
   <div>
      <template v-if="!showFormComponent && isMounted">
         <vx-card class="mb-base mt-5">
            <div class="vx-row">
               <div class="vx-col w-full">
                  <div class="flex items-end px-3">
                     <feather-icon icon="BarChart2Icon" svgClasses="w-5 h-5" class="mr-2 text-primary" />
                     <span class="font-medium text-lg leading-none bold">Perfil fiscal</span>
                     <vs-button v-if="!investorData.fiscalValidationTimestamp" class="ml-auto" size="small" type="border"
                        @click.stop="showFormComponent = true">Editar</vs-button>
                  </div>
                  <vs-divider />
               </div>
            </div>
            <div class="vx-row">
               <!-- Information - Col 1 -->
               <div class="vx-col flex-1" id="tax-info-col-1">
                  <table>
                     <tr>
                        <td class="font-semibold">RFC:</td>
                        <td>{{ investorData.rfc }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Nombre fiscal:</td>
                        <td>{{ investorData.taxName }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Código postal fiscal:</td>
                        <td>{{ investorData.taxZipcode }}</td>
                     </tr>
                  </table>
               </div>
               <!-- /Information - Col 1 -->
               <!-- Information - Col 2 -->
               <div class="vx-col flex-1" id="tax-info-col-2">
                  <table>
                     <tr>
                        <td class="font-semibold">Régimen fiscal:</td>
                        <td>{{ investorData.taxRegime }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">Uso de CFDI:</td>
                        <td>{{ investorData.cfdi }}</td>
                     </tr>
                     <tr>
                        <td class="font-semibold">información verificada:</td>
                        <td>{{ explicitDateFormat(investorData.fiscalValidationTimestamp) }}</td>
                     </tr>
                  </table>
               </div>
            </div>
         </vx-card>
      </template>
      <template v-else>
         <vx-card class="mb-base mt-5">
            <div class="vx-row">
               <div class="vx-col w-full">
                  <div class="flex items-end px-3">
                     <feather-icon icon="BarChart2Icon" svgClasses="w-5 h-5" class="mr-2 text-primary" />
                     <span class="font-medium text-lg leading-none bold">Perfil fiscal</span>
                     <vs-button class="ml-auto" size="small" type="border"
                        @click.stop="showFormComponent = false">Cancelar</vs-button>
                  </div>
                  <vs-divider />
               </div>
            </div>
            <user-tax-profile @on-form-saved="onFormSaved" />
         </vx-card>
      </template>
   </div>
</template>
   
<script>
import UserTaxProfile from '@components/profile_shared/UserTaxProfile';
import dateHelper from "@mixins/dateHelper";

export default {
   name: "InvestorTaxProfileData",
   components: {
      UserTaxProfile
   },
   mixins: [dateHelper],
   data() {
      return {
         isMounted: false,
         investorData: {},
         showFormComponent: false,
      }
   },
   async mounted() {
      this.isMounted = false
      this.showLoading(true)
      await this.getInvestorData();
      this.isMounted = true
      this.showLoading(false)
   },
   methods: {
      async getInvestorData() {
         try {
            let response = await axios.get(`/api/v2/investor/${this.InvestorId}/getTaxProfileDataProfile`);
            this.investorData = response.data;
         }
         catch (e) {
            this.warn(e);
            this.failed = true;
         }
      },
      async onFormSaved() {
         this.isMounted = false
         this.showFormComponent = false
         this.showLoading(true)
         await this.getInvestorData();
         this.isMounted = true
         this.showLoading(false)
      },
   }
}
</script>
<style scoped>
table {
   border-collapse: separate;
   border-spacing: .5em;
}
</style>