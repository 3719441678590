<template>
    <div>
        <vx-card class="mb-base mt-5">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="flex items-end px-3">
                        <feather-icon icon="DollarSignIcon" svgClasses="w-5 h-5" class="mr-2 text-primary" />
                        <span class="font-medium text-lg leading-none bold">Banco</span>
                    </div>
                    <vs-divider />
                </div>
            </div>
            <investor-bank-accounts v-if="isMounted" :is-moral="isMoral" :person-type="investorType"
                :user-id="currentInvestorUserId" :has-backoffice-access="hasBackofficeAccess" :user-data="base" />
        </vx-card>
    </div>
</template>
  
<script>
import InvestorBankAccounts from "@views/investor/profile/InvestorBankAccounts";
import investorInfoHelper from "@/helpers/investorInfoHelper";
export default {
    name: "InvestorBankData",
    components: {
        InvestorBankAccounts,
    },
    mixins: [investorInfoHelper],
    data() {
        return {
            isMounted: false,
            errorMssg: '',
            successMssg: '',
            investorType: null,
            isMoral: false,
            currentInvestorUserId: null,
            hasBackofficeAccess: null,

            // objeto principal
            base: {},
        }
    },
    async beforeMount() {
        this.showLoading(true);
        this.isMounted = false;
        const requiredObjects = ['user.personal', 'user.business.personal'];
        this.currentInvestorUserId = this.UserId;
        this.base = await this.getInvestorData(this.InvestorId, requiredObjects);
        this.investorType = this.getInvestorType(this.base);
        this.isMoral = this.userIsMoral(this.investorType);
        this.hasBackofficeAccess = this.$acl.check('isBackoffice');
        this.isMounted = true;
        this.showLoading(false);
    },
    computed: {
    },
}
</script>
  