<template>
  <div>
    <vx-card class="mb-6" v-if="isMounted">
      <h4 class="bold"><span>Identificación</span>. </h4>
      <div class="vx-row">
        <!-- Information - Col 0 -->
        <div class="vx-col flex-1 header-info-col-1">
          <table aria-describedby="general">
            <th scope="col"></th>
            <tr>
              <td class="font-semibold">Nombre completo:</td>
              <td class="pl-5">{{ beneficiary.complete_name }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Fecha de nacimiento:</td>
              <td class="pl-5">{{ dateStr(beneficiary) }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Correo electrónico:</td>
              <td class="pl-5">{{ beneficiary.email }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Teléfono celular:</td>
              <td class="pl-5">
                {{ `${beneficiary.country_calling_code} ${phoneFormat(beneficiary.phone)}` }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">Nacionalidad:</td>
              <td class="pl-5">{{ citizenshipName(beneficiary) }}</td>
            </tr>
            <tr>
              <td class="font-semibold">% de participación:</td>
              <td class="pl-5">{{ percentageFormat(beneficiary) }}</td>
            </tr>
          </table>
        </div>
        <!-- /Information - Col 0 -->
      </div>
      <br>
      <h4 class="bold"><span>Domicilio</span>. </h4>
      <div class="vx-row">
        <!-- Information - Col 1 -->
        <div class="vx-col flex-1 header-info-col-1">
          <table aria-describedby="general">
            <th scope="col"></th>
            <tr>
              <td class="font-semibold">Calle:</td>
              <td class="pl-5">{{ address.street }}</td>
            </tr>
            <tr>
              <td class="font-semibold">No. exterior:</td>
              <td class="pl-5">{{ address.ext_number }}</td>
            </tr>
            <tr>
              <td class="font-semibold">No. interior:</td>
              <td class="pl-5">{{ address.int_number }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Código postal:</td>
              <td class="pl-5">{{ zipCode(beneficiary) }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Colonia o urbanización:</td>
              <td class="pl-5">{{ neighborhoodName(beneficiary) }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Delegación o municipio:</td>
              <td class="pl-5">{{ address.municipality }}</td>
            </tr>
          </table>
        </div>
        <!-- /Information - Col 1 -->

        <!-- Information - Col 2 -->
        <div class="vx-col flex-1 header-info-col-1">
          <table aria-describedby="general">
            <th scope="col"></th>
            <tr>
              <td class="font-semibold">País:</td>
              <td class="pl-5">{{ countryName(beneficiary) }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Estado:</td>
              <td class="pl-5">{{ stateFormat(beneficiary) }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Ciudad o población:</td>
              <td class="pl-5">{{ cityFormat(beneficiary) }}</td>
            </tr>
          </table>
        </div>
        <!-- /Information - Col 2 -->
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dateHelper from "@mixins/dateHelper";
import formHelper from "@mixins/formHelper";
import formatHelper from "@mixins/formatHelper";

export default {
  mixins: [formHelper, dateHelper, formatHelper],
  name: "BeneficiaryDetail",
  props: ['id'],
  data(){
    return {
      isMounted: true,
      onError: null,
      errorCode: null,
      failed: null,
      notFound: false,
      beneficiary: {},

      breadcrumbItems: [
        {
          title: 'Mis beneficiarios',
          url: '/solicitante/beneficiarios'
        },
        {
          title: "Información de beneficiario",
          active: true
        }
      ]
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    address(){
      return this.beneficiary.address;
    },
  },
  mounted: async function () {
  },
  async created() {
    this.showLoading(true);
    this.isMounted = false;
    await this.getBeneficiaryInfo();
    this.isMounted = true;
    this.showLoading(false);
  },
  methods: {
    stateFormat(beneficiary){
      if(beneficiary.address.country_id === 700 || beneficiary.address.country_id === "700"){
        if(beneficiary.address.neighborhood_model.city_id != null && beneficiary.address.neighborhood_model.city_id !== ""){
          return beneficiary.address.neighborhood_model.city.state.name;
        }
        else {
          return "";
        }
      }else{
        return beneficiary.address.other_state;
      }
    },
    cityFormat(beneficiary){
      if(beneficiary.address.country_id === 700 || beneficiary.address.country_id === "700"){
        if(beneficiary.address.neighborhood_model.city_id != null && beneficiary.address.neighborhood_model.city_id !== ""){
          return beneficiary.address.neighborhood_model.city.name;
        }
        else {
          return "";
        }
      }else{
        return beneficiary.address.other_city;
      }
    },
    countryName(beneficiary){
      if(beneficiary.address_id === null || beneficiary.address_id === ""){
        return "";
      }
      else {
        if(beneficiary.address.country_id !== null && beneficiary.address.country_id !== ""){
          return beneficiary.address.country.name;
        }
        else {
          return "";
        }
      }
    },
    neighborhoodName(beneficiary){
      if(beneficiary.address.country_id === 700 || beneficiary.address.country_id === "700"){
        if(beneficiary.address.neighborhood_id != null && beneficiary.address.neighborhood_id !== ""){
          return beneficiary.address.neighborhood_model.name;
        }
        else {
          return "";
        }
      }else{
        return beneficiary.address.foreign_neighborhood;
      }
    },
    zipCode(beneficiary){
      if(beneficiary.address.country_id === 700 || beneficiary.address.country_id === "700"){
          return beneficiary.address.zipcode;
      }else{
        return beneficiary.address.foreign_zipcode;
      }
    },
    citizenshipName(beneficiary){
      if(beneficiary.citizenship === null || beneficiary.citizenship === ""){
        return "";
      }
      else {
        return beneficiary.citizenship.name;
      }
    },
    dateStr(beneficiary){
      return this.midDateFormat(beneficiary.birth_date);
    },
    percentageFormat(beneficiary){
      let per = `${beneficiary.beneficiary_percentage*100}%`
      return per;
    },
    async getBeneficiaryInfo() {
      this.showLoading(true);
      try {
        let response = await axios.get(`/api/v1/beneficiary/${this.id}?with[]=address&with[]=citizenship`);
        if (response.data != "") {
          this.beneficiary = response.data;
        }
        else{
          this.notFound = true;
        }
      } catch (error) {
        this.processError(error.status);
      }
      this.showLoading(false);
    },
    processError(code){
      this.onError = true;
      this.errorCode = code;
    },
    editBeneficiaryData() {
      this.$router.push({ name: 'editBeneficiario', params: { id: this.id.toString()} });
      this.$emit('on-edit-request', this.id);
    },
  }
}
</script>

<style scoped>

</style>