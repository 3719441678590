<template>
  <div>
    <!-- <vx-card> -->
      <template v-if="beneficiaries > 0">
        <div class="vx-row">
          <div class="vx-col w-full md:w-2/3" >
            <h4>¿Quiénes son tus beneficiarios?</h4>
            <p class="mb-2 mt-2">
              Consulta y administra los datos e información más relevante de tus beneficiarios.
              Procura mantener este apartado lo más actualizado posible.
            </p>
          </div>
        </div>
      </template>
      <template v-else >
        <div>
          <h4>No tienes beneficiarios registrados</h4>
          <p class="mt-2 mb-2">Podrás regresar cuando lo desees, para administrar los registros que existan.</p>
        </div>
      </template>

      <!-- Add Button -->
      <div class="vx-row mt-1 mb-3">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button class="mr-auto mt-2" @click="openNew">Agregar beneficiario</vs-button>
          </div>
        </div>
      </div>
      <beneficiaries-list
        role="investor"
        @loadedBeneficiaries="loadedBeneficiaries"
        @on-edit-request="onEditRequest"
        @on-show-request="onShowRequest"
      />
    <!-- </vx-card> -->

    <vs-popup class="extended-popup" :title="formPopupTitle" :active.sync="isBeneficiaryPopupActive" v-if="isBeneficiaryPopupActive">
      <beneficiary-form
        :new-record="newRecord"
        :beneficiary-id="editBeneficiaryId"
        role="investor"
        @on-cancel="onCancelForm"
        @on-saved="onSavedForm"
      />
    </vs-popup>

    <vs-popup class="extended-popup" title="Datos de beneficiario" :active.sync="isBeneficiaryDetailPopupActive" v-if="isBeneficiaryDetailPopupActive">
      <beneficiary-detail :id="showBeneficiaryId" />
    </vs-popup>
  </div>

</template>

<script>
import BeneficiariesList from "@components/profile_shared/BeneficiariesList";
import BeneficiaryForm from "@components/profile_shared/BeneficiaryForm";
import BeneficiaryDetail from "@components/profile_shared/BeneficiaryDetail";
export default {
  name: "InvestorBeneficiaries",
  data() {
    return {
      beneficiaries: null,
      newRecord: true,
      editBeneficiaryId: null,
      showBeneficiaryId: null,
    }
  },
  components: {
    BeneficiariesList,
    BeneficiaryForm,
    BeneficiaryDetail
  },
  computed: {
    formPopupTitle(){
      return this.newRecord ? "Agregar beneficiario" : "Editar beneficiario";
    },
    isBeneficiaryPopupActive: {
      get() {
        return this.$store.state.isBeneficiaryPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_BENEFICIARY_POPUP_ACTIVE", val);
      }
    },
    isBeneficiaryDetailPopupActive: {
      get() {
        return this.$store.state.isBeneficiaryDetailPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_BENEFICIARY_DETAIL_POPUP_ACTIVE", val);
      }
    },
  },
  methods: {
    loadedBeneficiaries(count) {
      this.beneficiaries = count;
    },
    openNew(){
      this.newRecord = true;
      this.isBeneficiaryPopupActive = true;
    },
    onEditRequest(id){
      this.newRecord = false;
      this.editBeneficiaryId = id;
      this.isBeneficiaryPopupActive = true;
    },
    onShowRequest(id){
      this.showBeneficiaryId = id;
      this.isBeneficiaryDetailPopupActive = true;
    },
    onCancelForm(){
      this.isBeneficiaryPopupActive = false;
    },
    onSavedForm(){
      this.isBeneficiaryPopupActive = false;
      this.$store.state.shouldReloadBeneficiariesList = true;
    }
  }
}
</script>

<style scoped>

</style>