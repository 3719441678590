<template>
  <div class="px-2 py-2">
    <div class="vx-row mb-3">
      <div class="vx-col w-full" v-if="onEditMode">
        <h4 class="mb-3" v-if="disableEdition">Detalle de tu cuenta de banco.</h4>
        <h4 class="mb-3" v-else>Edita tu cuenta de banco.</h4>
        <p>
          Consulta o modifica los 18 dígitos de tu cuenta CLABE y sube un
          documento donde podamos consultar de manera clara y legible la
          misma cuenta CLABE que ingresaste.
          <span class="bold">Considera que el proceso de verificación después de editar tu cuenta podrá tomar hasta 72 horas.</span>
        </p>
      </div>
      <div class="vx-col w-full" v-else>
        <h4 class="mb-3">Agrega una cuenta de banco.</h4>
        <p>
          Ingresa los 18 dígitos de tu cuenta CLABE y sube un
          documento donde podamos consultar de manera clara y legible la
          misma cuenta CLABE que ingresaste.
          <span class="bold">Considera que el proceso de verificación de nuevas cuentas puede tomar hasta 72 horas.</span>
        </p>
      </div>
    </div>

    <div class="vx-row px-4">
      <vs-alert v-if="errorMssg" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mb-3" color="danger">
        <span>{{errorMssg}}</span>
      </vs-alert>

      <vs-alert v-if="successMssg" icon-pack="feather" style="height:auto" icon="icon-check" class="mb-3" color="success">
        <span>{{successMssg}}</span>
      </vs-alert>
    </div>

    <!-- Formulario -->
    <div class="vx-row mt-2" v-if="isMounted">
      <template v-for="f in mfields">
        <!-- Campos automaticos -->
        <form-field
          ref="mfields"
          v-if="isAutoFormField(f)"
          :key="f.id"
          :f="f"
          :dataContainer="getContainer(f)"
          :collectionsRoot="collections"
          :keep-disabled="disableEdition"
          :onChangeReceptor="onFieldChange"
          :hasBackofficeAccess="hasBackofficeAccess"
          :blockedByDocument="f.blockedByDocument"
          :evaluator="evaluateCondition"
          :input-class-large="true"
          :editableByBackoffice="true" />
      </template>
    </div>
    <div class="vx-row" v-if="isMounted">
      <document-upload
        :target_id="targetId"
        :f="doc.formField"
        :doc="doc.documentSet"
        :evaluator="evaluateCondition"
        :loadedState="doc.loadedState"
        :disabled="disableEdition"
        :stickToLoadedState="true"
        @on-success="onDocumentUploadSuccess"
        :editableByBackoffice="true"
        :inputClassExtraLarge="true"
      />
    </div>

    <div class="vx-row" >
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-start">
          <vs-button v-if="showSaveBtn && !disableEdition" class="ml-2" @click.stop="saveChangesRequest">{{ saveBtnText }}</vs-button>
          <vs-button v-if="showFinishBtn" class="ml-2" @click.stop="finish">Finalizar</vs-button>
          <vs-button v-if="showCancelBtn && !disableEdition" class="ml-6" type="border" @click.stop="finish">{{ cancelBtnText }}</vs-button>
          <vs-button v-if="showCancelBtn && disableEdition" class="ml-2" @click.stop="finish">Cerrar</vs-button>
        </div>
      </div>
    </div>
    <!-- fin formulario -->
  </div>
</template>

<script>
import FormField from '@components/forms/FormField.vue'
import inputHelper from '@components/mixins/inputHelper';
import formHelper from '@components/mixins/formHelper';

export default {
  name: "FormBankAccount",
  props: {
    formattedAccount: { type: Object },
    userAccount: { type: Object },
    collections: { type: Object, required: true },
    onEditMode: { type: Boolean, default: false },
    disableEdition: { type: Boolean, default: false },
    isMoral: { type: Boolean, required: true },
    userId: { type: [String,Number], required: true },
    personType: { type: [String,Number], required: true },
    sectionInfo: { type: Object, required: true },
    docInfo: { type: Object, required: true },
    hasBackofficeAccess: { type: Boolean, required: true },
    userData: { type: Object, required: true },
  },
  mixins: [formHelper, inputHelper],
  components:{
    FormField
  },
  data(){
    return {
      isMounted: false,
      mexicoCountryId: 700,
      pmDocId: 109,
      pfaeDocId: 102,
      mfields: [],
      base: {},
      bank: {},
      muser: {},
      mpersonType: null,
      address_is_mexico: false,
      targetId: null,
      doc: {},
      errorMssg: null,
      successMssg: null,
      //getters
      should_ask_for_bank_confirmation: false,

      showSaveBtn: true,
      saveBtnText: this.onEditMode ? "Guardar" : "Agregar",
      showFinishBtn: false,
      showCancelBtn: this.onEditMode,
      cancelBtnText: "Cancelar",

      currentDocFileId: null,
      docLoadedState: null,
    }
  },
  async beforeMount() {
    this.isMounted = false
    this.setUserProps();
    this.setFormFields();
    this.setDocField();
    await this.getDocState();
    this.isMounted = true
  },
  methods: {
    setFormFields(){
      this.mfields = this.sectionInfo.fields;
      this.mfields.forEach(f => {
        this.formFieldSetter(f, this);
      });
    },
    setUserProps(){
      this.mpersonType = parseInt(this.personType);
      this.targetId = this.userId;

      if(this.onEditMode){
        this.base = this.userAccount;
        this.bank = this.userAccount.bank;
        this.currentDocFileId = this.base.document_file_id;
      }
      else{
        this.base = { bank_is_in_mexico: false }
        this.bank = {}
      }

      this.muser = this.userData.user;
      let isForeign = false;

      if(this.isMoral){
        isForeign = (this.mpersonType === 3);
        this.address_is_mexico = (this.muser.business.address && this.muser.business.address.country_id === this.mexicoCountryId);
      }
      else {
        isForeign = (this.mpersonType === 2);
        this.address_is_mexico = (this.muser.personal.address && this.muser.personal.address.country_id === this.mexicoCountryId);
      }

      if(this.onEditMode){
        this.should_ask_for_bank_confirmation = false;
        this.base.bank_is_in_mexico = (this.bank.country_id === this.mexicoCountryId);
      }
      else{
        this.base.bank_is_in_mexico = !isForeign;
        this.should_ask_for_bank_confirmation = isForeign;
      }
    },
    async getDocState(){
      if(!this.onEditMode || this.currentDocFileId === null){
        return;
      }
      this.showLoading(true)
      try {
        const res = await axios.get(`/storagev3/documents/files/getDocumentFile/${this.currentDocFileId}`);
        this.doc.loadedState = res.data;
      }
      catch (e) {
      }
      this.showLoading(false)
    },
    setDocField(){
      if(this.isMoral){
        let df = this.mfields.find(f => f.doc_id === this.pmDocId);
        this.doc = {formField: df, documentSet: this.docInfo, target_id: this.targetId, loadedState: null };
      }
      else {
        let df = this.mfields.find(f => f.doc_id === this.pfaeDocId);
        this.doc = {formField: df, documentSet: this.docInfo, target_id: this.targetId, loadedState: null};
      }
    },

    getContainer(f){
      if(f.db_table === "bank_accounts"){
        return this.base;
      }
      else if(f.db_table === "banks"){
        return this.bank;
      }
    },

    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },

    /* evaluator */
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },

    async validate_personal_clabe_is_valid(){
      return this.validate_clabe(this.base.clabe, this.collections.banks);
    },
    async validate_business_clabe_is_valid(){
      return this.validate_clabe(this.base.clabe, this.collections.banks);
    },

    async onDocumentUploadSuccess(data){
      this.successMssg = "El documento ha sido cargado correctamente. Tu cuenta ahora se encuentra en proceso de verificación.";

      if(this.onEditMode){
        this.cancelBtnText = "Finalizar";
      }

      await this.$emit('on-account-saved');
    },

    async requestAuthSaveAccount(){
      await this.$emit('on-save-account-auth-request');
    },

    async saveChangesRequest(){
      this.errorMssg = null;
      this.successMssg = null;

      let res1 = await this.validateFormFields();
      if(!res1){
        this.errorMssg = "Verifique los datos faltantes o incorrectos."
        return;
      }

      await this.requestAuthSaveAccount();
    },
    extractBasePayload(){
      // unicamente obtener los datos actualizables en el form
      return (({id, clabe, account, bank_id, bank_address, bic_swift, iban, routing_number}) => ({
        id,
        clabe,
        account,
        bank_id,
        bank_address,
        bic_swift,
        iban,
        routing_number
      }))(this.base);
    },
    async doSaveChanges(code){
      this.showLoading(true)
      try {
        let payload = this.extractBasePayload();
        payload.bank = this.bank;
        payload.code = code;
        const res = await axios.post(`api/v1/userBankAccounts/${this.userId}/save`, payload);
        this.base = res.data.account;
        this.bank = res.data.bank;
        this.base.bank_is_in_mexico = (this.bank.country_id === this.mexicoCountryId);
        this.should_ask_for_bank_confirmation = false;

        if(!this.onEditMode){
          this.showSaveBtn = false;
          this.showFinishBtn = true;
          this.successMssg = "La cuenta ha sido creada con éxito. Ahora puedes agregar la carátula de tu estado de cuenta para completar el proceso.";
        }
        else {
          this.cancelBtnText = "Finalizar";
          this.successMssg = "La cuenta ha sido actualizada con éxito.";
        }
        await this.$emit('on-account-saved');
      }
      catch (e) {
        this.errorMssg = "Ha ocurrido un error guardando los datos. Intente más tarde.";
      }
      this.showLoading(false)
    },
    async validateFormFields(){
      let allValid = true;
      let grefs = this.$refs.mfields;
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid === false){
          allValid = false;
        }
        else if(r.dirty === true) {
          let sp = f.specialValidation();
          if(sp != null && sp.method in this){
            let res = await this[sp.method]();
            let n = f.rawFieldName;
            if(!res){
              this.skipMissingWarnings = true;
              allValid = false;
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
            }
          }
        }
      }
      return allValid;
    },
    async finish(){
      await this.$emit('on-finish');
    },
  },
  computed: {
    should_ask_for_pf_bank_confirmation() {
      return this.should_ask_for_bank_confirmation;
    },
    should_ask_for_pm_bank_confirmation() {
      return this.should_ask_for_bank_confirmation;
    },
    fill_clabe(){
      return (this.base.bank_is_in_mexico === true);
    },
    fill_swift_bic_iban(){
      return (this.base.bank_is_in_mexico === false);
    },
    fill_routing_account_number(){
      return (this.base.bank_is_in_mexico === false && (this.base.iban === "" || this.base.iban === null || this.base.iban === undefined));
    },
    should_fill_pf_clabe(){
      return this.fill_clabe;
    },
    should_fill_pm_clabe(){
      return this.fill_clabe;
    },
    should_fill_pf_swift_bic(){
      return this.fill_swift_bic_iban;
    },
    should_fill_pf_iban(){
      return this.fill_swift_bic_iban;
    },
    should_fill_pf_routing_number(){
      return this.fill_routing_account_number;
    },
    should_fill_pf_account_number(){
      return this.fill_routing_account_number;
    },
    investor_pf_bank_account_exists(){
      return (this.base.id != null);
    },
    investor_pm_bank_account_exists(){
      return (this.base.id != null);
    },
    investor_pm_bank_account_id(){
      return this.base.id;
    },
    investor_pf_bank_account_id(){
      return this.base.id;
    },

    should_fill_pm_swift_bic(){
      return this.fill_swift_bic_iban;
    },
    should_fill_pm_iban(){
      return this.fill_swift_bic_iban;
    },
    should_fill_pm_routing_number(){
      return this.fill_routing_account_number;
    },
    should_fill_pm_account_number(){
      return this.fill_routing_account_number;
    }
  },
}
</script>
