<template>
  <div class="data-list-container data-list-list-view data-list-high-rows vs-con-loading__container" id="main-div" > 
    <vs-table ref="table" pagination max-items="10" :data="beneficiaries" noDataText="No hay datos disponibles">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between mt-4"></div>
      <template slot="thead">
        <vs-th v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
          <vs-td>
            {{ tr.complete_name }}
          </vs-td>
          <vs-td>
            {{ dateStr(tr) }}
          </vs-td>
          <vs-td>
            {{ tr.email }}
          </vs-td>
          <vs-td>
            {{ `${tr.country_calling_code} ${phoneFormat(tr.phone)}` }}
          </vs-td>
          <vs-td>
            {{ prcgFormat(tr.beneficiary_percentage*100, 2) }}
          </vs-td>
          <vs-td>
            <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="showItemRequest(tr)" />
            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="editItemRequest(tr)" />
            <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="removeItemRequest(tr)" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import formatHelper from '../mixins/formatHelper';
import dateHelper from '../mixins/dateHelper';
import { es } from "vuejs-datepicker/dist/locale";
import {mapState} from "vuex";

const columnDefinitions = [
  { title: "Nombre completo", key: "complete_name", sortable: true },
  { title: "Fecha de nacimiento", key: "birth_date", sortable: true },
  { title: "Correo electrónico", key: "email", sortable: true },
  { title: "Teléfono", key: "phone", sortable: true },
  { title: "Porcentaje de participación", key: "beneficiary_percentage", sortable: true },
  { title: "Acciones", key: "", sortable: false },
];

const itemsPerPageDefinitions = [5,10,25,50];

export default {
  name: "beneficiaries-list",
  props: {
    role: {
      type: [String],
      required: true,
    }
  },  
  mixins: [formatHelper, dateHelper],
  components: {},
  async mounted() {
    this.client_id = this.ApplicantId;
    await this.loadBeneficiaries();
  },
  data() {
    return {
      beneficiaries : [],
      orderKey: "",
      beneficiaryToRemove: null,
      columns: columnDefinitions,
      itemsPerPageDefinitions: itemsPerPageDefinitions,
      itemsPerPage: itemsPerPageDefinitions[0],
      isMounted: false,
      loading: false,
      // Data Sidebar
      es: es,
      client_id: null
    };
  },
  computed: {
    ...mapState("auth", ["access_token"]),
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.beneficiaries.length
    },
    accessToken(){
      return this.access_token;
    },
  },
  watch: {
    '$store.state.shouldReloadBeneficiariesList': async function (val) {
      if(val === true){
        await this.loadBeneficiaries();
        this.$store.state.shouldReloadBeneficiariesList = false;
      }
    },
  },
  methods: {
    async loadBeneficiaries(){
      this.isMounted = false;
      this.showLoading(true);
      try {
        let response = await axios.get(`/api/v1/userBeneficiaries/${this.UserId}/${this.role}`);
        this.beneficiaries = response.data;
        this.$emit('loadedBeneficiaries', this.beneficiaries.length);
      } catch (error) {}
      this.isMounted = true;
      this.showLoading(false);
    },
    dateStr(beneficiary){
        return this.midDateFormat(beneficiary.birth_date);
    },
    showItemRequest(beneficiary){
      //this.$router.push({ name: 'infoBeneficiario', params: { id: beneficiary.id.toString()} });
      this.$emit('on-show-request', beneficiary.id);
    },
    editItemRequest(beneficiary){
      //this.$router.push({ name: 'editBeneficiario', params: { id: beneficiary.id.toString()} });
      this.$emit('on-edit-request', beneficiary.id);
    },
    removeItemRequest(beneficiary){
      this.beneficiaryToRemove = beneficiary;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar beneficiario',
        text: `¿Realmente desea eliminar el beneficiario?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      let payload = {beneficiary_id: this.beneficiaryToRemove.id};
      this.injectAccountMetadataToPayload(payload);
      await axios.post('/api/v1/beneficiary/delete', payload).then(async response => {
        this.showLoading(false);
        this.saveSuccessNotif("Operación exitosa", "Beneficiario eliminado correctamente.", 6000);
        await this.loadBeneficiaries();
      }).catch(error => {
        this.showLoading(false);
        this.failedOperationNotif("Error ejecutando la eliminación"); 
      });
    },
  },
};
</script>


<style lang="css">
  .bg-gray {
    background-color: gray;
  }
  .vx-input-group .append-text.btn-addon .vs-button.search-btn {
    padding: 0 1rem !important;
  }
</style>
