<template>
  <div>
    <template v-if="!showFormComponent && isMounted">
      <vx-card class="mb-base mt-5">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon icon="MailIcon" svgClasses="w-5 h-5" class="mr-2 text-primary"/>
              <span class="font-medium text-lg leading-none bold">Contacto</span>
              <vs-button class="ml-auto" size="small" type="border"
                         @click.stop="showFormComponent = true">Editar
              </vs-button>
            </div>
            <vs-divider/>
          </div>
        </div>
        <div class="vx-row">
          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="contact-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Alias:</td>
                <td>{{ alias }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->
          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="contact-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Correo electrónico:</td>
                <td>{{ email }}</td>
              </tr>
            </table>
          </div>
          <!-- Information - Col 3 -->
          <div v-if="!isMoral" class="vx-col flex-1" id="contact-info-col-3">
            <table>
              <tr>
                <td class="font-semibold">Teléfono:</td>
                <td>{{ user.phone }}</td>
              </tr>
            </table>
          </div>
        </div>
      </vx-card>
    </template>
    <template v-else>
      <vx-card class="mb-base mt-5">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon icon="MailIcon" svgClasses="w-5 h-5" class="mr-2 text-primary"/>
              <span class="font-medium text-lg leading-none bold">Contacto</span>
              <vs-button class="ml-auto" size="small" type="border"
                         @click.stop="showFormComponent = false">Cancelar
              </vs-button>
            </div>
            <vs-divider/>
          </div>
        </div>

        <!-- ALIAS CARD -->

        <div class="vx-row mb-5" v-if="isMounted">
          <div class="vx-col w-full">
            <h4>Alias</h4>
            <p class="mb-4">Tu alias es la manera en la que podrás identificarte en la Red. No podrá cambiarse o
              actualizarse una vez definido.</p>

            <div class="vx-row mb-2">
              <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
                <vs-input class="w-full" :label="hasAlias ? '' : 'Ingresa el alias que desees utilizar'"
                          type="text" v-model.lazy="alias" v-validate="'required'" :danger="hasError('alias') && sent"
                          :danger-text="errorText('alias')" :success="isSuccess('alias')" :disabled="hasAlias"
                          name="alias"/>
              </div>
            </div>

            <!-- Save Button -->
            <div class="vx-row mt-3" v-if="!hasAlias">
              <div class="vx-col w-full">
                <div class="flex flex-wrap items-center">
                  <vs-button class="mt-2" @click.stop="saveAliasRequest">Agregar</vs-button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- END ALIAS CARD -->

        <!-- EMAIL CARD -->
        <div class="vx-row mb-5" v-if="isMounted">
          <div class="vx-col w-full">
            <h4>Correo electrónico</h4>

            <p class="mb-4">Tu correo electrónico es otro medio importante para identificarte,
              además de que es el canal en donde más comunicación tendrás con la Red.
              Puedes consultarlo o actualizarlo aquí cuando lo necesites.</p>

            <vs-alert class="mb-4" v-if="!hasAlias">Para poder modificar tu dirección de correo electrónico es
              necesario que cuentes con un Alias.
            </vs-alert>

            <vs-alert v-if="errorMssgMail" icon-pack="feather" icon="icon-alert-triangle" class="mb-5"
                      color="danger">
              <span class="font-regular">{{ errorMssgMail }}</span>
            </vs-alert>

            <vs-alert v-if="hasChangeEmailRequest" icon-pack="feather" icon="icon-alert-circle" class="mb-5"
                      color="warning">
              <span class="font-regular">Has solicitado actualizar tu correo a </span>
              <span class="bold">{{ emailStatus.unconfirmed_email }}</span>
              <span class="font-regular">. Se han enviado las instrucciones a dicha dirección para completar el
                        cambio.</span>
            </vs-alert>

            <template v-if="!editEmail">
              <div class="vx-row mb-5">
                <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
                  <vs-input class="w-full" type="text" v-model.lazy="email" v-validate="'required|email'"
                            :danger="hasError('email')" :danger-text="errorText('email')" :success="isSuccess('email')"
                            :disabled="true" name="email"/>
                </div>
              </div>
              <!-- Edit Button -->
              <div class="vx-row" v-if="hasAlias">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center">
                    <vs-button @click="checkEditEmail">Modificar</vs-button>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="editEmail">
              <div class="vx-row mb-5">
                <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
                  <vs-input label="Ingresa el nuevo correo" class="w-full" type="text" v-model.lazy="new_email"
                            v-validate="'required|email'" :danger="hasError('new_email')"
                            :danger-text="errorText('new_email')" :success="isSuccess('new_email')" name="new_email"/>
                </div>
                <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
                  <vs-input label="Confirma el nuevo correo" onpaste="return false;" class="w-full" type="text"
                            v-model.lazy="new_email_confirm" v-validate="'required|email'"
                            :danger="hasError('new_email_confirm')" :danger-text="errorText('new_email_confirm')"
                            :success="emailConfirmFieldValid" name="new_email_confirm"/>
                </div>
              </div>
            </template>

            <!-- Save/cancel Button -->
            <div class="vx-row" v-if="editEmail">
              <div class="vx-col w-full">
                <div class="flex flex-wrap items-center">
                  <vs-button @click="editEmailRequest">Guardar</vs-button>
                  <vs-button class="ml-6" type="border" @click="cancelEditEmail">Cancelar</vs-button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- END EMAIL CARD -->

        <div class="vx-row mb-5" v-if="!isMoral && isMounted">
          <div class="vx-col w-full">
            <h4>Teléfono</h4>
            <label>Para asegurarse de que este número sea tuyo, RedGirasol te enviará un <strong> código de
              verificación </strong>.
              <small class="bold" v-if="isUsaPhone">
                <br>
                (Por el momento los télefonos de Estados Unidos no serán verificados)
              </small>
            </label>
            <div class="vx-row">
              <div :class="colClass">
                <div class="flex">
                  <vue-country-code name="dialCode" class="vs-custom" data-vv-name="dialCode"
                                    :enableSearchField="true" searchPlaceholderText="Buscar código de país"
                                    :enabledCountryCode="true" @onSelect="onSelect" :preferredCountries="['MX']"
                                    :danger="hasError('dialCode')" :danger-text="errorText('dialCode')"
                                    :success="isSuccess('dialCode')">
                  </vue-country-code>
                  <vs-input label="" name="phone" type="tel" v-validate="'required|numeric'" v-model.lazy="phone"
                            :danger="hasError('phone')" :danger-text="errorText('phone')" :success="isSuccess('phone')"
                            icon-pack="feather" placeholder="(Requerido)"/>
                </div>
              </div>
            </div>
            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5" color="danger">
              <span class="font-regular">{{ errorMssg }}</span>
            </vs-alert>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-button @click="changeUserPhone">{{ saveBtnText }}</vs-button>
              </div>
            </div>
          </div>
        </div>


      </vx-card>
    </template>
    <!-- model popup -->
    <vs-popup title="Confirma tu alias" :active.sync="showPasswordFormPrompt">
      <div class="con-exemple-prompt">

        <p class="mb-5 bold">{{ modelFormPromptIndication }}</p>
        <p class="mb-5">Tu alias será: <strong>{{ alias }}</strong></p>
        <vs-input class="w-full mb-5" label="Contraseña" type="password" v-model.lazy="alias_pwd"
                  v-validate="'required'" :danger="hasError('alias_pwd')" :danger-text="errorText('alias_pwd')"
                  :success="isSuccess('alias_pwd')" name="alias_pwd"/>

        <vs-button :disabled="!completeModelForm || $isLoading" @click="doUpdateAlias" color="primary"
                   class="mr-5 mt-2">Enviar
        </vs-button>
        <vs-button @click="closeModelFormPrompt" :disabled="$isLoading" type="flat"
                   color="primary">Cancelar
        </vs-button>

      </div>
    </vs-popup>

    <vs-popup title="Confirma tus credenciales" :active.sync="showPasswordEmailFormPrompt">
      <div class="con-exemple-prompt">

        <p class="mb-5 bold">{{ modelFormPromptIndication }}</p>
        <p class="mb-5">Tu correo electrónico será: <strong>{{ new_email }}</strong></p>
        <vs-input class="w-full mb-5" label="Contraseña" type="password" v-model.lazy="email_pwd"
                  v-validate="'required'" :danger="hasError('email_pwd')" :danger-text="errorText('email_pwd')"
                  :success="isSuccess('email_pwd')" name="email_pwd"/>

        <vs-input label="Código de 6 dígitos de tu segundo factor de autenticación" type="text" class="w-full mb-5"
                  v-model.lazy="email_code" name="email_code" v-validate="'required|numeric'"
                  :danger="hasError('email_code')"
                  :danger-text="errorText('email_code')" :success="isSuccess('email_code')" :min="6" :max="6"
                  autocomplete="off"/>

        <vs-button :disabled="!completeEmailForm || $isLoading" @click="doUpdateEmail" color="primary"
                   class="mr-5 mt-2">Enviar
        </vs-button>
        <vs-button @click="closeEmailFormPrompt" :disabled="$isLoading" type="flat"
                   color="primary">Cancelar
        </vs-button>

      </div>
    </vs-popup>

    <vs-popup :active.sync="isSmsCodePopupActive" title="Código de verificación">
      <div class="p-4 pt-0">
        <div>
          <h6 class="title-dark">
            Acabamos de enviarte un SMS con un código. Ingrésalo para verificar tu teléfono.
            ¿No recibiste un SMS? <span><router-link class="bold ml-1 inline-link-primary" to=""
                                                     @click.native="isSmsCodePopupActive = false">Volver a intentarlo.</router-link></span>
          </h6>
          <vs-alert v-if="errorCodeMssg" color="danger" class="mt-3 mb-3" style="height: auto">{{
              errorCodeMssg
            }}
          </vs-alert>
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <div class="flex items-center flex-grow justify-left mt-1">
                <vs-input type="text" v-model.lazy="verificationCode" name="verificationCode"
                          v-validate="'required|numeric'" maxlength="6" autocomplete="off" :disabled="validatingCode"
                          v-on:keyup.enter="verifyCode" placeholder="Ingresa el código"/>
                <vs-button class="ml-6" color="primary" :disabled="validatingCode"
                           @click="verifyCode">Verificar
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import {mapState} from "vuex";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import inputHelper from '@mixins/inputHelper'

export default {
  name: "InvestorContactData",
  components: {},
  mixins: [investorInfoHelper, inputHelper],
  data() {
    return {
      isMounted: false,
      investorData: {},
      showFormComponent: false,
      isMoral: null,

      email: null,
      confirm_email: null,
      new_email: null,
      new_email_confirm: null,
      alias: '',
      email_error: '',
      loading_email: false,
      email_confirm_error: '',
      general_error: '',
      edit_email: false,
      set_alias: false,
      loading_alias: false,
      writing: true,
      alias_pwd: null,
      alias_error: '',

      failed: false,
      hasAlias: false,
      showPasswordFormPrompt: false,
      modelFormPromptIndication: "Escribe tu contraseña actual para confirmar",

      showPasswordEmailFormPrompt: false,

      userData: {},
      sent: false,
      editEmail: false,
      email_pwd: null,
      email_code: null,

      factorEnabled: null,
      cancellationAccountNotValid: null,
      cancellationAccountValidations: [],
      errorMssgMail: null,
      errorMssg: null,
      hasChangeEmailRequest: false,
      emailStatus: {
        unconfirmed_email: null,
        email_token_sent_at: null,
        request_active: false,
      },


      saveTemporaryPhoneStep: 1,
      verifyCodeStep: 2,
      updatePhoneNoVerificationStep: 3,
      countryCallingCodeUsa: "+1",
      currentProcessStep: null,
      requiredRules: 'required',
      errorCodeMssg: null,
      successMssg: null,
      colClass: 'vx-col w-full mb-5',
      requiredPlaceholder: '(Requerido)',
      countryCallingCode: null,
      phone: null,
      verificationCode: null,
      processStep: null,
      isSmsCodePopupActive: false,
      validatingCode: false
    }
  },
  async mounted() {
    await this.intializeData();
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    completeModelForm() {
      return this.alias_pwd != null;
    },
    completeEmailForm() {
      return this.email_pwd != null && this.email_code != null && this.email_code.length === 6;
    },
    emailConfirmFieldValid() {
      return this.new_email === this.new_email_confirm && this.new_email_confirm != null;
    },
    isUsaPhone() {
      return (this.countryCallingCode == this.countryCallingCodeUsa);
    },
    saveBtnText() {
      return this.isUsaPhone ? "Cambiar número" : "Obtener código";
    },
  },
  methods: {
    async intializeData() {
      this.showFormComponent = false;
      await this.onUserInfoUpdated();
      this.isMounted = false
      this.showLoading(true)
      this.isMoral = this.userIsMoral(this.UserPersonType);
      this.verificationCode = null;
      this.phone = null;
      await this.getUserData();
      this.isMounted = true
      this.showLoading(false)
    },
    async getUserData() {
      try {
        const res1 = await axios.get(`api/perfil/solar-id/${this.UserId}`);
        const res2 = await axios.get(`api/auth/user/email/status`);
        this.userData = res1.data;
        this.alias = this.userData.alias;
        this.hasAlias = (this.alias != null && this.alias !== "");
        this.email = this.userData.email;
        this.factorEnabled = this.userData.factorEnabled;
        this.emailStatus = res2.data;
        this.hasChangeEmailRequest = this.emailStatus.request_active;
      } catch (error) {
        this.warn(error);
      }
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
    async saveAliasRequest() {
      this.sent = true;
      let r = await this.$validator.validate('alias');
      if (!r) {
        return;
      }
      await this.testAlias();
    },
    async testAlias() {
      try {
        let res = await axios.post('/api/auth/user/alias/check', {alias: this.alias});
        if (res.data == true) {
          this.openModelFormPrompt();
        } else {
          this.errorNotifDialog("No es posible guardar tu alias", "El alias que has elegido no se encuentra disponible, por favor intenta con otro.");
        }
      } catch (error) {
        this.failedOperationNotif();
      }
    },
    async doUpdateAlias() {
      this.showLoading(true)
      try {
        await axios.put('/api/auth/user/alias', {alias: this.alias, password: this.alias_pwd});
        this.hasAlias = true;
        this.closeModelFormPrompt();
        await this.getUserData();
        this.successNotifDialog("Operación exitosa", "Tu nuevo alias ha sido registrado correctamente. " +
            "A partir de ahora podrás utilizarlo para iniciar sesión en la plataforma.");
        await this.intializeData();
      } catch (error) {
        this.closeModelFormPrompt();
        if (error.response && error.response.data && error.response.data.message) {
          this.errorNotifDialog("Contraseña incorrecta", "La contraseña ingresada es incorrecta, por lo que no se ha podido generar tu alias. " +
              "Revisa tus credenciales e intenta de nuevo,");
        } else {
          this.failedOperationNotif();
        }
      }
      this.showLoading(false)
    },
    async checkEditEmail() {
      if (this.factorEnabled) {
        this.editEmail = true;
        this.new_email = null;
        this.new_email_confirm = null;
      } else {
        this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Actualizar correo electrónico",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });
      }
    },
    cancelEditEmail() {
      this.editEmail = false;
      this.errorMssgMail = null;
    },
    openModelFormPrompt() {
      this.alias_pwd = null;
      this.showPasswordFormPrompt = true;
    },
    closeModelFormPrompt() {
      this.showPasswordFormPrompt = false;
      this.alias_pwd = null;
    },

    async editEmailRequest() {
      let r = await this.$validator.validate('new_email');
      let r1 = await this.$validator.validate('new_email_confirm');
      if (!r || !r1) {
        return;
      }

      if (this.new_email === this.email) {
        this.errorMssgMail = "No puedes ingresar tu dirección de correo actual.";
        return;
      } else if (this.new_email !== this.new_email_confirm) {
        this.errorMssgMail = "Las direcciones de correo no coinciden.";
        return;
      }

      this.errorMssgMail = null;
      await this.testEmail();
    },
    async testEmail() {
      this.startLoading()
      try {
        let res = await this.publicApiPost('/api/auth/user/email/validate', {email: this.new_email});
        if (!res.data.is_valid) {
          this.errorMssgMail = "Este correo electrónico ya se encuentra en uso.";
          this.errorNotif("No es posible actualizar", "Este correo electrónico ya existe, por favor intenta con otro", 8000);
        } else {
          this.openEmailFormPrompt();
        }
      } catch (error) {
        this.failedOperationNotif();
      }
      this.stopLoading()
    },
    async doUpdateEmail() {
      this.showLoading(true)
      try {
        const res = await axios.post('/api/auth/user/email/request', {
          email: this.new_email,
          password: this.email_pwd,
          code: this.email_code
        });

        const res2 = await axios.get(`api/auth/user/email/status`);
        this.emailStatus = res2.data;
        this.hasChangeEmailRequest = this.emailStatus.request_active;
        this.editEmail = false;
        this.successNotifDialog('Solicitud enviada correctamente', res.data.message);
        await this.intializeData();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.errorNotifDialog("Error al generar la solicitud", error.response.data.message);
        } else {
          this.failedOperationNotif();
        }
      }
      this.closeEmailFormPrompt();
      this.showLoading(false)
    },
    openEmailFormPrompt() {
      this.email_pwd = null;
      this.email_code = null;
      this.showPasswordEmailFormPrompt = true;
    },
    closeEmailFormPrompt() {
      this.email_pwd = null;
      this.email_code = null;
      this.showPasswordEmailFormPrompt = false;
    },
    async goTo2FaPage() {
      await this.$router.push({name: 'auth2FaInversionista'});
    },
    async changeUserPhone() {
      this.errorMssg = null
      if (this.phone === null) {
        this.errorMssg = 'Debes ingresar un télefono.'
        setTimeout(() => (this.errorMssg = null), 5000)
        return
      }
      let processStep = this.saveTemporaryPhoneStep;
      if (this.isUsaPhone) {
        processStep = this.updatePhoneNoVerificationStep;
      }
      let apiSuccess = await this.executePostApi(processStep)
      if (apiSuccess) {
        if (processStep == this.updatePhoneNoVerificationStep) {
          await this.intializeData();
        } else {
          this.isSmsCodePopupActive = true;
        }
      }
    },
    async verifyCode() {
      this.errorCodeMssg = null;
      let apiSuccess = await this.executePostApi(this.verifyCodeStep);
      if (apiSuccess) {
        this.isSmsCodePopupActive = false;
        await this.intializeData();
      }
    },
    async executePostApi(processStep) {
      this.currentProcessStep = processStep
      let success = false
      this.errorMssg = null
      this.showLoading(true, 'Enviando información...')
      try {
        let payload = {
          user_id: this.UserId,
          phone: this.phone,
          country_calling_code: this.countryCallingCode,
          process_step: processStep,
          verification_code: this.verificationCode
        }
        await axios.post('/api/perfil/updateAndVerifyUserPhone', payload)
        success = true
        this.showLoading(false)
      } catch (e) {
        this.showLoading(false)
        this.showError(e)
      }
      return success
    },
    onSelect({dialCode}) {
      this.countryCallingCode = `+${dialCode}`
    },
    showError(e) {
      this.warn(e)
      let err = 'Ha ocurrido un error con la operación, intente más tarde'
      if (e.response?.data?.message) {
        err = e.response.data.message
      }
      if (this.currentProcessStep == 1) {
        this.errorMssg = err
        setTimeout(() => (this.errorMssg = null), 5000)
      } else {
        this.errorCodeMssg = err
        setTimeout(() => (this.errorCodeMssg = null), 5000)
      }
    }
  }
}
</script>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: .5em;
}
</style>