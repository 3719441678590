<template>
    <div>
        <vx-card class="mb-base mt-5">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="flex items-end px-3">
                        <feather-icon icon="UsersIcon" svgClasses="w-5 h-5" class="mr-2 text-primary" />
                        <span class="font-medium text-lg leading-none bold">Beneficiarios</span>
                    </div>
                    <vs-divider />
                </div>
            </div>
            <investor-beneficiaries />
        </vx-card>
    </div>
</template>
  
<script>
import InvestorBeneficiaries from "@views/investor/profile/InvestorBeneficiaries";
export default {
    name: "InvestorBeneficiariesData",
    components: {
        InvestorBeneficiaries,
    },
}
</script>
  