<template>
  <div>
    <vx-card card-background="primary" content-color="white" title-color="white" title="¡Entérate de todo!" class="">
      <div class="vx-col w-full">
        <p> Configura las notificaciones que deseas recibir en el correo electrónico que tienes registrado en la Red conforme a tus intereses.
          Podrás ser notificado por pagos que recibas de proyectos, nuevas oportunidades de inversión y más.</p>
      </div>
    </vx-card>

    <!-- TODO-DELETE 28/11/2022 SE COMENTA PORQUE ES TRANSACCIONAL (NO ES OPCIONAL PARA EL USUARIO) Y SE MANDA A TRAVES DE CRON -->
    <!-- <vx-card class="mt-5">
      <div class="mb-4">
        <div class="flex items-center mb-5">
          <h5 class="mr-5">Pagos de proyectos</h5>
          <vs-switch v-model="projectPaymentsPrefs.value" @change="updatePrefs" />
        </div>
        <p>
          Activa estas notificaciones si deseas recibir un correo electrónico por cada
          <span class="bold">pago realizado por un solicitante.</span>
          Este aviso lo podrás recibir de manera semanal los días jueves.
        </p>
      </div>
    </vx-card> -->

    <!-- TODO-DELETE 28/11/2022 SE COMENTA PORQUE SE ENVIAN POR CORREO A TRAVES DE CRON -->
    <!-- <vx-card class="mt-5">
      <div class="mb-4">
        <div class="flex items-center mb-5">
          <h5 class="mr-5">Nuevos proyectos semilla.</h5>
          <vs-switch v-model="newProjectsPrefs.value" @change="newProjectPrefsUpdated"  />
        </div>
        <p class="mb-4">
          Activa estas notificaciones si deseas recibir un correo electrónico por cada
          <span class="bold">nuevo proyecto semilla</span>
          publicado en la Red. Podrás elegir entre calificaciones o plazo de cada uno de ellos. ¡Sé el primero en participar!
        </p>
        <div class="ml-6" v-if="newProjectsPrefs.value">
          <p class="bold flex">
            <feather-icon icon="CalendarIcon" class="mr-2" svg-classes="h-5 w-5" />
            Por plazo del proyecto
          </p>
          <div class="ml-8 mt-5 mb-base">
            <div class="flex items-center mb-4" v-for="pref in termsPrefs">
              <vs-switch v-model="pref.value" @change="updatePrefs" />
              <span class="ml-4">{{ pref.label}}</span>
            </div>
          </div>
          <p class="bold flex">
            <feather-icon icon="AwardIcon" class="mr-2" svg-classes="h-5 w-5" />
            Por calificación del proyecto
          </p>
          <div class="ml-8 mt-5 mb-6">
            <div class="flex items-center mb-4" v-for="pref in qualificationsPrefs">
              <vs-switch v-model="pref.value" @change="updatePrefs" />
              <span class="ml-4">{{ pref.label}}</span>
            </div>
          </div>
        </div>
      </div>
    </vx-card> -->

    <!-- TODO-DELETE 28/11/2022 SE COMENTA PORQUE LOS EVENTOS DE PROYECTO NO CONTENMPLABAN NOTIFICACIONES POR CORREO -->
    <!-- <vx-card class="mt-5">
      <div class="mb-4">
        <div class="flex items-center mb-5">
          <h5 class="mr-5">Eventos de proyectos</h5>
          <vs-switch v-model="eventsPrefs.value" @change="updatePrefs" />
        </div>
        <p class="">
          Activa estas notificaciones si deseas recibir un correo electrónico cada vez que ocurra un evento o
          cambio de estado durante el <span class="bold">proceso de fondeo e instalación</span> de tus proyectos semilla.
        </p>
      </div>
    </vx-card> -->

    <vx-card class="mt-5">
      <div class="mb-4">
        <div class="flex items-center mb-5">
          <h5 class="mr-5">{{marketingPromotionalContent.name}}</h5>
          <vs-switch v-model="marketingPromotionalUserPref.value" @change="updatePrefs" />
        </div>
        <p class="">{{marketingPromotionalContent.description}}</p>
      </div>
    </vx-card>

    <vx-card class="mt-5">
      <div class="mb-4">
        <div class="flex items-center mb-5">
          <h5 class="mr-5">{{marketingNotificationsContent.name}}</h5>
          <vs-switch v-model="marketingNotificationsUserPref.value" @change="updatePrefs" />
        </div>
        <p class="">{{marketingNotificationsContent.description}}</p>
      </div>
    </vx-card>

    <!--vx-card class="mt-5">
      <div class="mb-4">
        <div class="flex items-center mb-5">
          <h5 class="mr-5">Recibir notificaciones de la cobranza en mis proyectos</h5>
          <vs-switch v-model="cashingPrefs.value" @change="updatePrefs" />
        </div>
        <p class="">Recibe correos electrónicos cada vez que sucedan eventos de cobranza en alguno de tus proyectos.</p>
      </div>
    </vx-card-->
  </div>
</template>

<script>
export default {
  name: "NotificationsPage",
  data(){
    return {
      // newProjectsA: true,
      // newProjectsB: true,
      // newProjectsC: true,
      // newProjectsD: true,
      // newProjectsE: true,
      // newProjectsF: true,

      isBusy: false,

      // projectPaymentsCode: 'project_payments',
      // newProjectsCode: 'new_projects',
      // bonusesCode: 'bonuses',
      // cashingCode: 'cashing_events',
      // eventsCode: 'project_events',

      allNotifs: [],
      userNotifs: [],
      
      // TODO-DELETE 28/11/2022 SE COMENTA PORQUE ES TRANSACCIONAL (NO ES OPCIONAL PARA EL USUARIO) Y SE MANDA A TRAVES DE CRON
      // projectPaymentsPrefs: {
      //   id: null,
      //   notification_id: null,
      //   value: 0,
      //   details: null
      // },
      // TODO-DELETE 28/11/2022 SE COMENTA PORQUE SE ENVIAN POR CORREO A TRAVES DE CRON
      // newProjectsPrefs: {value: 0},
      marketingPromotionalContent: {},
      marketingPromotionalUserPref: {value: 0},
      marketingNotificationsContent: {},
      marketingNotificationsUserPref: {value: 0},
      // bonusesPrefs: {value: 0},
      // cashingPrefs: {value: 0},
      // TODO-DELETE 28/11/2022 SE COMENTA PORQUE LOS EVENTOS DE PROYECTO NO CONTENMPLABAN NOTIFICACIONES POR CORREO
      // eventsPrefs: {value: 0},

      // TODO-DELETE 28/11/2022 SE COMENTA PORQUE SE ENVIAN POR CORREO A TRAVES DE CRON (PROYECTOS SEMILLA)
      // newProjectsDetailsPrefs: [
      //   {type: 'term', label: 'Plazo de 1 año', prop: "1", value: false },
      //   {type: 'term', label: 'Plazo de 2 años', prop: "2", value: false },
      //   {type: 'term', label: 'Plazo de 3 años', prop: "3", value: false },
      //   {type: 'term', label: 'Plazo de 4 años', prop: "4", value: false },
      //   {type: 'term', label: 'Plazo de 5 años', prop: "5", value: false },
      //   {type: 'qualification', label: 'Calificación A+, A, A-', prop: "A", value: false },
      //   {type: 'qualification', label: 'Calificación B+, B, B-', prop: "B", value: false },
      //   {type: 'qualification', label: 'Calificación C+, C, C-', prop: "C", value: false },
      //   {type: 'qualification', label: 'Calificación D+, D, D-', prop: "D", value: false },
      //   {type: 'qualification', label: 'Calificación E+, E, E-', prop: "E", value: false },
      //   {type: 'qualification', label: 'Calificación F+, F, F-', prop: "F", value: false },
      // ]
    }
  },
  async mounted(){
    this.showLoading(true)
    await this.getNotifsPreferences();
    this.showLoading(false)
  },
  computed: {
    // TODO-DELETE 28/11/2022 SE COMENTA PORQUE SE ENVIAN POR CORREO A TRAVES DE CRON (PROYECTOS SEMILLA)
    // qualificationsPrefs (){
    //   return this.newProjectsDetailsPrefs.filter(f => f.type === 'qualification');
    // },
    // termsPrefs (){
    //   return this.newProjectsDetailsPrefs.filter(f => f.type === 'term');
    // },
  },
  methods: {
    async getNotifsPreferences(){
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getNotificationsPreferences`);
        const unots = res.data.user_notifs;
        const all = res.data.all_notifs;
        
        // TODO-DELETE 28/11/2022 SE COMENTA PORQUE ES TRANSACCIONAL (NO ES OPCIONAL PARA EL USUARIO) Y SE MANDA A TRAVES DE CRON
        // let pp = all.find(f => f.event_code === this.projectPaymentsCode);
        // this.projectPaymentsPrefs = unots.find(f => f.notification_id === pp.id) || this.newPref(pp);
        
        // TODO-DELETE 28/11/2022 SE COMENTA PORQUE SE ENVIAN POR CORREO A TRAVES DE CRON (PROYECTOS SEMILLA)
        // let np = all.find(f => f.event_code === this.newProjectsCode);
        // this.newProjectsPrefs = unots.find(f => f.notification_id === np.id) || this.newPref(np);
        // let details = this.newProjectsPrefs.details;
        // if(details != null){
        //   let prefs =  JSON.parse(details);
        //   prefs.forEach(f => {
        //     this.newProjectsDetailsPrefs.find(p => p.prop === f.prop).value = f.value;
        //   })
        // }
        // else{
        //   this.setDefaultNewProjectsPrefs(false);
        // }

        // let bb = all.find(f => f.event_code === this.bonusesCode);
        // this.bonusesPrefs = unots.find(f => f.notification_id === bb.id) || this.newPref(bb);

        /*let ce = all.find(f => f.event_code === this.cashingCode);
        this.cashingPrefs = unots.find(f => f.notification_id === ce.id) || this.newPref(ce);*/
        
        // TODO-DELETE 28/11/2022 SE COMENTA PORQUE LOS EVENTOS DE PROYECTO NO CONTENMPLABAN NOTIFICACIONES POR CORREO
        // let ee = all.find(f => f.event_code === this.eventsCode);
        // this.eventsPrefs = unots.find(f => f.notification_id === ee.id) || this.newPref(ee);

        this.marketingPromotionalContent = all.find(f => f.event_code == "advertising");
        this.marketingPromotionalUserPref = unots.find(f => f.notification_id === this.marketingPromotionalContent.id) || this.newPref(this.marketingPromotionalContent);
        
        this.marketingNotificationsContent = all.find(f => f.event_code == "notifications");
        this.marketingNotificationsUserPref = unots.find(f => f.notification_id === this.marketingNotificationsContent.id) || this.newPref(this.marketingNotificationsContent);
      }
      catch (e) {
        this.warn(e);
      }
    },
    // TODO-DELETE 28/11/2022 SE COMENTA PORQUE SE ENVIAN POR CORREO A TRAVES DE CRON (PROYECTOS SEMILLA)
    // async newProjectPrefsUpdated(){
    //   if(this.isBusy){ return false; }
    //   this.isBusy = true;
    //   this.setDefaultNewProjectsPrefs(this.newProjectsPrefs.value);
    //   await this.savePrefs();
    //   this.isBusy = false;
    // },
    newPref(notif){
      return {notification_id: notif.id, value: false };
    },
    // TODO-DELETE 28/11/2022 SE COMENTA PORQUE SE ENVIAN POR CORREO A TRAVES DE CRON (PROYECTOS SEMILLA)
    // setDefaultNewProjectsPrefs(val){
    //   this.newProjectsDetailsPrefs.forEach(f => f.value = val);
    // },
    async savePrefs(){
      this.showLoading(true)
      try {
        let payload = {preferences: this.getPrefsPayload()};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v2/investor/${this.InvestorId}/updateNotificationsPreferences`, payload);
        this.successNotif('¡Listo!', 'Tus preferencias han sido actualizadas', 2000);
      }
      catch (e) {
        this.warn(e)
        this.errorNotif('Error en guardado', 'No ha sido posible actualizar la información');
      }
      finally {
        this.showLoading(false)
      }
    },
    getPrefsPayload(){
      let prefs = [];
      // TODO-DELETE 28/11/2022 SE COMENTA PORQUE ES TRANSACCIONAL (NO ES OPCIONAL PARA EL USUARIO) Y SE MANDA A TRAVES DE CRON
      // prefs.push(this.setPref(this.projectPaymentsPrefs, null));
      // TODO-DELETE 28/11/2022 SE COMENTA PORQUE LOS EVENTOS DE PROYECTO NO CONTENMPLABAN NOTIFICACIONES POR CORREO
      // prefs.push(this.setPref(this.eventsPrefs, null,));
      //prefs.push(this.setPref(this.cashingPrefs, null));

      prefs.push(this.setPref(this.marketingPromotionalUserPref, null,));
      prefs.push(this.setPref(this.marketingNotificationsUserPref, null,));

      // TODO-DELETE 28/11/2022 SE COMENTA PORQUE SE ENVIAN POR CORREO A TRAVES DE CRON (PROYECTOS SEMILLA)
      // let details = JSON.stringify(this.newProjectsDetailsPrefs);
      // prefs.push(this.setPref(this.newProjectsPrefs, details));
      return prefs;
    },
    setPref(pref, details){
      return {notification_id: pref.notification_id, value: pref.value, details: details };
    },
    async updatePrefs(){
      if(this.isBusy){ return false; }
      this.isBusy = true;
      await this.savePrefs();
      this.isBusy = false;
    },
  }
}
</script>

<style scoped>

</style>