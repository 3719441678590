<template>
  <div>
    <big-title>
      Mi <span>Perfil</span>.
    </big-title>

    <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container profile-tabs">
      <vs-tab label="Mi cuenta" icon-pack="feather" icon="icon-user" >
        <investor-profile-v3 />
      </vs-tab>

      <vs-tab label="Notificaciones" icon-pack="feather" icon="icon-mail" >
        <notifications-page />
      </vs-tab>

      <vs-tab label="Mis contratos" icon-pack="feather" icon="icon-archive" >
        <contracts-page />
      </vs-tab>

      <vs-tab label="Seguridad" icon-pack="feather" icon="icon-shield" >
        <security-page />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import InvestorProfileV3 from "@views/investor/profile/InvestorProfileV3";
import SecurityPage from "@views/investor/security/SecurityPage";
import ContractsPage from "@views/investor/contracts/ContractsPage";
import NotificationsPage from "@views/investor/profile/NotificationsPage";
import ComplianceBanner from "@components/compliance/ComplianceBanner";

export default {
  name: "ProfilePage",
  props: {
    tab: { type: String, required: true }
  },
  data(){
    return {
      activeTab: 0,
    }
  },
  async beforeMount() {
    this.activeTab = this.getInitialTabIndexFromCode();
  },
  components: {
    InvestorProfileV3,
    ContractsPage,
    SecurityPage,
    NotificationsPage,
    ComplianceBanner
  },
  methods: {
    getInitialTabIndexFromCode(){
      switch (this.tab) {
        // case "informacion":
        //   return 0;
        case "cuenta":
          return 0;
        case "notificaciones":
          return 1;
        case "contratos":
          return 2;
        case "seguridad":
          return 3;
        default:
          return 0;
      }
    }
  }
}
</script>

<style >
.profile-tabs .vs-tabs--li button {
  font-family: 'Gilroybold',sans-serif !important;
  font-weight: normal !important;
}
</style>