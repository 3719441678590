import { render, staticRenderFns } from "./InvestorContactData.vue?vue&type=template&id=20c973b5&scoped=true&"
import script from "./InvestorContactData.vue?vue&type=script&lang=js&"
export * from "./InvestorContactData.vue?vue&type=script&lang=js&"
import style0 from "./InvestorContactData.vue?vue&type=style&index=0&id=20c973b5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c973b5",
  null
  
)

export default component.exports