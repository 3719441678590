<template>
  <div>
    <!-- <vx-card> -->
      <div class="vx-row">
        <div class="vx-col w-full md:w-2/3" >
          <h4>Tus cuentas de banco.</h4>
          <div v-if="formattedAccounts.length > 0">
            <!-- <p class="mb-2 mt-2">
              <span class="bold">¡No olvides tener a la mano tu Segundo Factor de Autenticación!</span>
            </p> -->
            <p class="mb-2 mt-2">
              Consulta o administra las cuentas de banco con las que has abonado fondos en la Red.<br>
              <span class="bold">Recuerda que cada vez que abones desde una nueva cuenta, ésta se registrará automáticamente
                mientras tú seas titular de la misma, de lo contrario, tus fondos serán reembolsados.</span>
            </p>
            <p class="mb-2 mt-4">
              {{this.midText}}
            </p>
            <p class="mb-2 mt-4">
              En caso de tener más de una cuenta, selecciona una CLABE predeterminada al dar clic en el icono
              de estrella dentro de las acciones. <span class="bold">Esta será tu cuenta principal y estará resaltada en color negro</span>.
            </p>
            <!-- tabla de cuentas de banco -->
            <div class="vx-row mt-6 mb-2">
              <div class="vx-col xxl:w-2/3 lg:w-full w-full">
                <div class="data-list-container data-list-list-view data-list-rows vs-con-loading__container" id="main-div">
                  <vs-table class="mt-1" ref="accountsTable" :data="formattedAccounts" noDataText="No hay cuentas registradas">
                    <template slot="thead">
                      <vs-th class="bold">Banco</vs-th>
                      <vs-th class="bold">CLABE / SWIFT / BIC</vs-th>
                      <!-- <vs-th class="bold">Estado</vs-th> -->
                      <vs-th class="bold">Acciones</vs-th>
                    </template>

                    <template slot-scope="{data}">
                      <tbody>
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :state="isAccountSelected(tr) ? 'black' : null" >
                        <vs-td>
                          {{ tr.bank_name }}
                        </vs-td>
                        <vs-td>
                          {{ tr.clabe }}
                        </vs-td>
                        <!-- <vs-td>
                          {{ tr.status_name }}
                        </vs-td> -->
                        <!--vs-td>
                          <span v-if="!isAccountSelected(tr) && isSelectable(tr) && isVerified(tr)" class="text-sm text-primary">
                            Seleccionar
                          </span>
                        </vs-td-->
                        <vs-td>
                          <div class="flex items-center flex-grow flex-no-wrap w-full">
                            <feather-icon icon="StarIcon" :svgClasses="getSelectIconClass(tr)" @click.stop="selectAccountRequest(tr)"/>
                            <!-- <feather-icon icon="EyeIcon" :svgClasses="klassFull" class="ml-5" @click.stop="showAccountRequest(tr)"/> -->
                            <!-- <feather-icon icon="EditIcon" :svgClasses="getEditIconClass(tr)" class="ml-5" @click.stop="editAccountRequest(tr)"/> -->
                            <!--feather-icon icon="DownloadIcon" :svgClasses="klassFull" class="ml-5" @click.stop="downloadAccountRequest(tr)"/-->
                            <feather-icon icon="Trash2Icon" :svgClasses="klassFull" class="ml-5" @click.stop="deleteAccountRequest(tr)"/>
                          </div>
                        </vs-td>
                      </vs-tr>
                      </tbody>
                    </template>
                  </vs-table>
                </div>
              </div>

              <!-- <div class="vx-col w-full mt-5">
                <vs-button class="px-3" @click="showNewAccountPopup">Agregar cuenta</vs-button>
              </div> -->
            </div>
            <!-- fin tabla de cuentas de banco -->
          </div>
          <div v-else>
            <p class="mb-2 mt-2">
              En este apartado podrás consultar o administrar las cuentas de banco con las que hayas abonado fondos
              en la Red. <span class="bold">Recuerda que cada vez que abones desde una nueva cuenta, ésta se registrará
              automáticamente mientras tú seas titular de la misma, de lo contrario, tus fondos serán reembolsados.</span>
            </p>
            <p class="mb-2 mt-4">
              {{this.midText}}
            </p>
            <p class="mb-2 mt-4">
              Para abonar fondos y registrar una nueva cuenta de banco verifica que ya tengas dado de alta tu <span class="bold">RFC</span>
              o <span class="bold">CURP</span> en tu perfil de RedGirasol y da clic a continuación.
            </p>  
            <div class="vx-col w-full mt-5">
              <vs-button class="px-3" @click="goToPayFunds">Abonar fondos</vs-button>
            </div>          
          </div>
        </div>
      </div>
    <!-- </vx-card> -->

    <!-- mensajes y modales de edicion, registro, etc -->
    <vs-popup class="mid-popup header-primary" :active.sync="isPopupActive" :title="currentTitle" v-if="isPopupActive">
      <form-bank-account
        ref="formBankAccount"
        v-if="onNewView || onEditView"
        :formatted-account="currentFormattedAccount"
        :user-account="currentUserAccount"
        :on-edit-mode="onEditView"
        :is-moral="isMoral"
        :user-id="userId"
        :person-type="personType"
        :section-info="sectionInfo"
        :has-backoffice-access="hasBackofficeAccess"
        :user-data="userData"
        :doc-info="docInfo"
        :disable-edition="formEditionDisabled"
        @on-account-saved="refreshAccounts"
        @on-save-account-auth-request="requestAuthSaveAccount"
        @on-finish="onFinish"
        :collections="collections" />
    </vs-popup>

    <!-- Componente para segundo factor -->
    <two-factor-auth
      :user-id="UserId"
      custom-indication-message="Por favor ingresa los 6 dígitos de tu Segundo Factor de Autenticación para ejecutar la operación."
      @on-code-validated="onAuth" />
  </div>
</template>

<script>
import FormBankAccount from "@views/investor/profile/bank-accounts/FormBankAccount";
import auth2faHelper from "@mixins/auth2faHelper";
import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
import {BankAccountStatus} from "@/bl/BankAccountStatus";
export default {
  name: "InvestorBankAccounts",
  components: {
    FormBankAccount,
    TwoFactorAuth,
  },
  mixins: [auth2faHelper],
  props: {
    isMoral: { type: Boolean, required: true },
    userId: { type: [String,Number], required: true },
    personType: { type: [String,Number], required: true },
    hasBackofficeAccess: { type: Boolean, required: true },
    userData: { type: Object, required: true },
  },
  data(){
    return {
      midText:"Puedes tener tantas cuentas sean necesarias, pero considera que necesitas tener al menos una cuenta para poder operar dentro de RedGirasol con la que podrás retirar los fondos de tu saldo disponible en el momento en que lo necesites.",
      userAccounts: [],
      formattedAccounts: [],
      accountIdSelected: null,
      accountIdToDelete: null,
      createdAccountId: null,
      preselectedAccountId: null,
      klassBase: "h-5 w-5 stroke-current",
      klassFull: "h-5 w-5 stroke-current hover:text-primary cursor-pointer",

      collections: {
        cities: [],
        states: [],
        countries: [],
        foreign_countries: [],
        banks: [],
        business_activities: [],
      },

      mexicoCountryId: 700,
      section_id_pm: 107,
      section_id_pfae: 103,

      currentTitle: "",
      currentView: null,
      currentUserAccount: null,
      currentFormattedAccount: null,

      onError: false,
      errorMssg: null,

      sectionInfo: null,
      docInfo: null,
      formEditionDisabled: false,

      currentIdForSelection: null,
      currentIdToDelete: null,

      factorEnabled: false,
      currentAuthAction: null,
    }
  },
  created() {
    this.AUTH_ACTION_SELECT_ACCOUNT = 1;
    this.AUTH_ACTION_DELETE_ACCOUNT = 2;
    this.AUTH_ACTION_SAVE_ACCOUNT = 3;
  },
  async beforeMount(){
    this.showLoading(true)
    await this.getCollections();
    await this.getUserAccounts();
    this.factorEnabled = await this.getUser2FAStatus(this.UserId);
    await this.getSectionFields();
    this.setDefaults();
    this.showLoading(false)
  },
  computed: {
    isPopupActive: {
      get() {
        return this.$store.state.isBankAccountsPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_BANK_ACCOUNTS_POPUP_ACTIVE", val);
      }
    },
    onNewView(){
      return this.currentView === 0;
    },
    onEditView(){
      return this.currentView === 1;
    },
  },
  methods: {
    async refreshAccounts(){
      await this.getUserAccounts();
    },
    async onFinish(){
      this.isPopupActive = false;
      //await this.getUserAccounts();
    },
    getSelectIconClass(tr){
      let klass = this.klassBase;
      if(this.isAccountSelected(tr)){
        klass += " fill-current text-primary";
      }
      else {
        if(this.isSelectable(tr)){
          klass += " hover:text-primary cursor-pointer";
        }
        else {
          klass += " opacity-30";
        }
      }
      return klass;
    },
    getEditIconClass(tr){
      if(this.isAccountSelected(tr) || tr.is_verified){
        let klass = this.klassBase;
        klass += " opacity-25";
        return klass;
      }
      else {
        return this.klassFull;
      }
    },
    async getUserAccounts(){
      try {
        const res = await axios.get(`api/v1/userBankAccounts/${this.UserId}`);
        const accs = res.data;
        this.userAccounts = accs;
        this.formattedAccounts = [];
        accs.forEach(f => {
          let nacc = this.getFormattedAccount(f);
          this.formattedAccounts.push(nacc);
        });

        this.formattedAccounts.sort(function(a, b) {
          if(a.is_default){
            return -1
          }
          else if(b.is_default){
            return 1;
          }
          else if(a.is_verified && b.is_verified){
            return a.clabe > b.clabe;
          }
          else if(a.is_verified && !b.is_verified){
            return -1
          }
          else if(!a.is_verified && b.is_verified){
            return 1
          }
          return 0;
        });
      }
      catch (e) {
      }
    },
    getFormattedAccount(f){
      let nacc = {
        id: f.id,
        bank_id: f.bank_id,
        is_default: f.is_default === 1,
        is_verified: f.verified === BankAccountStatus.VERIFIED,
        status_name: BankAccountStatus.getAccountStatusName(f.verified),
      }

      if(f.bank && f.bank.country_id === this.mexicoCountryId){
        nacc.bank_name = f.bank.short_name;
        nacc.clabe = f.clabe;
      }
      else {
        nacc.bank_name = f.bank.name;
        nacc.clabe = f.bic_swift;
      }
      return nacc;
    },
    setDefaults(){
      let account = this.formattedAccounts.find(f => f.is_default);
      if(account != null) {
        this.accountIdSelected = account.id;
      }
    },
    async getSectionFields(){
      try {
        let sid = this.isMoral ? this.section_id_pm : this.section_id_pfae;
        let response = await axios.get(`/api/v1/forms/getSectionFields/${sid}`);
        this.sectionInfo = response.data.section;

        let response2 = await axios.get(`/storagev3/documents/section/${sid}`);
        this.docInfo = response2.data[0];
      }
      catch (e) {
      }
    },
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList','foreignCountriesList','banksForMexico'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        let colls = res.data;
        this.collections.countries = colls.countriesList;
        this.collections.foreign_countries = colls.countriesList;
        this.collections.banks = colls.banksForMexico;
      }
      catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    async selectAccountRequest(tr){
      if(tr.id === this.accountIdSelected){
        return;
      }
      if(!this.isVerified(tr)){
        this.warningNotifDialog('Cuenta no verificada', 'No se puede marcar esta cuenta como predeterminada ya que aún no ha sido verificada.');
      }
      else {
        if(!this.checkForFactorEnabled()){
          return;
        }
        this.currentIdForSelection = tr.id;
        let mssg = "Al seleccionarla esta será la cuenta en la que recibirás tus fondos al retirar de tu cuenta de RedGirasol. Podrás cambiarla cuando tu desees en esta misma sección.";
        this.confirmationDialog(this.requestAuthSelectAccount, "¿Estás seguro que deseas que esta sea tu cuenta predeterminada?", mssg, "Confirmar");
      }
    },
    async requestAuthSelectAccount(){
      this.currentAuthAction = this.AUTH_ACTION_SELECT_ACCOUNT;
      this.requestAuth();
    },
    async requestAuthDeleteAccount(){
      this.currentAuthAction = this.AUTH_ACTION_DELETE_ACCOUNT;
      this.requestAuth();
    },
    async requestAuthSaveAccount(){
      this.currentAuthAction = this.AUTH_ACTION_SAVE_ACCOUNT;
      this.requestAuth();
    },
    async onAuth(code){
      switch (this.currentAuthAction) {
        case this.AUTH_ACTION_SELECT_ACCOUNT:
          await this.doSelectAccount(code);
          break;
        case this.AUTH_ACTION_DELETE_ACCOUNT:
          await this.doDeleteAccount(code);
          break;
        case this.AUTH_ACTION_SAVE_ACCOUNT:
            await this.$refs.formBankAccount.doSaveChanges(code);
          break;
      }
    },
    requestAuth(){
      this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true);
    },
    async doSelectAccount(code){
      this.showLoading(true)
      try {
        let payload = {bank_account_id: this.currentIdForSelection, code: code};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v1/userBankAccounts/${this.userId}/setDefault`, payload);
        this.successNotif("Preferencias actualizadas", "Tu cuenta predeterminada ha sido actualizada");
        await this.getUserAccounts();
        this.setDefaults();
      }
      catch (e) {
        this.failedOperationNotif();
      }
      this.showLoading(false)
    },
    setOnNewView(){
      this.formEditionDisabled = false;
      this.currentTitle = "Nueva cuenta.";
      this.currentView = 0;
    },
    setOnEditView(){
      this.formEditionDisabled = false;
      this.currentTitle = "Editar cuenta.";
      this.currentView = 1;
    },
    setOnShowView(){
      this.formEditionDisabled = true;
      this.currentTitle = "Detalle de cuenta.";
      this.currentView = 1;
    },
    async showAccountRequest(tr){
      this.currentFormattedAccount = tr;
      this.currentUserAccount = this.userAccounts.find(f => f.id === tr.id);
      this.setOnShowView();
      this.isPopupActive = true;
    },
    async editAccountRequest(tr){
      if(!this.checkForFactorEnabled()){
        return;
      }

      if(tr.is_default || tr.is_verified){
        return;
      }

      this.currentFormattedAccount = tr;
      this.currentUserAccount = this.userAccounts.find(f => f.id === tr.id);
      this.setOnEditView();
      this.isPopupActive = true;
    },
    async downloadAccountRequest(tr){
    },
    async deleteAccountRequest(tr){
      if(!this.checkForFactorEnabled()){
        return;
      }

      if(this.formattedAccounts.length > 1){
        this.currentIdToDelete = tr.id;
        let verifiedCount = this.formattedAccounts.filter(f => f.is_verified).length;

        if(tr.is_verified){
          if(verifiedCount === 1){
            // no se puede eliminar si es la unica
            let mssg = "Para eliminarla será necesario agregar una nueva abonando fondos a tu cuenta de RedGirasol. Recuerda que debes tener al menos una cuenta de banco registrada.";
            this.cannotDeleteMssg(mssg);
          }
          else {
            let mssg = "Recuerda que para registrarla nuevamente deberás abonar fondos a través de una transferencia desde tu banca en línea."+
              "Este proceso no podrá revertirse una vez eliminada.";
            this.confirmDeletion(mssg);
          }
        }
        else{
          let mssg = "Recuerda que para registrarla nuevamente deberás abonar fondos a través de una transferencia desde tu banca en línea."+
          "Este proceso no podrá revertirse una vez eliminada.";
          this.confirmDeletion(mssg);
        }
      }
      else {
        let mssg = "Para eliminarla será necesario agregar una nueva abonando fondos a tu cuenta de RedGirasol. Recuerda que debes tener al menos una cuenta de banco registrada.";
        this.cannotDeleteMssg(mssg);
      }
    },
    async doDeleteAccount(code){
      this.showLoading(true)
      try {
        let payload = {bank_account_id: this.currentIdToDelete, code: code};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v1/userBankAccounts/${this.userId}/delete`, payload);
        this.successNotif("Cuenta eliminada", "La cuenta ha sido eliminada exitosamente");
        await this.getUserAccounts();
        this.setDefaults();
      }
      catch (e) {
        if(e && e.response && e.response.data && e.response.data.error){
          this.errorNotifDialog("No ha sido posible eliminar la cuenta bancaria", e.response.data.error);
        }
        else {
          this.failedOperationNotif();
        }
      }
      this.showLoading(false)
    },
    confirmDeletion(mssg){
      this.confirmationDialog(this.requestAuthDeleteAccount,"¿Estás seguro que deseas eliminar esta cuenta?", mssg, "Eliminar", "Cancelar");
    },
    cannotDeleteMssg(mssg){
      this.confirmationDialog(this.goToPayFunds,"No es posible eliminar esta cuenta", mssg, "Abonar fondos", "Cancelar");
    },
    showNewAccountPopup(){
      if(!this.checkForFactorEnabled()){
        return;
      }

      this.currentUserAccount = null;
      this.currentFormattedAccount = null;
      this.setOnNewView();
      this.isPopupActive = true;
    },
    checkForFactorEnabled(){
      if(!this.factorEnabled){
        this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Autenticación necesaria",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });
        return false;
      }
      return true;
    },
    async goTo2FaPage(){
      await this.$router.push({name: 'auth2FaInversionista'});
    },
    isAccountSelected(tr){
      return tr.id === this.accountIdSelected;
    },
    isSelectable(tr){
      return tr.is_verified;
    },
    isVerified(tr){
      return tr.is_verified;
    },
    clean(){
      this.onError = false
      this.errorMssg = null
    },
    async goToPayFunds() {
      await this.$router.push({name: 'abonarFondos'});
    },
  }
}
</script>

<style scoped>

</style>