<template>
  <div>
    <!-- <vx-card card-background="primary" content-color="white" title-color="white" title="Tu información es de suma importancia." class="">
      <div class="vx-col xxl:w-3/4 w-full">
        <p>
          En RedGirasol tu información es fundamental para invertir en energía limpia. Completa y mantén actualizadas
          las siguientes secciones para conocerte y tener una Red más segura.
          Identifica los apartados que son obligatorios para invertir y ¡no te quedes fuera!
        </p>
      </div>
    </vx-card> -->

    <!-- Datos personales y verificacion de identidad -->
    <investor-about-you-data></investor-about-you-data>

    <!-- Datos de contacto -->
    <investor-contact-data></investor-contact-data>

    <!-- Domicilio -->
    <investor-address-data></investor-address-data>

    <!-- Perfil fiscal-->
    <investor-tax-profile-data v-if="userHasTaxProfile"></investor-tax-profile-data>

    <!-- Cuentas de banco -->
    <investor-bank-data></investor-bank-data>

    <!-- Beneficiarios -->
    <investor-beneficiaries-data v-if="isUserPf"></investor-beneficiaries-data>

    <!-- CLOSE ACCOUNT CARD -->
    <vx-card title="Cerrar mi cuenta." v-if="isMounted">
      <div class="vx-row" v-if="!userHasCancellationRequest">
        <div class="vx-col lg:w-3/4 w-full">
          <p class="mb-5">En este apartado podrás cerrar tu cuenta en RedGirasol.
            Recuerda que para hacerlo tendrás que haber concluido cualquier compromiso de inversión, es decir,
            que al momento de cerrarla no tengas ningún pago pendiente, ni saldo disponible en tu cuenta.
            ¡Esperamos que nunca te desconectes de la Red!</p>
          <!-- Save Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button color="dark" @click.stop="closeAccountRequest">Cerrar cuenta</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row" v-else>
        <div class="vx-col w-full">
          <p class="mb-4 bold">Tu cuenta está en proceso de ser cerrada. </p>
          <p>Realizaste la solicitud de cierre el <span class="bold"> {{ userCancellationDate }}</span>.</p>
          <p class="mb-5">Pasados 30 días, el acceso a tu cuenta se revocará, específicamente el día <span class="bold">
              {{ userCancellationLimit }}</span>.</p>
          <p class="mb-5">Si deseas mantener tu cuenta con nosotros, limpiar el planeta y seguir impulsando la transición
            energética de México
            da clic en el botón que se encuentra a continuación. ¡No te desconectes!</p>
          <!-- Save Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button type="border" color="dark" @click.stop="revokeAccountCancellation">Deseo continuar en la
                  Red</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END CLOSE ACCOUNT CARD -->

    <!-- 
    <vx-card class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Sobre ti.
              </p>
            </div>
          </div>
          <p>
            Una vez completada tu verificación de identidad podrás consultar en esta sección tus datos y documentación general.
          </p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click.stop="goToAboutYou">Actualizar</vs-button>
        </div>
      </div>
    </vx-card> -->

    <!-- <vx-card class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                {{contactSectionTitle}}
              </p>
            </div>
          </div>
          <p>
            {{contactSectionDescription}}
          </p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click.stop="goToContact">Actualizar</vs-button>
        </div>
      </div>
    </vx-card> -->

    <!-- <vx-card v-if="isUserPf" class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Teléfono.
              </p>
            </div>
          </div>
          <p>
            Mantén actualizado tu télefono para aumentar la seguridad de tu cuenta.
          </p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click.stop="goToPhone">Actualizar</vs-button>
        </div>
      </div>
    </vx-card> -->

    <!-- <vx-card class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Banco. (Obligatorio para retirar)
              </p>
            </div>
          </div>
          <p>
            Agrega, consulta o administra las cuentas de banco con las que deseas participar en la Red.
            Puedes tener tantas cuentas sean necesarias, pero considera que necesitas tener al menos una cuenta verificada para
            poder operar dentro de RedGirasol con la que podrás retirar los fondos de tu saldo disponible en el momento en que lo necesites.
          </p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click.stop="goToBank">Actualizar</vs-button>
        </div>
      </div>
    </vx-card> -->

    <!-- <vx-card class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Beneficiarios.
              </p>
            </div>
          </div>
          <p>
            ¿Quiénes son tus beneficiarios? Consulta y administra los datos e información más relevante de tus beneficiarios.
          </p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click.stop="goToBeneficiaries">Actualizar</vs-button>
        </div>
      </div>
    </vx-card> -->

    <!-- <vx-card v-if="userHasTaxProfile" class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Perfil fiscal.
              </p>
            </div>
          </div>
          <p>
            Es importante que registres tus datos fiscales (RFC, Nombre, Código Postal y régimen).
          </p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click.stop="goToTaxProfile">Actualizar</vs-button>
        </div>
      </div>
    </vx-card> -->
    <!-- cancellation account invalid popup -->
    <vs-popup :active.sync="cancellationAccountNotValid" title="Cierre de cuenta">
      <div class="p-3 pt-1">
        <div class="vx-row">
          <div class="vx-col w-full">
            <h5>No es posible proceder con el cierre de cuenta.</h5>
            <p class="mt-4 mb-5">
              No es posible iniciar el proceso de cierre de tu cuenta, debido a que existen una o más condiciones que lo
              impiden:
            </p>

            <p class="bold mb-3" v-for="(validation, idx) in cancellationAccountValidations" :key="idx">
              - {{ validation }}
            </p>
          </div>
          <div class="vx-col w-full mt-3">
            <div class="flex items-center flex-grow justify-left mt-1">
              <vs-button color="dark" @click.stop="cancellationAccountNotValid = false">Finalizar</vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import userComplianceHelper from "@/helpers/userComplianceHelper";
import dateHelper from "@mixins/dateHelper";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import InvestorAboutYouData from "@/components/investor/profile/InvestorAboutYouData";
import InvestorContactData from "@/components/investor/profile/InvestorContactData";
import InvestorAddressData from "@/components/investor/profile/InvestorAddressData";
import InvestorTaxProfileData from "@/components/investor/profile/InvestorTaxProfileData";
import InvestorBankData from "@/components/investor/profile/InvestorBankData";
import InvestorBeneficiariesData from "@/components/investor/profile/InvestorBeneficiariesData";

export default {
  name: "InvestorProfileV3",
  mixins: [dateHelper, userIdentityHelper, userComplianceHelper],
  components: {
    InvestorAboutYouData,
    InvestorContactData,
    InvestorAddressData,
    InvestorTaxProfileData,
    InvestorBankData,
    InvestorBeneficiariesData
  },
  data() {
    return {
      nationalPmPersonType: 0,
      nationalPfPersonType: 1,
      foreignPfPersonType: 2,
      isMounted: false,
      identity: null,
      status: null,
      processWasStarted: false,

      cancellationAccountValidations: [],
      cancellationAccountNotValid: null,

    }
  },
  async mounted() {
    this.isMounted = false
    this.showLoading(true)
    await this.getIdentityInfo();
    this.isMounted = true
    this.showLoading(false)
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    isUserPf() {
      return (this.UserPersonType == this.nationalPfPersonType || this.UserPersonType == this.foreignPfPersonType);
    },
    userHasTaxProfile() {
      return (this.UserPersonType == this.nationalPfPersonType || this.UserPersonType == this.nationalPmPersonType);
    },
    contactSectionTitle() {
      return (this.isUserPf) ? "Domiclio" : "Contacto";
    },
    contactSectionDescription() {
      return (this.isUserPf) ? "En esta sección se encuentran datos importantes sobre tu ubicación, domicilio, entre otros." : "En esta sección se encuentran datos importantes sobre tu ubicación, domicilio, teléfono, entre otros.";
    },
    userHasCancellationRequest() {
      return this.roleGeneralData.cancellationAccountRequest !== null;
    },
    userCancellationDate() {
      if (this.roleGeneralData.cancellationAccountRequest !== null) {
        return this.explicitDateFormat(this.roleGeneralData.cancellationAccountRequest);
      }
    },
    userCancellationLimit() {
      if (this.roleGeneralData.cancellationAccountLimit !== null) {
        return this.regularDateFormat(this.roleGeneralData.cancellationAccountLimit);
      }
    },
  },
  methods: {
    async reloadStatus() {
      this.showLoading(true)
      await this.getIdentityInfo();
      this.processWasStarted = false;
      this.showLoading(false)
    },
    async onMatiProcessStarted() {
      this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
      this.processWasStarted = true;
    },
    async goToAboutYou() {
      await this.$router.push({ name: 'perfilInversionistaAbout' });
    },
    async goToContact() {
      await this.$router.push({ name: 'perfilInversionistaContact' });
    },
    async goToPhone() {
      await this.$router.push({ name: 'perfilInversionistaPhone' });
    },
    async goToBank() {
      await this.$router.push({ name: 'perfilInversionistaBank' });
    },
    async goToBeneficiaries() {
      await this.$router.push({ name: 'perfilInversionistaBeneficiaries' });
    },
    async goToKycPage() {
      await this.$router.push({ name: 'kycInversionista' });
    },
    async goToTaxProfile() {
      await this.$router.push({ name: 'perfilFiscalInversionista' });
    },
    async openMatiPopup() {
      this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
    },
    async getIdentityInfo() {
      try {
        this.status = await this.getUserIdentityStatus(this.UserId);
      }
      catch (e) {
      }
    },
    async closeAccountRequest() {
      // solicitar datos de validacion de cierre de cuenta
      this.showLoading(true)
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/checkAccountCancellationConditions`);
        const hasTrx = res.data.hasTrx;
        const activeProjsCount = res.data.activeProjsCount;
        const finalBalance = res.data.finalBalance;
        const planActive = res.data.planActive;
        let validations = [];

        if (finalBalance > 0) {
          validations.push('Aún tienes saldo en tu cuenta. No debes tener saldo en tu cuenta para poder cancelar tu contrato. Puedes retirarlo si así lo deseas.');
        }
        if (activeProjsCount > 0) {
          validations.push('Aún cuentas con proyectos que están activos, es decir, que están en proceso de fondeo, en instalación o en proceso de cobranza.');
        }
        if (planActive) {
          validations.push('Cuentas con un plan de inversión activo. Te invitamos a desactivarlo para poder cerrar tu cuenta.')
        }
        if (hasTrx) {
          // PANEL DE CUMPLIMIENTO
          const requirements = await this.getComplianceRequirements(this.UserTags);
          if (requirements.length > 0) {
            validations.push('Para que puedas cerrar la cuenta es necesario primero atender los pendientes del Panel de Cumplimiento.');
          }
        }
        if (validations.length > 0) {
          this.cancellationAccountValidations = validations;
          this.cancellationAccountNotValid = true;
        }
        else {
          let mssg = 'Confirma que deseas proceder con el cierre de tu cuenta en RedGirasol.' +
            '\n\nA partir de este momento contarás con un plazo de 30 días para poder acceder a tu cuenta y verificar movimientos, datos, etc. ' +
            'Una vez cumplidos, no podrás volver a acceder.';
          this.confirmationDialog(
            this.doCancelAccount,
            'Confirma la acción',
            mssg,
            'Confirmar'
          );
        }
      }
      catch (e) {
        this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
      }
      this.showLoading(false)
    },
    async doCancelAccount() {
      this.showLoading(true)
      try {
        let payload = {};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v2/investor/${this.InvestorId}/doCancelAccount`, payload);
        await this.onUserInfoUpdated();
        this.successNotifDialog('Petición de cierre de cuenta', 'El proceso de cierre de cuenta ha sido iniciado correctamente.' +
          ' Recuerda que tendrás 30 días para seguir usando tu cuenta, antes de que el acceso se revoque completamente.');
      }
      catch (e) {
        if (e && e.response && e.response.data && e.response.data.error) {
          this.errorNotifDialog('Error en petición', e.response.data.error);
        }
        else {
          this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
        }
      }
      this.showLoading(false)
    },
    async revokeAccountCancellation() {
      let mssg = 'Confirma que deseas cancelar la solicitud de cierre de tu cuenta en RedGirasol.' +
        '\n\nTu cuenta se mantendrá sin cambios y podrás usarla de manera normal.';
      this.confirmationDialog(
        this.doRevokeAccountCancellation,
        'Confirma la acción',
        mssg,
        'Confirmar'
      );
    },
    async doRevokeAccountCancellation() {
      this.showLoading(true)
      try {
        let payload = {};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v2/investor/${this.InvestorId}/doRevokeAccountCancellation`, payload);
        await this.onUserInfoUpdated();
        this.successNotifDialog('Solicitud de cierre de cuenta cancelada', 'Podrás continuar utilizando tu cuenta de forma normal.');
      }
      catch (e) {
        if (e && e.response && e.response.data && e.response.data.error) {
          this.errorNotifDialog('Error en petición', e.response.data.error);
        }
        else {
          this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
        }
      }
      this.showLoading(false)
    },
  }
}
</script>

<style scoped>
.card-disabled {
  opacity: 0.4;
}
</style>