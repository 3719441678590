<template>
  <div>
    <vs-alert v-if="isMounted && totalPercentageAvailable <= 0" icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="danger">
      <span class="font-regular">No puedes agregar más beneficiarios porque no hay <strong>porcentaje de participación</strong> disponible.
      Elimina o reasigna los porcentajes ya establecidos.</span>
    </vs-alert>
    <vs-alert v-if="isMounted && totalPercentageAvailable > 0" icon-pack="feather" icon="icon-check" class="h-full mt-4" color="success">
      <span class="font-regular">El porcentaje de participación disponible es de <strong>{{prcgFormat(totalPercentageAvailable*100, 2)}}</strong>.</span>
    </vs-alert>
    <!-- SECCIONES -->
    <div v-if="isMounted && !failed" >
      <vx-card v-for="section in allSections" :key="section.id" :title="section.public_name" class="mb-5">
        <p class="mb-4" v-if="section.public_description != null">{{section.public_description}}</p>
        <div class="vx-row">
          <template v-for="f in section.fields">
            <!-- Campos automaticos -->
            <form-field 
              ref="allSections"
              v-if="isAutoFormField(f)"
              :key="f.id"
              :f="f"
              :dataContainer="getDataContainer(section.id)"
              :collectionsRoot="collections"
              :countriesList="collections.countries"
              :onChangeReceptor="onFieldChange"
              :evaluator="evaluateCondition" />
          </template>
        </div>
      </vx-card>
      <!-- Save Cancel Buttons -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button @click="saveBeneficiaryData()">Guardar cambios</vs-button>
            <vs-button type="border" class="ml-4" @click="goBackScreen()">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </div>
    <load-error v-if="failed" />
  </div>
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import formatHelper from '@components/mixins/formatHelper';
import FormField from '@components/forms/FormField.vue';

export default {
  name: "beneficiary-form",
  props: {
   newRecord: Boolean,
   beneficiaryId: [String, Number],
    role: {
      type: [String],
      required: true,
    }
  },
  mixins: [formHelper, inputHelper, dateHelper, formatHelper],
  data(){
    return {
      errorCode: 0,
      onError: false,
      allSections: [],
      loading: false,  
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,
      documentsStates: [],

      beneficiary: {
        address: {}
      },

      address: {
        city_id: null
      },

      collections: {
        countries: [],
        cities: [],

        states_beneficiary_address: [],
        cities_beneficiary_address: [],
        neighborhoods_beneficiary_address: []
      },

      totalPercentageAvailable: 1,

      validatedFields: [], 

      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,
    }
  },
  components: {
    FormField
  },
  async mounted() {
    this.showLoading(true);
    this.isMounted = false;
    await this.getCollections();
    if (!this.newRecord) {
      await this.getBeneficiaryInfo();
    }
    await this.getFormInfo();
    this.isMounted = true;
    this.showLoading(false);
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },
  },
  computed: {
    is_mexico_selected(){
      return this.address.country_id == this.mexicoCountryId;
    },
    is_mexico_not_selected(){
      return this.address.country_id != this.mexicoCountryId;
    },
  },
  methods: {
    async getFormInfo(){
      try{
        await this.getSectionInfo(82);
        await this.getSectionInfo(83);
        await this.getSectionInfo(84);

       this.allSections.forEach(s => {
          s.documentFields = [];
          s.fields.forEach(f => {
            this.formFieldSetter(f, this, true);

            // check for documents
            if(f.doc_id != null){
              let ff = {formField: f, documentSet: null, target_id: null };
              let dset = this.documentsStates.find(sid => sid.id == f.doc_id);
              if(dset){
                ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                ff.documentSet = dset;
              }
              s.documentFields.push(ff);
            }
          });
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getSectionInfo(id){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + id);
        this.allSections.push(response.data.section);
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getCollections(){
      try {
        let response1 = await axios.get('/api/v1/forms/getCountriesList');
        this.collections.countries = response1.data;

        let response2 = await axios.get('/api/register/states');
        this.collections.states = response2.data;

        let response3 = await axios.get(`/api/v1/userBeneficiaries/${this.UserId}/${this.role}/getBeneficiaryPercentage`);
        this.totalPercentageAvailable = response3.data;
      }
      catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    async getBeneficiaryInfo() {
      try {
        let response = await axios.get(`/api/v1/beneficiary/${this.beneficiaryId}?with[]=address`);
        this.onError = false;
        this.beneficiary = response.data;
        this.beneficiary.prev_beneficiary_percentage = this.beneficiary.beneficiary_percentage;
        this.beneficiary.beneficiary_percentage = this.beneficiary.beneficiary_percentage * 100;
        this.address = this.beneficiary.address;
        await this.reload_personal_cities();
      } catch (error) {
        this.processError(error.status);
      }
    },
    /* on_change methods */
    async reload_personal_cities(){
      try {
        let sid = this.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async goBackScreen() {
      await this.$emit('on-cancel');
      /*if (this.newRecord) {
        this.$router.push({ name: 'misBeneficiarios' });
      }else{
        this.$router.push({ name: 'infoBeneficiario', params: { id: this.beneficiaryId} });
      }*/
    },
    async saveBeneficiaryData() {
      let result = await this.runValidations();
      if(!result){
        this.missingFieldsNotif();
      }
      else{
        this.showLoading(true);
        let res = await this.saveFormInfo();
        this.showLoading(false);
        if(res == true){
          this.saveSuccessNotif();
          // this.$router.push({ name: 'misBeneficiarios' });
          await this.$emit('on-saved');
        }
        else {
          this.failedOperationNotif(null, this.errorMssg);
        }
      }
    },
    async runValidations() {
        // validar radio
        let res1 = await this.validateFormFields();
        if(!res1){
            return false;
        }
        // validar inputs instanciados en la vista
        let res2 = await this.$validator.validateAll();
        if(!res2){
            return false;
        }
        return true;
    },

    async validateFormFields(){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs["allSections"];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          let sp = f.specialValidation();
          if(sp != null){
            let res = await this[sp.method]();
            let n = f.rawFieldName;
            if(!res){
              allValid = false;
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
              continue;
            }
          }
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },

    async saveFormInfo(){
      let ok = false;
      try{
        this.beneficiary.address = this.address;
        this.beneficiary.beneficiary_percentage = this.beneficiary.beneficiary_percentage / 100;
        let beneficiary = this.packNested(this.beneficiary);
        // agregar informacion de metadata para logs
        this.injectAccountMetadataToPayload(beneficiary);
        if (this.newRecord) {
          // ejecutar guardado
          await axios.post(`/api/v1/userBeneficiaries/${this.UserId}/${this.role}/save`, beneficiary);
        }else{
          // ejecutar actualizacion
          await axios.put(`/api/v1/beneficiary/${this.beneficiaryId}/deepUpdate`, beneficiary);
        }
        ok = true;
      }
      catch(e){
        this.warn(e.response);
        ok = false;
        this.errorMssg = e.response.data ? e.response.data.error : null;
      }
      return ok;
    },

    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
    evaluateCondition(condition){
      if(condition != null && condition in this){
        return this[condition];
      }
    },

     /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'project'){
        return this.id;
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },

    processError(code){
      this.onError = true;
      this.errorCode = code;
    },

    /* validaciones especiales */
    validate_user_birthdate(){
      let years = this.calculateAgeFromDate(this.beneficiary.birth_date);
      return years >= 18;
    },
    async validate_beneficiary_percentage(){
      let currentPercentage = this.beneficiary.beneficiary_percentage/100;
      let totalPercentage = (this.totalPercentageAvailable+this.beneficiary.prev_beneficiary_percentage)-currentPercentage;
      let result = true;
      if(currentPercentage == 0 || currentPercentage > 1){
        result =  false;
      }
      else{
        // si es nuevo registro se valida que su porcentaje no sea mayor al disponible
        if (!this.beneficiary.id && currentPercentage > this.totalPercentageAvailable) {
          result =  false;
        }else{
          // si es una edicion de registro se valida que su porcentaje nuevo no sea mayor al disponible
          if (totalPercentage < 0) {
            result =  false;
          }
        }
      }
      return result;
    },

    getDataContainer(s){
      if (s == 82 || s == 84) {
        return this.beneficiary;
      }else if(s == 83){
        return this.address;
      }
    },
    verify_zip_code_beneficiary_address() {
      this.verifyZipCode(this.address, {
        zipCodeVariable: 'zip_code_beneficiary_address',
        statesArray: 'states_beneficiary_address',
        citiesArray: 'cities_beneficiary_address',
        neighborhoodsArray: 'neighborhoods_beneficiary_address'
      });
    },
    set_zip_code_values_beneficiary_address() {
      this.setZipCodeAddressValues(this.beneficiary.address_id, this.address, {
        zipCodeVariable: 'zip_code_beneficiary_address',
        statesArray: 'states_beneficiary_address',
        citiesArray: 'cities_beneficiary_address',
        neighborhoodsArray: 'neighborhoods_beneficiary_address'
      });
    }
  }
};
</script>

<style scoped>
  .alt-radio-text {
    margin-left: 10px;
    color: rgba(var(--vs-success), 1);
  }
</style>