<!-- =========================================================================================
    ACTUALIZAR LAS VARIABLES COMPUTADAS EN DONDE SEA NECESARIO PARA LLENAR LOS DATOS CORRESPONDIENTES DEL USUARIO
    ASI COMO TAMBIEN LA FECHA ACTUAL DE FIRMA DEL CONTRATO
========================================================================================== -->
<template>
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="alt-2"></div>
			<h1 class="big_title text-center">
				Contrato de Comisión Mercantil Inversionistas
			</h1>
			<br>

			<p style="text-align: center;"><strong>CARÁTULA DE COMISIÓN MERCANTIL</strong></p>
			<div class="parent">
				<div class="div4"><strong>NOMBRE DEL PRODUCTO: INVERSIÓN VERDE</strong></div>
				<div class="div5"><strong>TIPO DE PRODUCTO: Comisión Mercantil</strong></div>
				<div class="div1"><strong>IDENTIFICACIÓN DE LA PLATAFORMA</strong></div>
				<div class="div2"><strong>MEDIOS DE DEPÓSITO</strong></div>
				<div class="div3"> <strong>MEDIOS DE DISPOSICIÓN</strong> </div>
				<div class="div6">
					<ul class="list-disc">
						<li>
							Página de internet: <br> <a :href="$sanitizeUrl('https://www.redgirasol.com/')"
								target="_blank">www.redgirasol.com</a>
						</li>
					</ul>
				</div>
				<div class="div7">
					<ul class="list-disc">
						<li>
							Transferencias Electrónicas
						</li>
						<li>
							Tarjeta de débito
						</li>
						<li>
							Domiciliación bancaria
						</li>
					</ul>
				</div>
				<div class="div8">
					<ul class="list-disc">
						<li>
							Transferencias Electrónicas
						</li>
					</ul>
				</div>
				<div class="div9"><strong>COMISIONES RELEVANTES</strong></div>
				<div class="div10">
					<ul class="list-disc">
						<li>De administración por éxito: 10% del Rendimiento cobrado por el Comisionista</li>
						<li>De administración por procesamiento: 2% del valor de la Cesión de Derechos (únicamente en
							caso de quien cede la deuda)</li>
						<li>Por penalización por contracargo: MXN$500.00</li>
						<li>Por penalización por aclaración improcedente: MXN$500.00</li>
					</ul>
				</div>
				<div class="div11"></div>
				<div class="div12">
					<strong>ADVERTENCIAS</strong><br>
					"Los recursos de los Usuarios en las operaciones realizadas con Red Girasol, S.A.P.I. de C.V.,
					Institución de Financiamiento Colectivo no se encuentran garantizados por ninguna autoridad" <br>
					"Los rendimientos de esta inversión son estimados y no están asegurados"
				</div>
				<div class="div13"><strong>SEGUROS Y SERVICIOS ADICIONALES</strong> </div>
				<div class="div14">
					Seguro: No aplica <br>
					Servicio Adicional: No aplica
				</div>
				<div class="div15">
					Aseguradora: No aplica <br>
					Prestado por: No aplica
				</div>
				<div class="div16">
					Cláusula: No aplica <br>
					Cláusula: No aplica
				</div>
				<div class="div17">
					<strong>ESTADO DE OPERACIÓN</strong> Periodicidad: Mensual <br>
					Disponible: Página de internet <a :href="$sanitizeUrl('https://www.redgirasol.com/')"
						target="_blank">www.redgirasol.com</a>
				</div>
				<div class="div18">
					<strong>Aclaraciones y reclamaciones </strong> <br>
					Unidad Especializada de Atención a Usuarios: Jorge Armando Cárdenas Tijerina<br>
					Correo electrónico: une@redgirasol.com<br>
					Página de Internet: <a :href="$sanitizeUrl('https://www.redgirasol.com/aclaraciones')"
						target="_blank">www.redgirasol.com/aclaraciones</a>
				</div>
				<div class="div19">
					<strong>Registro de Contratos de Adhesión Núm: 15895-456-040921/01-02234-0824</strong> <br>
					Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros
					(CONDUSEF)<br>
					Teléfono: 01 800 999 8080 y 53400999. Página de Internet. www.gob.mx/condusef
				</div>
			</div>
			<div v-if="!onlyCoverPage" class="text-justify">
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center;"><br
						style="page-break-before:always; clear:both;"></p>
				<p style="margin-top:0pt; margin-bottom:10pt; text-align:left; line-height:6%; font-size:11pt;">
					<strong>&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>CONTRATO DE COMISI&Oacute;N
						MERCANTIL&nbsp;</strong>(en lo sucesivo el &ldquo;<u>Contrato</u>&rdquo;) que celebran por una
					parte {{userName}}{{personTypeText}}{{userLegalRepresentativeName}} (en lo sucesivo &quot;<u>Comitente</u>&quot;), y por la otra Red Girasol, S.A.P.I. de C.V.,
					Instituci&oacute;n de Financiamiento Colectivo, representada en este acto por Jorge Armando
					C&aacute;rdenas Tijerina (en lo sucesivo &quot;<u>Comisionista</u>&quot; y en conjunto con el
					Comitente como las &ldquo;<u>Partes</u>&rdquo; o individualmente cada uno como una
					&ldquo;<u>Parte</u>&rdquo;); mismo que sujetan al tenor de las siguientes Declaraciones y
					Cl&aacute;usulas.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>&nbsp;</strong></p>
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; page-break-after:avoid; font-size:11pt;">
					<strong>DECLARACIONES</strong>
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">1.<span
						style="width:27.75pt; display:inline-block;">&nbsp;</span>Declara el Comisionista a
					trav&eacute;s de su representante legal que:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					1.1.<strong><span style="width:19.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong>Es
					una
					sociedad constituida de conformidad con las leyes de los Estados Unidos Mexicanos
					(&ldquo;<u>M&eacute;xico</u>&rdquo;) seg&uacute;n consta en la escritura p&uacute;blica
					n&uacute;mero 49,903 de fecha 14 de abril de 2016 otorgada ante la fe del licenciado Eduardo Adolfo
					Manautou Ayala, titular de la Notar&iacute;a P&uacute;blica No. 123 con ejercicio en Monterrey,
					Nuevo Le&oacute;n y cuyo primer testimonio qued&oacute; inscrito en el registro p&uacute;blico de la
					ciudad de Monterrey, Nuevo Le&oacute;n bajo el folio mercantil electr&oacute;nico n&uacute;mero
					160394*1. Asimismo, declara que realiza operaciones de financiamiento colectivo en t&eacute;rminos
					de la Ley FinTech y que cuenta con los recursos y medios necesarios para mantener dicha
					autorizaci&oacute;n.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<ol start="2" type="1" class="awlist1" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Su
						representante cuenta con las facultades necesarias y suficientes para suscribir el presente
						Contrato, mismas que se encuentran vigentes y no le han sido revocadas o limitadas en forma
						alguna a la fecha de celebraci&oacute;n del presente contrato.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<ol start="3" type="1" class="awlist2" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Su
						objeto social consiste en, entre otros, recibir y publicar las solicitudes de operaciones de
						financiamiento colectivo de deuda de conformidad con el art&iacute;culo 16 fracci&oacute;n 1 de
						la Ley FinTech (&ldquo;<u>Operaciones</u>&rdquo;) de los Solicitantes y sus Proyectos a
						trav&eacute;s del Sitio o medio de comunicaci&oacute;n electr&oacute;nica o digital que utilice
						para realizar sus actividades.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<ol start="4" type="1" class="awlist3" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Que:
						(i) se abstendr&aacute; de privilegiar los intereses de alg&uacute;n Cliente sobre otro o actuar
						con conflictos de inter&eacute;s en la ejecuci&oacute;n de estos; (ii) mantendr&aacute;
						informados a los Clientes de los actos realizados en la ejecuci&oacute;n de los mandatos o
						comisiones de que se trate; y (iii) dar&aacute; a conocer de forma clara las contraprestaciones
						que, en su caso, se cobrar&iacute;an por la ejecuci&oacute;n del mandato. Para los efectos de
						esta declaraci&oacute;n, se entender&aacute; por Cliente tanto a los Inversionistas como a los
						Solicitantes en los t&eacute;rminos del art&iacute;culo 16 de la Ley FinTech.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<ol start="5" type="1" class="awlist4" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Que
						para organizarse y operar como instituci&oacute;n de financiamiento colectivo
						(&ldquo;<u>IFC</u>&rdquo;) obtuvo una autorizaci&oacute;n otorgada por la CNBV, previo acuerdo
						del Comit&eacute; Interinstitucional, en t&eacute;rminos del Cap&iacute;tulo I del T&iacute;tulo
						III de la Ley FinTech. Las IFC, adem&aacute;s de cumplir con las obligaciones establecidas en la
						Ley FinTech y en las disposiciones que de ella emanen, deber&aacute;n tomar medidas para evitar
						que se difunda informaci&oacute;n falsa o enga&ntilde;osa a trav&eacute;s de ellas.
						Adicionalmente, las ITF deber&aacute;n difundir la informaci&oacute;n que permita a sus Clientes
						identificar los riesgos de las Operaciones que celebren con o a trav&eacute;s de ellas, conforme
						a lo previsto en la Ley FinTech. <strong>Ni el Gobierno Federal ni las entidades de la
							administraci&oacute;n p&uacute;blica paraestatal podr&aacute;n responsabilizarse o
							garantizar los recursos del Comitente que sean utilizados en las Operaciones que celebren
							con las ITF o frente a otros, as&iacute; como tampoco asumir alguna responsabilidad por las
							obligaciones contra&iacute;das por las ITF o por el Comitente frente a otro, en virtud de
							las Operaciones que celebren</strong>. Las ITF deber&aacute;n se&ntilde;alar expresamente lo
						mencionado en este p&aacute;rrafo en sus respectivas p&aacute;ginas de internet, en los mensajes
						que muestren a trav&eacute;s de las aplicaciones inform&aacute;ticas o transmitan por medios de
						comunicaci&oacute;n electr&oacute;nica o digital que utilicen para el ofrecimiento y
						realizaci&oacute;n de sus Operaciones, as&iacute; como en la publicidad y los contratos que
						celebren con sus Clientes.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<ol start="6" type="1" class="awlist5" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Que
						ha obtenido una constancia electr&oacute;nica del Comitente a trav&eacute;s del Sitio (misma que
						forma parte integrante de este Contrato), en la que &eacute;ste manifiesta conocer los riesgos
						de su inversi&oacute;n previo a la celebraci&oacute;n del presente Contrato, de conformidad con
						lo establecido en el art&iacute;culo 43 de la CUITF.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<ol start="7" type="1" class="awlist6" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Que
						no asegura ning&uacute;n retorno o rendimiento sobre la inversi&oacute;n realizada por el
						Comitente ni garantiza el resultado o &eacute;xito de las inversiones.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:left; font-size:11pt;">&nbsp;
				</p>
				<ol start="8" type="1" class="awlist7" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Que
						ha verificado o verificar&aacute; los datos y autenticidad de los documentos obtenidos de manera
						digital por parte del Comitente.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:left; font-size:11pt;">&nbsp;
				</p>
				<ol start="9" type="1" class="awlist8" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Que
						su p&aacute;gina de internet se identifica bajo el nombre de dominio: <a
							href="http://www.redgirasol.com" style="text-decoration:none;"><u><span
									style="color:#0000ff;">www.redgirasol.com</span></u></a> (el
						&ldquo;<u>Sitio</u>&rdquo;).</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">2. <span
						style="width:25pt; display:inline-block;">&nbsp;</span>Declara el Comitente, por su propio
					derecho o por conducto de su representante que:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">2.1.
					<span style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Es una persona
					f&iacute;sica mayor de edad o una sociedad debidamente constituida conforme a sus leyes aplicables.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">2.2.
					<span style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Que su persona se
					encuentra en pleno uso y goce de sus facultades mentales, o, en su caso, su representante legal
					cuenta con las facultades necesarias para celebrar el presente contrato seg&uacute;n poder que en
					este acto se tiene a la vista.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.3<span style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Que la
					informaci&oacute;n y documentaci&oacute;n presentada al Comisionista es ver&iacute;dica, sabiendo de
					las consecuencias penales de presentar informaci&oacute;n falsa.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.4<span style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Es un usuario
					registrado del Sitio y reconoce y declara haber revisado, entendido y aceptado sin reserva alguna
					los t&eacute;rminos y condiciones, avisos legales y cualesquiera otras cl&aacute;usulas,
					declaraciones, derechos y/u obligaciones que contiene dicho Sitio.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.5<span style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Mantiene, a su
					nombre una cuenta bancaria que podr&aacute; ser revelada por el Comisionista a trav&eacute;s del
					Sitio.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.6<span style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Reconoce que
					RedGirasol actuar&aacute; &uacute;nica y exclusivamente como comisionista suyo, y por ende, el Monto
					y/o cualesquiera otras cantidades que sean prestadas bajo cualesquier modalidad aplicable a los
					Solicitantes, corre por cuenta exclusiva y riesgo del Comitente. Dichos recursos de ninguna forma y
					en ning&uacute;n momento ser&aacute;n propiedad de RedGirasol, y este &uacute;ltimo por
					ning&uacute;n motivo y bajo ninguna causa o circunstancia ser&aacute; responsable, obligado
					solidario, subsidiario o garante, fiador o aval de los Solicitantes, ni de forma alguna
					responder&aacute; por el pago que deber&aacute;n hacer terceros y/o el Comitente y/o los
					Solicitantes por los Servicios.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.7<span style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Est&aacute; de
					acuerdo en que el hecho de seleccionar a un Solicitantepor medio del Sitio, no significa que el
					cr&eacute;dito ser&aacute; otorgado inmediatamente, ya que se deber&aacute; esperar a que el
					Comisionista por medio del Sitio consiga suficientes Inversionistas para fondear la totalidad del
					Monto solicitado, o el Monto parcial que el Comisionista defina, por cada uno de los Solicitantes.
					Por otro lado, tambi&eacute;n declara que en caso de que no se re&uacute;na el Monto requerido para
					los Proyectos solicitados, el dinero aportado para los mismos se abonar&aacute; a su cuenta. En caso
					de que (i) el Monto del Solicitante no se hubiese obtenido o (ii) el Solicitante no hubiese firmado
					los Documentos del Cr&eacute;dito de su Proyecto, el Comisionista deber&aacute; reintegrar la
					totalidad invertida por el Comitente a su cuenta que tenga en el Sitio en un plazo no mayor a 48
					horas h&aacute;biles desde que el Proyecto no hubiese contenido los fondos o que el Solicitante no
					firme los Documentos del Cr&eacute;dito de su Proyecto, cualquiera que suceda primero.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.8<span style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Que reconoce
					que<strong>&nbsp;ni el Gobierno Federal ni las entidades de la administraci&oacute;n p&uacute;blica
						paraestatal podr&aacute;n responsabilizarse o garantizar los recursos del Comitente que sean
						utilizados en las Operaciones que celebren con las ITF o frente a otros, as&iacute; como tampoco
						asumir alguna responsabilidad por las obligaciones contra&iacute;das por las ITF o por el
						Comitente frente a otro, en virtud de las Operaciones que celebren</strong>.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.9<span style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Que ha emitido y
					firmado una constancia electr&oacute;nica a trav&eacute;s del Sitio (misma que forma parte
					integrante de este Contrato y la cual solo se recaba por &uacute;nica ocasi&oacute;n), en la que
					manifiesta conocer los riesgos de su inversi&oacute;n previo a la celebraci&oacute;n del presente
					Contrato, de conformidad con lo establecido en el art&iacute;culo 43 de la CUITF.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.10<span style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Que reconoce
					que el Comisionista no asegura ning&uacute;n retorno o rendimiento sobre la inversi&oacute;n
					realizada por el Comitente ni garantiza el resultado o &eacute;xito de las inversiones.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					2.11<span style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Que reconoce
					que las Operaciones que realizar&aacute; en el Sitio podr&aacute;n ser solamente en pesos, moneda
					nacional.</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">3.
					<span style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Declaran las Partes
					que:
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<ol type="1" class="awlist9" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Entienden
						y conocen la terminolog&iacute;a empleada en el presente Contrato, no obstante, la
						utilizaci&oacute;n de tecnicismos propios de la materia que aqu&iacute; se regula y que son
						empleados en la determinaci&oacute;n de los derechos y obligaciones que aqu&iacute; se pactan.,
						para lo cual existe un glosario en el Sitio con las disposiciones legales utilizadas en el
						presente Contrato.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<ol start="2" type="1" class="awlist10" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Se
						reconocen mutuamente la personalidad y, en su caso, representaci&oacute;n con la cual cada una
						de las Partes comparece a la celebraci&oacute;n del presente Contrato.</li>
				</ol>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<ol start="3" type="1" class="awlist11" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt;"><span
							style="width:19.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a
							name="_heading_h.gjdgxs"></a>Est&aacute;n de acuerdo en celebrar el presente Contrato,
						sujet&aacute;ndolo al tenor de las siguientes cl&aacute;usulas, ap&eacute;ndices, anexos y
						convenios accesorios que eventualmente se llegaren a celebrar entre las Partes incluyendo
						aquellos que se celebren con o sin la intervenci&oacute;n o comparecencia de terceros; una copia
						de los cuales se pondr&aacute;n a disposici&oacute;n del Comitente una vez sea celebrado el
						presente Contrato, en su cuenta en el Sitio.</li>
				</ol>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">En virtud de lo anterior, las Partes, de
					com&uacute;n acuerdo, se sujetan a las siguientes:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; page-break-after:avoid; font-size:11pt;">
					<strong>CL&Aacute;USULAS</strong>
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">&nbsp;</p>
				<ol type="1" class="awlist12" style="margin:0pt; padding-left:0pt;">
					<li style="margin-left:36pt; text-indent:-36pt; font-size:11pt; font-weight:bold;"><span
							style="width:30.5pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>Objeto
							del Contrato.&nbsp;</u><span style="font-weight:normal;">&nbsp;</span><span
							style="font-weight:normal;">Por virtud del presente Contrato, el Comitente otorga y confiere
							una comisi&oacute;n mercantil al Comisionista para (i) prestar y cobrar en su nombre y
							representaci&oacute;n los Servicios, incluyendo el otorgamiento del Cr&eacute;dito por el
							Monto especificado por el Comisionista, en nombre y representaci&oacute;n del Comitente,
							para el financiamiento del Proyecto por parte de los Solicitantes</span><span
							style="font-weight:normal; font-variant:small-caps;">&nbsp;</span><span
							style="font-weight:normal;">de manera no exclusiva dentro del Territorio y (ii) realizar en
							su nombre y representaci&oacute;n&nbsp;</span><span
							style="font-size:12pt; font-weight:normal;">,</span><span style="font-weight:normal;">&nbsp;con
							previo consentimiento expreso a trav&eacute;s del
							Sitio, aquellas actividades para facilitar la venta o adquisici&oacute;n de los derechos o
							t&iacute;tulos intercambiados que documenten los Cr&eacute;ditos, ya sea por medio de
							Permutas, Cesiones de Derechos de los Cr&eacute;ditos o cualquier otro instrumento legal, de
							acuerdo con los t&eacute;rminos y condiciones del Mercado Secundario publicados en el
							Sitio.&nbsp;</span></li>
				</ol>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Por este mismo medio, <span
						style="font-variant:small-caps;">E</span>l<span
						style="font-variant:small-caps;">&nbsp;</span>Comisionista acepta expresamente la
					comisi&oacute;n mercantil objeto del presente Contrato, oblig&aacute;ndose con el Comitente a
					desempe&ntilde;arla de conformidad con las disposiciones de este Contrato, sin exceder de las
					facultades conferidas en el mismo y sus accesorios.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><a
						name="_heading_h.30j0zll"></a><strong>1.1</strong><strong><span
							style="width:22.25pt; display:inline-block;">&nbsp;</span></strong><strong>Derechos y
						obligaciones del Comisionista bajo el presente Contrato.</strong> A continuaci&oacute;n, el
					Comisionista encontrar&aacute; un condensado de los derechos y obligaciones a su cargo bajo el
					presente Contrato, en la inteligencia que dichos derechos y obligaciones se encontrar&aacute;n
					sujetos a los t&eacute;rminos y condiciones previstos en los numerales correspondientes:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.1</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de promocionar, entregar y seleccionar los Proyectos&nbsp;</strong>conforme a las
					Cl&aacute;usulas 2 y 3.5.1 del presente Contrato;
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.2</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>&nbsp;Obligaci&oacute;n
						de celebrar el Cr&eacute;dito</strong>, mediante la celebraci&oacute;n de los actos necesarios
					incluyendo los Documentos del Cr&eacute;dito conforme a lo previsto en las Cl&aacute;usulas 3.3 y
					3.5 (incluyendo, sin limitaci&oacute;n, las Cl&aacute;usulas 3.5.2 y 3.5.3) del presente.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.3</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de pagar las aportaciones al Solicitante</strong>, en atenci&oacute;n a lo previsto en la
					Cl&aacute;usula 3.5.4 de este Contrato.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.4</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de poner a disposici&oacute;n del Comitente el Comprobante de Operaci&oacute;n, el Estado de
						Cuenta y el Estado de Operaci&oacute;n</strong>, y avisar de sus movimientos inusuales y
					modificaciones, conforme a lo establecido en las Cl&aacute;usulas 3.5.5 y 3.5.6 del presente.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.5</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de cobrar los pagos del Cr&eacute;dito y pagar los recursos a los Inversionistas</strong>, en
					virtud de lo establecido en las Cl&aacute;usulas 3.5.7, 3.5.8, 3.7 y 3.9 de este Contrato.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.6</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Derecho a percibir
						las comisiones</strong> previstas en la Cl&aacute;usula 5 del presente.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.7</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Derecho a terminar
						este Contrato</strong>, en seguimiento de lo establecido en la Cl&aacute;usula 6.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.8</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de cumplir con los impuestos a su cargo</strong>, seg&uacute;n se describe en la Cl&aacute;usula
					11.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.9</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de ejecutar el plan de continuidad de negocio en causa de contingencias operativas</strong>,
					cuando se satisfagan las condiciones previstas en la Cl&aacute;usula 17 del presente.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.10</strong><strong><span
							style="width:9.3pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaciones y
						derechos diversos</strong>, en t&eacute;rminos de las Cl&aacute;usulas 13 a 24 de este Contrato.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.11</strong> <strong>Obligaci&oacute;n de celebrar la Permuta o Permutas de los
						Cr&eacute;ditos,</strong> instruidas por el Comitente, de acuerdo con los t&eacute;rminos y
					condiciones del Mercado Secundario publicados en el Sitio.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.1.12</strong> <strong>Obligaci&oacute;n de celebrar la Cesiones de Derechos de los
						Cr&eacute;ditos,</strong> instruidas por el Comitente, &nbsp;de acuerdo con los t&eacute;rminos
					y condiciones del Mercado Secundario publicados en el Sitio.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>1.2</strong><strong><span
							style="width:22.25pt; display:inline-block;">&nbsp;</span></strong><strong>Derechos y
						obligaciones del Comitente bajo el presente Contrato.</strong> A continuaci&oacute;n, el
					Comitente encontrar&aacute; un condensado de los derechos y obligaciones a su cargo bajo el presente
					Contrato, en la inteligencia que dichos derechos y obligaciones se encontrar&aacute;n sujetos a los
					t&eacute;rminos y condiciones previstos en los numerales correspondientes:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.1</strong><span style="width:14.75pt; display:inline-block;">&nbsp;</span><strong>Derecho de
						abonar dinero a su
						cuenta en el Sitio</strong> conforme a las Cl&aacute;usulas 2 y 3.5 de este Contrato.
				</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.2</strong><strong><span
							style="width:14.75pt; display:inline-block;">&nbsp;</span></strong><strong>Derecho de
						reinvertir sus rendimientos e intereses</strong>, en seguimiento de lo previsto en la
					Cl&aacute;usula 3.6 del presente instrumento.
				</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.3</strong><strong><span
							style="width:14.75pt; display:inline-block;">&nbsp;</span></strong><strong>Derecho a
						percibir incentivos</strong>, conforme est&eacute;n disponibles en el Sitio de tiempo en tiempo,
					en t&eacute;rminos de lo establecido en la Cl&aacute;usula 3.8.
				</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.4</strong><strong><span
							style="width:14.75pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						a designar un beneficiario</strong>, en apego a lo previsto en la Cl&aacute;usula 4.
				</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.5</strong><strong><span
							style="width:14.75pt; display:inline-block;">&nbsp;</span></strong><strong>Derecho a retirar
						los fondos depositados sin cargo o restricci&oacute;n alguna</strong>, en t&eacute;rminos de la
					Cl&aacute;usula 5 de este Contrato.
				</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.6</strong><strong><span
							style="width:14.75pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de pagar las comisiones aplicables</strong> en beneficio del Comisionista, de conformidad con la
					Cl&aacute;usula 5 del presente.
				</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.7</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Derecho a terminar
						este Contrato</strong>, en seguimiento a lo establecido en la Cl&aacute;usula 6.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;"><span
						style="width:0.8pt; display:inline-block;">&nbsp;</span></p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.8</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Derecho de acudir
						ante la UNE y la CONDUSEF</strong>, para efectos de la prestaci&oacute;n de servicios
					financieros por parte de RedGirasol, en apego a lo establecido en la Cl&aacute;usula 12 del presente
					Contrato.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.9</strong><strong><span
							style="width:14.8pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de no competir con RedGirasol</strong>, bajo los t&eacute;rminos de la Cl&aacute;usula 10.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.10</strong><strong><span
							style="width:9.3pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaci&oacute;n
						de cumplir con los impuestos a su cargo</strong>, seg&uacute;n se describe en la Cl&aacute;usula
					11.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">
					<strong>1.2.11</strong><strong><span
							style="width:9.3pt; display:inline-block;">&nbsp;</span></strong><strong>Obligaciones y
						derechos diversos</strong>, en t&eacute;rminos de la Cl&aacute;usulas 13 a 24 de este Contrato.
				</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;"><strong>1.2.12
						Derecho&nbsp;</strong>a instruir al Comitente respecto las Permutas, de conformidad con los
					t&eacute;rminos y condiciones del Mercado Secundario<strong>.</strong></p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.2pt; margin-bottom:0pt; font-size:11pt;"><strong>1.2.13
						Derecho&nbsp;</strong>a instruir al Comitente respecto las Cesiones de Derechos, de conformidad
					con los t&eacute;rminos y condiciones del Mercado Secundario<strong>.</strong></p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><span
						style="width:36pt; display:inline-block;">&nbsp;</span></p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>2</strong><strong><span
							style="width:30.5pt; display:inline-block;">&nbsp;</span></strong><strong><u>Desempe&ntilde;o
							de la comisi&oacute;n.</u></strong> El Comisionista, para el desempe&ntilde;o de sus
					obligaciones, deber&aacute; obrar con buena fe y realizar&aacute; sus mejores esfuerzos para el
					cumplimiento adecuado de la comisi&oacute;n aqu&iacute; encomendada. El Comisionista deber&aacute;
					hacer su mejor esfuerzo por conocer en todo momento las mejores pr&aacute;cticas de promoci&oacute;n
					de los Proyectos en el Sitio.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">El Comitente reconoce que el hecho de
					seleccionar a un Proyecto de los Solicitantes registrados en el Sitio no significa que el Proyecto
					ser&aacute; fondeado inmediatamente, ya que deber&aacute; esperar a que el Comisionista consiga
					suficientes fondos para cubrir la totalidad del Monto solicitado y, en su caso, firme los Documentos
					del Cr&eacute;dito.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Como medio de disposici&oacute;n, el
					Comitente podr&aacute; abonar dinero a su cuenta en el Sitio mediante:</p>
				<p style="margin-top:0pt; margin-left:35.45pt; margin-bottom:0pt; text-indent:-35.45pt; font-size:11pt;">
					(i)<span
						style="width:25.07pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Transferencia
					bancaria SPEI a la cuenta se&ntilde;alada en el Sitio; y/o</p>
				<p style="margin-top:0pt; margin-left:35.45pt; margin-bottom:0pt; text-indent:-35.45pt; font-size:11pt;">
					(ii)<span
						style="width:22.01pt; font:7pt ; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Bajo
					consentimiento expreso, domiciliaci&oacute;n bancaria de acuerdo a las instrucciones
					se&ntilde;aladas en el Sitio..</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>3</strong><strong><span
							style="width:30.5pt; display:inline-block;">&nbsp;</span></strong><strong><u>Actividades que
							comprende la comisi&oacute;n.</u></strong> Durante la vigencia de este Contrato, el
					Comisionista estar&aacute; facultado para desarrollar las siguientes actividades con el fin de
					promover los Proyectos con los Solicitantes y/o el Comitente:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>3.1</strong><strong><span
							style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Promoci&oacute;n.</strong>
					El Comisionista estar&aacute; facultado para promocionar, de la manera m&aacute;s amplia y a su
					discreci&oacute;n, los Proyectos de una manera comercialmente razonable, pudiendo transmitir
					material promocional e informaci&oacute;n diversa a los Solicitantes y/o Inversionistas seg&uacute;n
					sea necesario para cumplir el objeto de este Contrato. La publicidad y promoci&oacute;n podr&aacute;
					ser efectuada mediante todos aquellos medios que el Comisionista considere adecuados para el
					correcto desarrollo de las actividades de promoci&oacute;n.
				</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-36pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.2</strong><strong><span
							style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Entrega
						de informaci&oacute;n.</strong> El Comisionista estar&aacute; facultado para entregar toda
					informaci&oacute;n relacionada a los Proyectos.
				</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-36pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.3</strong><strong><span
							style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Medio
						de venta.</strong> El Comisionista a trav&eacute;s de su Sitio celebrar&aacute; los siguientes
					actos jur&iacute;dicos para la formalizaci&oacute;n del Proyecto, sin limitar a: (i) este contrato
					de comisi&oacute;n mercantil y (ii) los Documentos del Cr&eacute;dito.
				</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-36pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.4</strong><strong><span
							style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Aportaci&oacute;n
						de recursos.&nbsp;</strong>El Comitente podr&aacute; aportar recursos a su cuenta en el Sitio a
					trav&eacute;s de (i) transferencia electr&oacute;nica a la cuenta se&ntilde;alada en el Sitio o (ii)
					bajo consentimiento expreso, domiciliaci&oacute;n bancaria de acuerdo a las instrucciones
					se&ntilde;aladas en el Sitio. En caso de que el Comitente sea un Usuario Inactivo, el Comisionista
					deber&aacute; regresar dicho monto al Comitente sin costo para &eacute;ste.
				</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-36pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5</strong><strong><span
							style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Otorgamiento
						del Cr&eacute;dito.&nbsp;</strong>El Comisionista deber&aacute; llevar a cabo las siguientes
					actividades relacionadas a los Servicios:
				</p>
				<p
					style="margin-top:9pt; margin-left:70.55pt; margin-bottom:9pt; text-indent:-35.25pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5.1</strong><strong><span
							style="width:13.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>B&uacute;squeda
						de Solicitantes.</strong> El Comisionista deber&aacute; realizar las actividades descritas en la
					metodolog&iacute;a de evaluaci&oacute;n, selecci&oacute;n y calificaci&oacute;n de Solicitantes y
					Proyectos que estar&aacute; publicado en el Sitio. Cuando no se hubiere obtenido el Monto requerido
					para efectos del Cr&eacute;dito, o bien, una vez concluido el plazo de financiamiento colectivo y
					obtenido el Monto para el Cr&eacute;dito y el Solicitante no hubiere confirmado el Proyecto o bien
					celebrado los Documentos del Cr&eacute;dito, las cantidades depositadas por el Comitente
					ser&aacute;n reintegradas dentro del t&eacute;rmino de 48 (cuarenta y ocho) horas siguientes a dicha
					circunstancia.
				</p>
				<p
					style="margin-top:9pt; margin-left:70.55pt; margin-bottom:9pt; text-indent:-35.25pt; widows:0; orphans:0; font-size:11pt;">
					<strong>Criterios de selecci&oacute;n de solicitantes y proyectos-</strong> Donde los puede
					encontrar el inversionista
				</p>
				<p
					style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; text-indent:-34.6pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5.2</strong><strong><span
							style="width:12.6pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Contrato
						de Cr&eacute;dito.</strong> El Comisionista podr&aacute; celebrar los Documentos del
					Cr&eacute;dito aplicables en nombre y representaci&oacute;n del Comitente con el Solicitante
					correspondiente al Proyecto que el Comitente seleccione a trav&eacute;s del Sitio. El Comisionista
					deber&aacute; informar al Comitente sobre cualquier modificaci&oacute;n a los Documentos del
					Cr&eacute;dito a trav&eacute;s del Sitio.
				</p>
				<p
					style="margin-top:9pt; margin-left:70.55pt; margin-bottom:9pt; text-indent:-35.25pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5.3</strong><strong><span
							style="width:13.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Registro
						de garant&iacute;a.</strong> El Comisionista podr&aacute; dar de alta la garant&iacute;a
					prevista en los Documentos del Cr&eacute;dito en el portal que la Secretar&iacute;a de
					Econom&iacute;a establece para el registro de garant&iacute;as mobiliarias (RUG) o en el Registro
					P&uacute;blico de Propiedad correspondiente.
				</p>
				<p
					style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; text-indent:-34.6pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5.4</strong><strong><span
							style="width:12.6pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Pago.</strong>
					En caso de aplicar en alg&uacute;n Proyecto, el Comisionista podr&aacute; realizar el pago en nombre
					del Solicitante al proveedor seleccionado por el Solicitante con la aportaci&oacute;n realizada por
					los Inversionistas una vez que los Documentos del Cr&eacute;dito hayan sido firmados.
				</p>
				<p
					style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; text-indent:-34.6pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5.5</strong><span
						style="width:12.6pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>Comprobante de
						Operaci&oacute;n</strong>. El Comisionista deber&aacute; poner a disposici&oacute;n del
					Comitente comprobantes de operaci&oacute;n a trav&eacute;s de los Medios Oficiales con las
					siguientes caracter&iacute;sticas: (i) denominaci&oacute;n social y logotipo de RedGirasol; (ii)
					medio de disposici&oacute;n, (iii) el folio de la operaci&oacute;n, (iv) monto, fecha y hora, (v)
					tipo de operaci&oacute;n, (vi) datos de identificaci&oacute;n de la cuenta y (vii) concepto y monto
					de las comisiones cobradas.
				</p>
				<p
					style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; text-indent:-34.6pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5.6</strong><span
						style="width:12.6pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>Estado de
						Cuenta o Estado de Operaci&oacute;n</strong>. El Comisionista deber&aacute; poner a
					disposici&oacute;n del Comitente un estado de cuenta a trav&eacute;s del Sitio con las siguientes
					caracter&iacute;sticas: (i) denominaci&oacute;n social, domicilio, n&uacute;mero de tel&eacute;fono
					y logotipo y Sitio de RedGirasol; (ii) nombre del usuario, n&uacute;mero de cuenta, (iii) periodo
					que corresponda, (iv) saldo inicial y final de cada proyecto invertido, (v) movimientos de cargo o
					abono (incluyendo descripci&oacute;n), (vi) un desglosa de las comisiones cobradas y sus detalles,
					(viii) los cargos objetados, (ix) el aviso de la UNE y que, en caso de no tener respuesta de la UNE,
					la notificaci&oacute;n de que puede acudir ante la CONDUSEF, (x) impuestos retenidos (en su caso) y
					(xi) leyendas legales. Adem&aacute;s, deber&aacute;n contener: (i) el saldo inicial y el del final
					del periodo del total de las inversiones realizadas, as&iacute; como los saldos inicial y final de
					cada Proyecto; (ii) el monto total invertido y el saldo disponible para retiro a la fecha final del
					periodo; (iii) la siguiente indicaci&oacute;n &ldquo;Los recursos de los Usuarios en las operaciones
					realizadas con Red Girasol, S.A.P.I. de C.V., Instituci&oacute;n de Financiamiento Colectivo no se
					encuentran garantizados por ninguna autoridad&rdquo;; (iv) un recuadro, que indique lo siguiente:
					(a) el comportamiento de pago de cada Solicitante, se&ntilde;alando si se encuentran al corriente en
					el pago de sus obligaciones o en mora; (b) en caso de alguno de los Solicitantes se encuentre en
					mora, deber&aacute;n se&ntilde;alar el n&uacute;mero de d&iacute;as del atraso en el pago, y (c) en
					caso de que se realicen acciones de cobranza deber&aacute;n indicar el estatus en que se encuentre
					la misma, (v) la informaci&oacute;n acerca del comportamiento de pago de cada Solicitante, de su
					desempe&ntilde;o y cualquier otra que sea relevante para el Comitente; (vi) el monto y la fecha en
					que se realiz&oacute; cada inversi&oacute;n; (vii) moneda en que se denomina la inversi&oacute;n;
					(viii) datos de identificaci&oacute;n del Proyecto donde se invirti&oacute;, tales como el
					n&uacute;mero o nombre; (ix) la tasa aplicable en el periodo expresada en t&eacute;rminos anuales
					simples y en porcentaje, as&iacute; como el monto de los intereses o rendimiento obtenidos, y (x) el
					plazo para el vencimiento de la inversi&oacute;n realizada por el Comitente. En caso de que se
					presente un atraso en el pago de los rendimientos de la inversi&oacute;n realizada deber&aacute;
					se&ntilde;alarse la raz&oacute;n del atraso, as&iacute; como las acciones de cobranza realizadas por
					el Comisionista. Adem&aacute;s, en caso de que exista recuperaci&oacute;n del monto invertido
					deber&aacute; se&ntilde;alarse el monto recuperado y el monto de las comisiones cobradas por dichas
					gestiones de cobro.
				</p>
				<p
					style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; text-indent:-34.6pt; widows:0; orphans:0; font-size:11pt;">
					<a name="_heading_h.1fob9te"></a><span
						style="width:34.6pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Para efectos de
					claridad, en caso de actualizarse alguno de los datos que contenga el estado de cuenta del
					Comitente, o bien, se realicen movimientos inusuales en su respectiva cuenta en el Sitio, el
					Comisionista deber&aacute; comunicar la existencia de dichas circunstancias al Comitente mediante su
					cuenta en el Sitio y/o a trav&eacute;s del correo electr&oacute;nico registrado por el Comitente en
					el Sitio.
				</p>
				<p
					style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; text-indent:-34.6pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5.7</strong><strong><span
							style="width:12.6pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Cobro
						y cobranza extrajudicial o judicial y uso de los Garant&iacute;as recuperadas.</strong> El
					Comisionista realizar&aacute; sus mejores esfuerzos para cobrar al Solicitante seleccionado y abonar
					la parte respectiva al Comitente a una cuenta que el Comitente mantega abierta en Entidades
					Financieras, en t&eacute;rminos del art&iacute;culo 45 de la Ley FinTech. El Comisionista
					deber&aacute; entregar al Comitente los pagos provenientes de los pagos de los Solicitantes a
					m&aacute;s tardar 30 d&iacute;as naturales posteriores al pago realizado por cada Solicitante. Sin
					embargo, el Comisionista bajo instrucci&oacute;n expresa del Comitente por solicitud independiente a
					trav&eacute;s los factores de autenticaci&oacute;n al presente Contrato y a trav&eacute;s del Sitio,
					podr&aacute; autorizar al Comisionista para que mantenga en su resguardo los recursos. En caso de
					estar en la necesidad de realizar una cobranza extrajudicial o judicial debido al incumplimiento del
					Solicitante del Proyecto, a la sola discreci&oacute;n del Comisionista, el Comisionista
					deber&aacute; informar al Comitente a trav&eacute;s del Sitio o en el Estado de Cuenta dentro de los
					10 d&iacute;as h&aacute;biles siguientes a que se inicie la cobranza extrajudicial o judicial: (i)
					las acciones que llevar&aacute; a cabo, por s&iacute; o a trav&eacute;s de terceros, para realizar
					la cobranza extrajudicial, as&iacute; como los alcances y objetivos que se pretenden con dicha
					gesti&oacute;n, incluyendo la resoluci&oacute;n de controversias por la v&iacute;a conciliatoria o
					de arbitraje y (ii) los t&eacute;rminos y condiciones en los que podr&aacute; pactar con el
					Solicitante del Proyecto las modificaciones a las condiciones originales para reestructurar o
					renovar los Cr&eacute;ditos, ceder el Cr&eacute;dito a un nuevo Solicitante, hacer quitas o recibir
					en pago bienes, realizar operaciones de factoraje, venta o cesi&oacute;n de derechos de cobro que
					tenga el Comitente, durante la recuperaci&oacute;n extrajudicial, as&iacute; como informar aquellos
					para considerar un Cr&eacute;dito como incobrable. Para tal efecto, el Comisionista podr&aacute;
					realizar por s&iacute; mismo o por terceros acciones tendientes al cobro de la parte insoluta del
					Cr&eacute;dito otorgado por el Comitente. Los pagos de honorarios, gastos de las gestiones de
					cobranza y otras actividades accesorias de los Cr&eacute;ditos morosos realizadas por el
					Comisionista o por cualquier tercero seleccionado por el Comisionista se deducir&aacute;n de
					cualquier monto que sea recuperado. El Comisionista cubrir&aacute; los costos durante el proceso de
					cobranza extrajudicial o judicial, mismo que ser&aacute; descontado del monto recuperado. Lo
					anterior en el claro entendido que los pagos de honorarios, gastos de cobranza y otras actividades
					accesorias por ning&uacute;n motivo podr&aacute;n exceder el monto que se est&aacute; intentando
					recuperar.<span style="font-family:Verdana; font-size:8pt;">&nbsp;</span>
				</p>
				<p style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; widows:0; orphans:0; font-size:11pt;">
					En caso de que las Garant&iacute;as sean recuperadas por la v&iacute;a judicial o mediante convenio
					con el Solicitante, el Comisionista deber&aacute; a su total discreci&oacute;n, una vez cubiertos
					los gastos mencionados en el p&aacute;rrafo anterior: (i) vender las Garant&iacute;as y distribuir
					el remanente entre los Inversionistas o (ii) administrar las Garant&iacute;as con terceros con el
					fin de recuperar en la medida de lo posible el Monto invertido por el Comitente. El Comitente en
					este acto autoriza expresamente al Comisionista a realizar la venta de las Garant&iacute;as a un
					valor residual una vez que el Monto sea reembolsado.</p>
				<p style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; widows:0; orphans:0; font-size:11pt;">
					En caso de que el Comisionista y Comitente sean inversionistas en un Proyecto, el Comisionista no
					podr&aacute; cobrar su pago mensual hasta en tanto el Comitente reciba su pago mensual de dicho
					Proyecto.</p>
				<p
					style="margin-top:9pt; margin-left:70.6pt; margin-bottom:9pt; text-indent:-35.35pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.5.8</strong><strong><span
							style="width:13.35pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Plazo
						para el pago de recursos a que tengan derecho los inversionistas.</strong> Toda vez que el
					Solicitante realice el pago de cada pago conforme al Cr&eacute;dito, el Comisionista
					percibir&aacute; los recursos a los que tenga derecho dentro de las 24 (veinticuatro) horas
					h&aacute;biles siguientes a las que el Solicitante haga el pago correspondiente.
				</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-36pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.6</strong><strong><span
							style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Inversi&oacute;n
						autom&aacute;tica.&nbsp;</strong>El Comisionista, bajo instrucci&oacute;n expresa del Comitente
					por solicitud independiente al presente Contrato a trav&eacute;s del Sitio, y mediante
					consentimiento expreso otorgado a trav&eacute;s del Sitio, podr&aacute; invertir
					autom&aacute;ticamente los recursos que el Comitente mantenga en el Sitio en nuevos Proyectos, bajo
					los t&eacute;rminos y condiciones de la Inversi&oacute;n Autom&aacute;tica que el Comitente
					se&ntilde;ale en el Sitio. El Comitente podr&aacute; revocar esta opci&oacute;n, bastando para tal
					efecto la simple solicitud a trav&eacute;s del Sitio.
				</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-0.7pt; widows:0; orphans:0; font-size:11pt;">
					El Comisionista deber&aacute; informar en el Sitio, al Comitente, los Proyectos en los cuales se
					realiz&oacute; dicha inversi&oacute;n, incluyendo los nuevos compromisos de inversi&oacute;n y la
					inversi&oacute;n efectiva, los montos, el Plazo de Solicitud de Financiamiento Colectivo y
					dem&aacute;s informaci&oacute;n relacionada.</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-36pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.7</strong><strong><span
							style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Dep&oacute;sito
						y disposici&oacute;n.&nbsp;</strong>El Comisionista deber&aacute; depositar en la cuenta que
					para tal efecto designe el Comitente en el Sitio, la cantidad que indique &eacute;ste de acuerdo a
					su saldo disponible, en un plazo no mayor a 72 (setenta y dos) horas h&aacute;biles despu&eacute;s
					de la solicitud realizada en el Sitio. El Comitente tendr&aacute; derecho a solicitar un retiro por
					d&iacute;a sin costo (el &ldquo;Retiro Ordinario&rdquo;). El Comisionista solo podr&aacute; recibir
					recursos del Comitente que provengan directamente de cuentas de dep&oacute;sito de dinero abiertas
					en una entidad financiera autorizada para recibir dichos dep&oacute;sitos conforme a la normatividad
					que le resulte aplicable. Asimismo, el Comisionista entregar&aacute; los recursos al Comitente
					mediante abonos o transferencias a las respectivas cuentas que el Comitente mantenga abiertas en
					entidades financieras y que designe para tal efecto en el Sitio. En caso de que el Comitente sea un
					Usuario Inactivo, el Comisionista deber&aacute; regresar el monto disponible al Comitente sin costo
					para &eacute;ste.
				</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-36pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.8</strong><span
						style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>Incentivos</strong>.
					El Comitente tendr&aacute; acceso a ciertos incentivos que estar&aacute;n disponibles de tiempo en
					tiempo en el Sitio.
				</p>
				<p
					style="margin-top:9pt; margin-left:36pt; margin-bottom:9pt; text-indent:-36pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.9</strong><strong><span
							style="width:22.25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Metodolog&iacute;a
						para el c&aacute;lculo de rendimientos.</strong> Para efectos del c&aacute;lculo del rendimiento
					de cada Proyecto, el Comitente reconoce que la metodolog&iacute;a es la siguiente: tasa efectiva de
					intereses del Proyecto multiplicado por el saldo insoluto del Proyecto.
				</p>
				<p
					style="margin-top:9pt; margin-left:35pt; margin-bottom:9pt; text-indent:-35pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.10</strong><span
						style="width:15.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>Sanas
						pr&aacute;cticas.&nbsp;</strong>El Comisionista se obliga a cumplir con las sanas
					pr&aacute;cticas y usos relativos al ofrecimiento y comercializaci&oacute;n de las Operaciones y
					servicios financieros aplicables a las instituciones de tecnolog&iacute;a financiera.
				</p>
				<p
					style="margin-top:9pt; margin-left:35.45pt; margin-bottom:9pt; text-indent:-35.45pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.11</strong><strong><span
							style="width:16.2pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Permutas
						y Cesiones de Derechos.</strong><strong>&nbsp;&nbsp;</strong>El Comitente, bajo
					instrucci&oacute;n expresa del Comitente por solicitud independiente al presente Contrato a
					trav&eacute;s del Sitio y de acuerdo a los t&eacute;rminos y condiciones del Mercado Secundario
					establecidos en el Sitio, tendr&aacute; acceso en el Sitio para instruir al Comisionista por
					solicitud independiente a trav&eacute;s del Sitio, respecto a (i) la celebraci&oacute;n de Permutas
					y (ii) la celebraci&oacute;n de Cesiones de Derechos. <a name="bookmark_id.2et92p0"></a><a
						name="bookmark_id.3znysh7"></a>
				</p>
				<p
					style="margin-top:9pt; margin-left:35pt; margin-bottom:9pt; text-indent:-35pt; widows:0; orphans:0; font-size:11pt;">
					<strong>3.12</strong><strong><span
							style="width:15.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Cesiones
						de Derecho.</strong> El Comitente, de conformidad con los t&eacute;rminos y condiciones del
					Mercado Secundario establecidos en el Sitio, tendr&aacute; acceso en el Sitio para ofrecer sus
					Cr&eacute;ditos a trav&eacute;s de una Cesi&oacute;n de Derechos. En caso de que sea efectivamente
					cedido a otro Inversionista a trav&eacute;s de una Cesi&oacute;n de Derechos, el Comisionista
					recibir&aacute; la comisi&oacute;n referida en la Cl&aacute;usula 5 de este Contrato. El
					Comisionista no garantiza que los Cr&eacute;ditos logren cederse a otro Inversionista.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>4</strong><strong><span
							style="width:30.5pt; display:inline-block;">&nbsp;</span></strong><strong><u>Actividades
							excluidas de la comisi&oacute;n</u></strong><strong>.</strong><strong><span
							style="width:6.74pt; display:inline-block;">&nbsp;</span></strong> Se encuentra expresamente
					excluido dentro de las actividades comprendidas en la comisi&oacute;n aqu&iacute; conferida la
					selecci&oacute;n del Proyecto por parte del Comisionista (incluyendo la tasa de inter&eacute;s
					esperada, fecha de pago de cada Proyecto, descripci&oacute;n detallada, objetos o Proyectos que
					podr&aacute;n ser consultados en el Sitio), siendo que esa decisi&oacute;n deber&aacute; ser tomada
					bajo responsabilidad &uacute;nica del Comitente, con las excepciones previstas en este Contrato
					(incluyendo pero sin limitar al Mercado Secundario y la Inversi&oacute;n Autom&aacute;tica) y sin
					necesidad de ratificaci&oacute;n por parte del Comitente.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">En caso de que el Proyecto no hubiere
					obtenido el Monto para ejecutarse, la cantidad invertida por el Comitente ser&aacute; reembolsada a
					su cuenta del Sitio en un plazo no mayor a 48 horas h&aacute;biles para que el Comitente pueda
					seleccionar otro Proyecto o bien, solicitar la devoluci&oacute;n de su inversi&oacute;n de
					conformidad con la Secci&oacute;n 3.7 anterior.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Adem&aacute;s, el Comitente deber&aacute;
					designar un beneficiario, incluyendo sus datos de identificaci&oacute;n y localizaci&oacute;n en su
					perfil del Sitio, mismo que podr&aacute; ser modificado por el Comitente de conformidad con el
					procedimiento establecido en el mismo Sitio.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>5</strong><strong><span
							style="width:30.5pt; display:inline-block;">&nbsp;</span></strong><strong><u>Comisiones.</u></strong>
					Las Partes acuerdan que el<span style="font-variant:small-caps;">&nbsp;</span>Comisionista
					percibir&aacute; las siguientes comisiones:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<table cellspacing="0" cellpadding="0"
					style="width:468.35pt; margin-left:5.65pt; border:0.75pt solid #000000; border-collapse:collapse;">
					<tbody>
						<tr>
							<td
								style="width:135.95pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">
									<strong><span style="font-family:Arial;">COMISI&Oacute;N</span></strong>
								</p>
							</td>
							<td
								style="width:149.6pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">
									<strong><span style="font-family:Arial;">PERIODICIDAD DE COBRO</span></strong>
								</p>
							</td>
							<td
								style="width:147.55pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">
									<strong><span style="font-family:Arial;">MONTO O PORCENTAJE</span></strong>
								</p>
							</td>
						</tr>
						<tr>
							<td
								style="width:135.95pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">Por administraci&oacute;n por &eacute;xito</span></p>
							</td>
							<td
								style="width:149.6pt; border-style:solid; border-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">Por evento</span></p>
							</td>
							<td
								style="width:147.55pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">10% del Rendimiento</span></p>
							</td>
						</tr>
						<tr>
							<td
								style="width:135.95pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">Por administraci&oacute;n por procesamiento</span>
								</p>
							</td>
							<td
								style="width:149.6pt; border-style:solid; border-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">Por evento</span></p>
							</td>
							<td
								style="width:147.55pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">2% del valor de la Cesi&oacute;n de Derechos
										(&uacute;nicamente quien cede la deuda)</span></p>
							</td>
						</tr>
						<tr>
							<td
								style="width:135.95pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">Por penalizaci&oacute;n por contracargo</span></p>
							</td>
							<td
								style="width:149.6pt; border-style:solid; border-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">Por evento</span></p>
							</td>
							<td
								style="width:147.55pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">MXN$500.00</span></p>
							</td>
						</tr>
						<tr>
							<td
								style="width:135.95pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">Por penalizaci&oacute;n por aclaraci&oacute;n
										improcedente</span></p>
							</td>
							<td
								style="width:149.6pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">Por evento</span></p>
							</td>
							<td
								style="width:147.55pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.38pt; padding-left:5.38pt; vertical-align:top;">
								<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:11pt;"><span
										style="font-family:Arial;">MXN$500.00</span></p>
							</td>
						</tr>
					</tbody>
				</table>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; font-size:11pt;">
					<strong>Comisi&oacute;n por administraci&oacute;n por &eacute;xito:&nbsp;</strong>Por
					cada<strong>&nbsp;</strong>cada cobro que el Comisionista realice en nombre del Comitente, el
					Comisionista recibir&aacute; el equivalente al 10% (diez por ciento) sobre el Rendimiento,
					m&aacute;s el correspondiente Impuesto al Valor Agregado
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; font-size:11pt;">
					<strong>&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; font-size:11pt;">
					<strong>Comisi&oacute;n por administraci&oacute;n por procesamiento:&nbsp;</strong>En caso
					de<strong>&nbsp;</strong>la salida total o parcial mediante Cesi&oacute;n de Derechos de sus
					Cr&eacute;ditos a trav&eacute;s del Mercado Secundario seg&uacute;n lo establecido en la
					secci&oacute;n 3.12, el equivalente a 2% (dos por ciento) del valor de la transacci&oacute;n,
					m&aacute;s el Impuesto al Valor Agregado.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; font-size:11pt;">
					<strong>Comisi&oacute;n por penalizaci&oacute;n por contracargo.</strong> En caso de que el
					Comitente genere un contracargo por cualquiera de las formas de pago y &eacute;ste sea improcedente,
					corresponder&aacute; al Comisionista MXN$500.00 (quinientos pesos 00/100 Moneda Nacional) m&aacute;s
					el Impuesto al Valor Agregado. El Comisionista se reserva el derecho de aplicar a su
					discreci&oacute;n esta comisi&oacute;n, as&iacute; como la posibilidad de aplicar un descuento.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; font-size:11pt;">
					<strong>Comisi&oacute;n por penalizaci&oacute;n por aclaraci&oacute;n improcedente.</strong> En caso
					de que el Comitente solicite una reclamaci&oacute;n a trav&eacute;s de la UNE y la misma resulte
					improcedente, corresponder&aacute; al Comisionista MXN$500.00 (quinientos pesos 00/100 Moneda
					Nacional) m&aacute;s el Impuesto al Valor Agregado. El Comisionista se reserva el derecho de aplicar
					a su discreci&oacute;n esta comisi&oacute;n, as&iacute; como la posibilidad de aplicar un descuento.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Las comisiones ser&aacute;n cobradas al
					Comitente mediante descuento realizado al monto pagado por cada cobro realizado en nombre del
					Comitente, mismo que ser&aacute; desglosado en su cuenta respectiva en el Sitio.<span
						style="font-family:Verdana; font-size:8pt;">&nbsp;</span>De igual forma, mientras el
					Comisionista mantenga cantidades propiedad del Comitente bajo su disposici&oacute;n, el Comisionista
					podr&aacute; emplear dichas cantidades en operaciones de reporto solo con Valores (seg&uacute;n
					dicho t&eacute;rmino es definido en la Ley FinTech) emitidos por el Gobierno Federal o el Banco de
					M&eacute;xico celebradas con instituciones de Cr&eacute;dito a plazo de un d&iacute;a renovable
					conforme a lo que convengan al efecto, o bien afectarse en un fideicomiso de administraci&oacute;n
					constituido para tal fin, que &uacute;nicamente lleve a cabo las referidas operaciones de reporto.
					El Comitente acuerda y acepta que las utilidades generadas corresponder&aacute;n exclusivamente al
					Comisionista.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Lo anterior, en el entendido,
					adem&aacute;s, que el Comitente podr&aacute; retirar las cantidades depositadas para efectos del
					Proyecto, previo al financiamiento del Monto, sin cargo o restricci&oacute;n alguna. Respecto de los
					retiros de rendimientos generados por los Proyectos,&nbsp; el Comitente podr&aacute; retirar sus
					recursos depositados para efectos del Proyecto, mismo que ser&aacute; entregado en la cuenta
					se&ntilde;alada por el Comitente en el Sitio a m&aacute;s tardar dentro de las 48 (cuarenta y ocho)
					horas h&aacute;biles siguientes a la solicitud de retiro.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Esta contraprestaci&oacute;n no cubre y es
					excluyente de los costos de filtraci&oacute;n de Proyectos y de an&aacute;lisis de riesgo de la
					solicitud de Cr&eacute;dito por parte del Solicitante, ya que dichos costos se prestan sin costo
					alguno para el Comitente.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>6</strong><strong><span
							style="width:30.5pt; display:inline-block;">&nbsp;</span></strong><strong><u>Vigencia.</u></strong>
					El presente Contrato tendr&aacute; una duraci&oacute;n indefinida, y empezar&aacute; a surtir sus
					efectos a partir de la fecha de su firma, pudiendo darse por terminado anticipadamente, sin
					necesidad de expresi&oacute;n de causa por cualquiera de las Partes y sin necesidad de
					declaraci&oacute;n judicial, en cualquier momento, en el entendido de que aquellas obligaciones
					pendientes de realizar y que hayan sido adquiridas por cualquiera de las Partes con
					antelaci&oacute;n a la cancelaci&oacute;n del mismo, deber&aacute;n ser cumplidas en su totalidad.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">El Comisionista deber&aacute; proporcionar
					al Comitente por medios digitales, un n&uacute;mero de referencia de dicha solicitud, as&iacute;
					como la fecha y hora en que esta se recibi&oacute;. Dicha informaci&oacute;n deber&aacute;
					entregarse en el momento en que el Comitente haya presentado la solicitud de terminaci&oacute;n, o
					bien, a m&aacute;s tardar al cierre del d&iacute;a h&aacute;bil bancario en el que hayan presentado
					la solicitud de alguna de las formas pactadas al efecto en el Contrato. Adem&aacute;s, el
					Comisionista deber&aacute; cerciorarse de la autenticidad y veracidad de la identidad del Comitente
					que formule la solicitud de terminaci&oacute;n respectiva.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Una vez realizado lo anterior, el
					Comisionista deber&aacute; entregar o mantener a disposici&oacute;n del Comitente, el estado de
					operaci&oacute;n o documento en el que conste el fin de la relaci&oacute;n contractual y la
					inexistencia de adeudos de las partes.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>7</strong><strong><span
							style="width:30.5pt; display:inline-block;">&nbsp;</span></strong><strong><u>Terminaci&oacute;n</u></strong><strong>.&nbsp;</strong>Este
					Contrato podr&aacute; darse por terminado por las Partes mediante notificaci&oacute;n por escrito
					sin declaraci&oacute;n judicial, mientras el Comitente tenga dinero en inversiones de Proyectos
					vigentes, sin embargo, la terminaci&oacute;n quedar&aacute; en suspensi&oacute;n, sujeta a que cada
					una de las cantidades adeudadas al Comitente sean entregadas a &eacute;ste cuando concluya el plazo
					de vencimiento de cada pago del Proyecto de cada Solicitante a la &uacute;ltima cuenta que el
					Comitente hubiese se&ntilde;alado en el Sitio.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>&nbsp;</strong></p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">El Comisionista podr&aacute; dar por
					terminado el presente Contrato cuando el Comitente se encuentre en incumplimiento de sus
					obligaciones bajo este instrumento. Para efectos de lo anterior, cuando se actualice el
					incumplimiento del Comitente a cualquiera de las obligaciones a su cargo bajo el presente, el
					Comisionista deber&aacute;, dentro del t&eacute;rmino de 30 (treinta) d&iacute;as h&aacute;biles,
					(i) notificarle al Comitente su incumplimiento a trav&eacute;s de (a) su cuenta en el Sitio y/o (b)
					el correo registrado por el Comitente en el Sitio; (ii) incluyendo para dicho efecto (x) las causas
					que motivan el incumplimiento, (y) las disposiciones legales o referencias contractuales
					contravenidas y (z) el plazo de 30 (treinta) d&iacute;as naturales que tendr&aacute; para subsanar
					dicho incumplimiento. Posterior a dicha notificaci&oacute;n de incumplimiento del Comitente y
					habiendo concluidoel plazo para subsanar sus incumplimientos, el Comisionista dar&aacute; aviso al
					Comitente de la terminaci&oacute;n del presente Contrato mediante mensaje en su cuenta en el Sitio
					y/o mediante mensaje enviado al correo registrado por el Comitente en el Sitio, en la inteligencia
					de que el Comisionista podr&aacute; tambi&eacute;n denunciar el cr&eacute;dito, proceder contra el
					Comitente conforme a la Cl&aacute;usula 3.5.7 del presente, o ejercer cualquier acci&oacute;n legal
					que le asista para exigir el cumplimiento forzoso de este Contrato.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>8</strong><strong><span
							style="width:30.5pt; display:inline-block;">&nbsp;</span></strong><strong><u>Efectos de la
							Terminaci&oacute;n</u></strong><strong>.&nbsp;</strong>Con excepci&oacute;n de lo
					establecido en las cl&aacute;usulas 6, una vez terminado el presente Contrato, los derechos y
					obligaciones de las Partes terminar&aacute;n. En el evento en que este Contrato se diere por
					terminado por cualquier causa y el Comitente tuviera dinero en la cuenta del Sitio y no est&eacute;
					colocado en un Proyecto, entonces el Comisionista deber&aacute; devolver dichos fondos al Comitente.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>9</strong><strong><span
							style="width:30.5pt; display:inline-block;">&nbsp;</span></strong><strong><u>Relaci&oacute;n
							entre las Partes.</u></strong> Queda expresamente convenido que de ninguna manera el
					presente contrato deber&aacute; o podr&aacute; ser considerado o interpretado para crear una
					coinversi&oacute;n, sociedad, representaci&oacute;n, asociaci&oacute;n, asociaci&oacute;n en
					participaci&oacute;n o relaci&oacute;n de agencia entre las Partes para ning&uacute;n
					prop&oacute;sito.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>10</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>No
							competencia.&nbsp;</u></strong>Desde la firma del presente Contrato y durante su vigencia y
					hasta 3 (tres) a&ntilde;os despu&eacute;s de la terminaci&oacute;n del mismo, el Comitente no
					podr&aacute;, ni por cuenta propia ni a trav&eacute;s de uno o varios terceros:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>10.1</strong><span
						style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Continuar con,
					comprometerse a, tener alg&uacute;n inter&eacute;s en la participaci&oacute;n del capital social de
					cualquier sociedad, actividad o negocio en todo o en alguna parte del Territorio que sea competencia
					del Comisionista;
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>10.2</strong><span
						style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Contactar o captar el
					negocio de (o procurar o asistir en el contacto o captaci&oacute;n del negocio de) cualquier
					Solicitante o proveedor para bienes y servicios similares o iguales a aquellos previamente ofrecidos
					por el Comisionista;
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>10.3</strong><span
						style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Aceptar (o procurar o
					asistir en la aceptaci&oacute;n de) cualquier negocio de cualquier Solicitante o proveedor para
					bienes y servicios similares o iguales a aquellos ofrecidos en cualquier momento por el
					Comisionista;
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>10.4</strong><span
						style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Emplear, ofrecer
					empleo a, o solicitar el empleo o compromiso de, o de otra manera alentar a cualquier individuo que
					sea empleado, directo o indirecto de RedGirasol a terminar su relaci&oacute;n laboral con los
					mismos, sin importar si dicho individuo pudiere incurrir en alg&uacute;n incumplimiento de su
					contrato o los t&eacute;rminos del empleo al terminar el empleo con RedGirasol;
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>10.5</strong><span
						style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Procurar o asistir a
					cualquier Persona para emplear, ofrecer empleo a, o solicitar el empleo o compromiso de, o de otra
					manera alentar a cualquier individuo a terminar su relaci&oacute;n laboral con el Comisionista; o
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>10.6</strong><span
						style="width:16.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span>Interferir o intentar
					interferir con las actividades de RedGirasol, como sociedad y como Comisionista bajo el presente
					Contrato, o persuadir o intentar persuadir a cualquier Solicitante, proveedor o empleado de
					RedGirasol para descontinuar o alterar la relaci&oacute;n de dicha Persona con RedGirasol.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>11</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Impuestos</u></strong><strong>.&nbsp;</strong>Todos
					y cada uno de los impuestos, contribuciones, derechos y obligaciones que deban pagarse con motivo de
					las operaciones derivadas de las obligaciones incluidas en el presente Contrato, correr&aacute;n por
					cuenta de quien para tales efectos sea se&ntilde;alado como causante por las leyes fiscales
					aplicables.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>12</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Avisos,
							notificaciones, aclaraciones y reclamaciones.</u></strong> Todos los avisos, notificaciones,
					demandas y comunicaciones requeridas conforme a este Contrato deber&aacute;n ser enviados por correo
					electr&oacute;nico y por escrito y se considerar&aacute; que se notific&oacute; debidamente si se
					entrega personalmente o se env&iacute;a por correo certificado, correo registrado con el importe
					pre-pagado. Las Partes se&ntilde;alan como sus domicilios y direcciones convencionales para efectos
					de o&iacute;r y recibir notificaciones, los siguientes:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>Al Comisionista:&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Red Girasol, S.A.P.I. de C.V.,
					Instituci&oacute;n de Financiamiento Colectivo</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Domicilio: Eugenio Garza Sada 3820 Int.
					801, Colonia Mas Palomas,</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Monterrey, Nuevo Le&oacute;n
					M&eacute;xico, C.P. 64780</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Correo: contacto@redgirasol.com</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Atenci&oacute;n: Jorge Armando
					C&aacute;rdenas Tijerina</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>Al Comitente:&nbsp;</strong></p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">
				Nombre: {{userName}}{{ userLegalRepresentativeName }}<br>
                            Tel: {{userPhone}}<br>
                            Correo: {{userEmail}}<br>
                            Fecha: {{currentDate}}<br><br>
									 </p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Cualquier cambio o modificaci&oacute;n de
					los domicilios aqu&iacute; convenidos, deber&aacute; ser debidamente notificado por correo
					electr&oacute;nico con acuse de recibo a la otra Parte para que surta efectos con por lo menos 10
					(diez) d&iacute;as previos a la fecha en que pretenda la Parte notificante hacer cumplir dicho
					cambio de domicilio. De no cumplirse dicho requisito, la notificaci&oacute;n hecha al &uacute;ltimo
					domicilio conocido surtir&aacute; efectos plenos.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Ser&aacute;n notificadas a trav&eacute;s
					del Sitio todas los actividades relacionadas con: (i) los Proyectos en los cuales se realiz&oacute;
					dicha inversi&oacute;n, incluyendo los nuevos Proyectos y las inversiones que hayan quedado firmes,
					los montos, el plazo del Cr&eacute;dito y dem&aacute;s informaci&oacute;n relacionada y (ii) para el
					caso de la cobranza extrajudicial, el estado en que se encuentre cualquier proceso que se
					est&eacute; llevando a cabo y los actos que se est&eacute;n realizando para la cobranza de los
					financiamientos otorgados, por s&iacute; o a trav&eacute;s de terceros.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Cualquiera de las Partes de este Contrato
					podr&aacute; designar un nuevo domicilio u otro domicilio para notificaciones posteriores mediante
					notificaci&oacute;n dada por escrito y observando lo estipulado en los p&aacute;rrafos anteriores.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; text-indent:-35.25pt;"><strong><span
							style="font-size:11pt;">12.1</span></strong><span
						style="width:16pt; text-indent:0pt; font-size:11pt; display:inline-block;">&nbsp;</span><strong><span
							style="font-size:11pt;">UNE, CONDUSEF y contacto con RedGirasol.&nbsp;</span></strong><span
						style="font-size:11pt;">RedGirasol tiene a disposici&oacute;n del Comitente una Unidad
						Especializada de Atenci&oacute;n a Usuarios (UNE) con domicilio de su titular en: Ave. Eugenio
						Garza Sada 3820 interior 801 Colonia Mas Palomas, Monterrey, Nuevo Le&oacute;n C.P. 64875.Correo
						electr&oacute;nico UNE:&nbsp;</span><a href="mailto:une@redgirasol.com"
						style="text-decoration:none;"><u><span
								style="font-size:11pt; color:#0000ff;">une@redgirasol.com</span></u></a><span
						style="font-size:11pt;">, con horario de atenci&oacute;n de 9:00 a 18:00 horas (horario de la
						Ciudad de M&eacute;xico). El Comitente podr&aacute; encontrar en el domicilio de la UNE carteles
						con los montos, conceptos y periodicidad de las comisiones vigentes.</span></p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.3pt; margin-bottom:0pt; font-size:11pt;">Los datos del centro
					de atenci&oacute;n telef&oacute;nica de la CONDUSEF son los siguientes en la Ciudad de M&eacute;xico
					53400999 y del interior de la Rep&uacute;blica 018009998080, la p&aacute;gina de internet
					www.condusef.gob.mx, correo electr&oacute;nico: asesoria@condusef.gob.mx.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.3pt; margin-bottom:0pt; font-size:11pt;">RedGirasol pone a
					disposici&oacute;n del Comitente su p&aacute;gina de internet www.redgirasol.com, las cuentas de sus
					redes sociales, su programa de educaci&oacute;n financiera e informaci&oacute;n gratuita para evitar
					posibles riesgos tecnol&oacute;gicos, cibern&eacute;ticos y de fraude, mismos que se podr&aacute;n
					enviar al correo electr&oacute;nico registrado por el Comitente en el Sitio.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.25pt; margin-bottom:0pt; text-indent:-35.25pt; font-size:11pt;">
					<strong>12.2</strong><strong><span
							style="width:16pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Procedimiento
						para presentar solicitudes de aclaraci&oacute;n y reclamaciones.</strong> De conformidad con lo
					establecido en el Art&iacute;culo 6, fracci&oacute;n VII, inciso b. de las Disposiciones CONDUSEF y
					el Art&iacute;culo 23 de la Ley para la Transparencia y Ordenamiento de los Servicios Financieros,
					el Comisionista pone a disposici&oacute;n del Comitente el siguiente procedimiento mediante el cual
					podr&aacute; solicitar las aclaraciones o presentar las reclamaciones que surjan con motivo de sus
					operaciones bajo el Sitio y/o las relacionadas con este Contrato y los dem&aacute;s documentos del
					Cr&eacute;dito:
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:70.5pt; margin-bottom:0pt; text-indent:-35.25pt; font-size:11pt;">
					<strong>12.2.1</strong><strong><span
							style="width:7.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Presentaci&oacute;n
						de inconformidades.</strong> Cuando el Comitente no se encuentre satisfecho con alguno de los
					movimientos que aparezcan en el Estado de Cuenta, Estado de Operaciones y/o el Comprobante de
					Operaciones, el Comitente podr&aacute; presentar, dentro del t&eacute;rmino de 90 (noventa)
					d&iacute;as naturales contados a partir de la fecha de corte respectiva, o bien, a partir de la
					realizaci&oacute;n de la operaci&oacute;n o servicio que pretende recibir una aclaraci&oacute;n o
					controvertir, una solicitud de aclaraci&oacute;n ante la UNE de RedGirasol por escrito a
					trav&eacute;s de correo electr&oacute;nico.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:70.5pt; margin-bottom:0pt; text-indent:-35.2pt; font-size:11pt;">
					<strong>12.2.2</strong><strong><span
							style="width:7.7pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Recepci&oacute;n
						de inconformidades.</strong> Recibida la inconformidad del Comitente, RedGirasol deber&aacute;
					proceder inmediatamente a notificar su acuse de recibo mediante los Medios Oficiales, seg&uacute;n
					correspondan. Posteriormente, y dentro del plazo m&aacute;ximo de 45 (d&iacute;as) d&iacute;as
					naturales posteriores a la recepci&oacute;n de la inconformidad, RedGirasol deber&aacute; emitir el
					dictamen correspondiente. En el caso de reclamaciones relativas a operaciones realizadas en el
					extranjero, el plazo previsto en este p&aacute;rrafo ser&aacute; hasta de ciento ochenta d&iacute;as
					naturales.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:70.55pt; margin-bottom:0pt; text-indent:-35.25pt; font-size:11pt;">
					<strong>12.2.3</strong><strong><span
							style="width:7.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Contenido
						del dictamen.</strong> El dictamen emitido por RedGirasol respecto de cada inconformidad
					deber&aacute; ser emitido por escrito y debidamente firmado por un representante legal de
					RedGirasol, en la cual deber&aacute;n anexar una copia simple del documento o evidencia considerada
					para la emisi&oacute;n del dictamen en cuesti&oacute;n, tomando como base la informaci&oacute;n que
					obre en su poder respecto del Comisionista y el Sitio.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:70.55pt; margin-bottom:0pt; text-indent:-35.25pt; font-size:11pt;">
					<strong>12.2.4</strong><strong><span
							style="width:7.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Procedencia
						e improcedencia de la aclaraci&oacute;n.</strong> Cuando el dictamen emitido por RedGirasol
					diere verificativo a las pretensiones del Comitente, RedGirasol deber&aacute; cancelar la
					operaci&oacute;n controvertida y reintegrar al patrimonio del Comitente, mediante su cuenta en el
					Sitio las cantidades correspondientes. En caso de resultar improcedente la solicitud del Comitente,
					el Comitente se ver&aacute; obligado a hacer el pago de la cantidad controvertida incluyendo los
					intereses ordinarios conforme a lo pactado en el presente Contrato, sin que procedan el cobre de
					inter&eacute;s u otros accesorios en perjuicio del Comitente.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:70.6pt; margin-bottom:0pt; text-indent:-35.25pt; font-size:11pt;">
					<strong>12.2.5</strong><strong><span
							style="width:7.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Puesta
						a disposici&oacute;n del expediente.</strong> Durante el plazo de 45 (cuarenta y cinco)
					d&iacute;as naturales contados a partir de la fecha de entrega del dictamen correspondiente,
					RedGirasol pondr&aacute; a disposici&oacute;n del Comitente a trav&eacute;s del correo
					electr&oacute;nico registado en el Sitio, una copia del expediente generado con motivo de la
					reclamaci&oacute;n del Comitente, debiendo RedGirasol, en todo caso, integrarlo con toda la
					informaci&oacute;n y documentaci&oacute;n que obre en su poder, sin incluir datos correspondientes a
					operaciones relacionadas con terceras personas.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>&nbsp;</strong></p>
				<p style="margin-top:0pt; margin-left:70.6pt; margin-bottom:0pt; text-indent:-35.25pt; font-size:11pt;">
					<strong>12.2.6</strong><strong><span
							style="width:7.75pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>Derechos
						adicionales.</strong> (i) Hasta en tanto la solicitud formulada por el Comitente no quede
					resuelta de conformidad con el procedimiento aqu&iacute; previsto, RedGirasol no podr&aacute;
					reportar como vencidas las cantidades sujetas a dicha solicitud a las sociedades de
					informaci&oacute;n crediticia con las que colabora; (ii) el procedimiento aqu&iacute; contenido no
					limita los dem&aacute;s derechos y acciones que tenga el Comitente ante la CONDUSEF o las
					autoridades jurisdiccionales competentes, ni limita las sanciones que en su caso puedan aplicarle a
					RedGirasol por el incumplimiento de lo anterior.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35.3pt; margin-bottom:0pt; font-size:11pt;">En todo caso, el
					procedimiento aqu&iacute; previsto quedar&aacute; sin efectos a partir de que el Comitente presente
					su demanda ante autoridad jurisdiccional o conduzca su reclamaci&oacute;n en t&eacute;rminos y
					plazos de la Ley de Protecci&oacute;n y Defensa al Usuario de Servicios Financieros.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>13</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Cesi&oacute;n de
							Derechos.</u></strong><strong>&nbsp;</strong>El Comitente no podr&aacute; ceder ni
					transferir los derechos y obligaciones adquiridos por virtud del presente Contrato, sin el
					consentimiento previo y por escrito del Comisionista. En caso contrario, aquella cesi&oacute;n o
					trasferencia de derechos y/u obligaciones sin el consentimiento de la otra Parte ser&aacute; nula.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>14</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Informaci&oacute;n
							Confidencial.</u></strong> Las Partes convienen en que cualquier informaci&oacute;n revelada
					o proporcionada por las Partes (cualquiera de sus filiales, sociedad controladora o subsidiarias),
					as&iacute; como toda la informaci&oacute;n generada con relaci&oacute;n al cumplimiento del objeto
					del presente Contrato, ya sea de manera escrita, verbal, electr&oacute;nica, magn&eacute;tica o
					cualquier otra tecnolog&iacute;a y dem&aacute;s informaci&oacute;n confidencial, o de cualquier otra
					&iacute;ndole, ser&aacute; considerada propiedad de dicha Parte.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Las Partes no revelar&aacute;n a terceras
					Personas ninguna informaci&oacute;n propiedad de la otra Parte, debiendo utilizarla exclusivamente
					para el desarrollo del objeto de este instrumento. Una vez terminado este Contrato, las Partes
					deber&aacute;n entregar en forma inmediata a la otra Parte<span
						style="font-variant:small-caps;">&nbsp;</span>toda la informaci&oacute;n y materiales que le
					hayan sido proporcionados.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>15</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Acuerdo
							&uacute;nico y modificaciones.</u></strong> Lo contenido en este Contrato constituye el
					acuerdo total entre las Partes respecto al objeto de este Contrato. Cualquier modificaci&oacute;n a
					los t&eacute;rminos y condiciones del presente Contrato, RedGirasol le notificar&aacute; mediante a
					trav&eacute;s de los Medios Oficiales, con 30 (treinta) d&iacute;as naturales de anticipaci&oacute;n
					a la fecha que entren en vigor dichos cambios, entendi&eacute;ndose que el Comitente deber&aacute;
					otorgar su consentimiento expreso conforme a lo previsto en la Cl&aacute;usula 20 de este Contrato
					dentro de dicho plazo, de lo contrario, deber&aacute; dar su consentimiento, en t&eacute;rminos de
					la Cl&aacute;usula 20 para dar por terminado el presente Contrato.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Los avisos de modificaciones al Contrato
					contendr&aacute;n, por lo menos: I. Denominaci&oacute;n social y logotipo de RedGirasol; II.
					Domicilio y tel&eacute;fono de contacto de RedGirasol, as&iacute; como domicilio, tel&eacute;fono y
					correo electr&oacute;nico de la Unidad Especializada de Atenci&oacute;n de Usuarios
					(&ldquo;UNE&rdquo;); III. Resumen de todas las modificaciones realizadas; IV. Fecha a partir de la
					cual entran en vigor, y V. Su derecho para dar por terminado el Contrato. El Comitente puede
					solicitar la terminaci&oacute;n del Contrato dentro de los 30 (treinta) d&iacute;as posteriores al
					aviso sin responsabilidad alguna a su cargo, y bajo las condiciones pactadas originalmente, sin que
					RedGirasol pueda cobrarle penalizaci&oacute;n alguna por dicha causa ni cobrar cantidad adicional
					alguna con excepci&oacute;n de los adeudos que ya se hubiera generado a la fecha en que el Comitente
					solicite dar por terminado el Contrato. En el evento de que El Comitente no est&eacute; de acuerdo
					con las modificaciones realizadas, podr&aacute; solicitar la terminaci&oacute;n del contrato dentro
					de los 30 (treinta) d&iacute;as posteriores al aviso se&ntilde;alado en el p&aacute;rrafo anterior,
					sin responsabilidad alguna a su cargo, y bajo las condiciones anteriores a la modificaci&oacute;n,
					siempre y cuando el Comitente no tenga dinero en inversiones de Proyectos vigentes.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Las Partes podr&aacute;n celebrar anexos
					que formar&aacute;n parte integral de este Contrato en el que podr&aacute;n pactar los servicios de
					abono y riego autom&aacute;tico.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>16</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Gastos.</u></strong>
					La contraprestaci&oacute;n aqu&iacute; establecida, excluye todos los gastos y erogaciones
					judiciales que al efecto podr&iacute;an destinarse para la recuperaci&oacute;n de la cartera en caso
					de mora o incumplimiento de los Solicitantes, el Comisionista podr&aacute; cobrar al Comitente
					cualquier otro gasto o erogaci&oacute;n relacionados con el supuesto anterior y con la
					condici&oacute;n de que los mismos sean desglosados en su estado de cuenta mensual.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><a
						name="_heading_h.tyjcwt"></a><strong>17</strong><strong><span
							style="width:24.45pt; display:inline-block;">&nbsp;</span></strong><strong><u>Caso fortuito
							y fuerza mayor.</u></strong> Las Partes no ser&aacute;n responsables por la falta de
					realizaci&oacute;n de las actividades establecidas en el presente Contrato,<span
						style="font-variant:small-caps;">&nbsp;</span>cuando existan causas fuera de su control
					incluyendo &oacute;rdenes de cualesquiera autoridades gubernamentales, civiles y/o militares,
					huelgas, inundaciones, epidemias, guerras, disturbios y dem&aacute;s causas an&aacute;logas de caso
					fortuito o fuerza mayor. Lo anterior, en el entendido, adem&aacute;s, que el Comisionista cuenta con
					un plan de continuidad de negocio, para el caso de contingencias operativas, para lo cual, el
					Comisionista no podr&aacute; excusar el incumplimiento de sus actividades bajo el presente Contrato
					por la actualizaci&oacute;n de los supuestos de contingencias operativas ah&iacute; previstas. En
					caso de actualizarse cualquiera de los supuestos previstos en esta Cl&aacute;usula, el Comisionista
					deber&aacute; comunicar la existencia de dichas circunstancias al Comitente mediante los Medios
					Oficiales.&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>&nbsp;</strong></p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>18</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Ejercicio de
							Derechos.</u></strong> El hecho de que alguna de las Partes no ejerza alguno de sus derechos
					derivados del presente contrato, no significa, ni deber&aacute; ser considerado, como una renuncia o
					p&eacute;rdida de tales derechos.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>19</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Correlaci&oacute;n
							de documentos.</u></strong> Las Partes no podr&aacute;n alegar desconocimiento o
					inaplicabilidad de alguna de las normas que en cualquier documento se establezcan, por lo que
					cualquier incumplimiento de los mismos ser&aacute; un incumplimiento a los t&eacute;rminos y
					condiciones pactados en este Contrato, as&iacute; tambi&eacute;n no podr&aacute; alegarse por las
					Partes el desconocimiento&nbsp; de la terminolog&iacute;a empleada en este Contrato, sus accesorios
					y dem&aacute;s instrumentos legales relacionados con este Contrato y con la relaci&oacute;n
					jur&iacute;dica que sostengan las Partes por diversos acuerdos.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>20</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Firma.</u></strong>
					Las Partes acuerdan que para manifestar su consentimiento expreso y firmar el presente Contrato
					podr&aacute;n: (i) firmar mediante firma aut&oacute;grafa digitalizada, (ii) aut&oacute;grafamente,
					(iii) por medio de la Firma Electr&oacute;nica Avanzada emitida por el Servicio de
					Administraci&oacute;n Tributaria, y de conformidad con los art&iacute;culos 2, 7, 8, 22 y
					dem&aacute;s aplicables de la Ley de Firma Electr&oacute;nica Avanzada o (iv) por medio de cualquier
					firma electr&oacute;nica simple de conformidad con las disposiciones del C&oacute;digo de Comercio
					(incluyendo pero sin limitar a su contrase&ntilde;a de usuario en el Sitio). Cualquiera de los
					m&eacute;todos previamente anunciados tendr&aacute; como efecto el sometimiento expreso a las
					disposiciones del presente y por lo tanto surtir&aacute;n efectos plenos para las Partes, frente a
					ellos mismos y frente a terceros. As&iacute; mismo, el Comitente expresamente renuncia a argumentar
					desconocimiento de la firma del presente.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>21</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Definiciones.</u></strong>
					Las Partes expresamente acuerdan que los t&eacute;rminos definidos establecidos en el
					Ap&eacute;ndice 1 del presente y usados en el clausulado del presente contrato, tendr&aacute;n el
					alcance y significado que en los mismos se establece y no su significado literal. Asimismo, las
					Partes convienen en utilizar dichos t&eacute;rminos en los Anexos del presente contrato que al
					efecto se celebren.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><strong>22</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Encabezados y
							T&iacute;tulos de las cl&aacute;usulas.</u></strong> Los encabezados y los t&iacute;tulos de
					las Cl&aacute;usulas que aparecen en el presente Contrato, se han puesto con el exclusivo
					prop&oacute;sito de facilitar su lectura, por tanto, no necesariamente definen ni limitan el
					contenido de las mismas. Para efectos de interpretaci&oacute;n de cada Cl&aacute;usula deber&aacute;
					atenderse exclusivamente a su contenido y de ninguna manera a su t&iacute;tulo.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;"><a
						name="_heading_h.3dy6vkm"></a><strong>23</strong><strong><span
							style="width:25pt; display:inline-block;">&nbsp;</span></strong><strong><u>Legislaci&oacute;n</u></strong><strong><u>&nbsp;&nbsp;</u></strong><strong><u>y
							jurisdicci&oacute;n aplicable.</u></strong> Las Partes se someten expresamente para la
					interpretaci&oacute;n, cumplimiento y ejecuci&oacute;n de este Contrato a lo establecido en la Ley
					FinTech, con la supletoriedad establecida en el art&iacute;culo 10 de la misma</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">, as&iacute; como a la jurisdicci&oacute;n
					de los tribunales competentes de la ciudad de Monterrey, Nuevo Le&oacute;n, M&eacute;xico, respecto
					a cualquier acci&oacute;n o procedimiento, interpretaci&oacute;n y cumplimiento de todo lo pactado
					en este Contrato, renunciando expresamente a cualquier jurisdicci&oacute;n que les pudiere
					corresponder en la actualidad o en el futuro por virtud de sus domicilios actuales o futuros o por
					cualquier otra raz&oacute;n.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">El presente Contrato se firma de
					conformidad por las Partes que intervienen en el mismo, sin que mediare entre ellos vicio del
					consentimiento alguno, en la Ciudad de Monterrey, Nuevo Le&oacute;n, el {{ currentDate }}.</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">*-*-*-*-*-*</p>
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Inscripci&oacute;n en el Registro de
					Contratos de Adhesi&oacute;n de la Comisi&oacute;n Nacional para la Protecci&oacute;n y Defensa de
					los Usuarios de Servicios Financieros (CONDUSEF) No. __________________</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Fecha: ________________</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:10pt; text-align:left; line-height:6%;"><br
						style="page-break-before:always; clear:both;"></p>
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">
					<strong><u>AP&Eacute;NDICE 1</u></strong>
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">
					<strong>Definiciones</strong>
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">Para los efectos de este contrato, los
					siguientes t&eacute;rminos tendr&aacute;n el significado que a continuaci&oacute;n se
					se&ntilde;alan:</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>1</strong><strong><span
							style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&ldquo;Mercado
						Secundario&rdquo;:&nbsp;</strong>Significan aquellas actividades para facilitar la venta o
					adquisici&oacute;n de los derechos o t&iacute;tulos intercambiados que documenten los
					Cr&eacute;ditos dentro de RedGirasol que permite a los Inversionistas: (i) realizar Permutas, (ii)
					ceder sus Cr&eacute;ditos a trav&eacute;s de la Cesi&oacute;n de Derechos o (iii) ser cesionarios de
					las Cesiones de Derechos de los Cr&eacute;ditos de otros Inversionistas. Los t&eacute;rminos y
					condiciones del Mercado Secundario se encuentran en el Sitio.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>2</strong><strong><span
							style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong>&ldquo;<strong>Cesi&oacute;n(es)
						de Derechos</strong>&rdquo;: Significa el contrato de cesi&oacute;n de derechos de cobro que
					puede realizar el Comitente a trav&eacute;s del Mercado Secundario.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>3</strong><span
						style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>&quot;CONDUSEF&quot;:&nbsp;</strong>Comisi&oacute;n
					Nacional para la Protecci&oacute;n y Defensa de los Usuarios de Servicios Financieros.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>4</strong><strong><span
							style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&ldquo;Contrato&rdquo;:&nbsp;</strong>Significa
					el presente Contrato de Comisi&oacute;n, sus ap&eacute;ndices y anexos.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>5</strong><strong><span
							style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&ldquo;Cr&eacute;dito&rdquo;:&nbsp;</strong>Significa
					el contrato de Cr&eacute;dito a ser celebrado por y entre los Inversionistas, representados por
					RedGirasol, con el Solicitante para financiar el Monto.<strong>&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>6</strong><span
						style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>&ldquo;Disposiciones
						CONDUSEF&rdquo;:</strong> Significa las Disposiciones de car&aacute;cter general de la CONDUSEF
					en materia de transparencia y sanas pr&aacute;cticas aplicables a las instituciones de
					tecnolog&iacute;a financiera.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>7</strong><strong><span
							style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&ldquo;Documentos
						del Cr&eacute;dito &rdquo;:&nbsp;</strong>Significan todos y cada uno de los contratos y/o
					documentos que el Comisionista determine necesarios para documentar correctamente el otorgamiento
					del Monto al Solicitante, incluyendo pero sin limitar, a (i) el Cr&eacute;dito, (ii) un contrato de
					prenda sobre las Garant&iacute;as entre los Inversionistas y el Solicitante, (iii) un pagar&eacute;
					a ser firmado por el Solicitante en favor de los Inversionistas; y (iv) en caso de financiamiento de
					productos, un contrato de compraventa y prestaci&oacute;n de servicios entre el Solicitante y el
					proveedor.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>8</strong><strong><span
							style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;Garant&iacute;as&quot;:</strong>
					Son los activos propiedad del Solicitante, se&ntilde;alados en los Documentos de Cr&eacute;dito para
					garantizar el cumplimiento del Cr&eacute;dito, as&iacute; como cualquier otra obligaci&oacute;n
					entre el Solicitante y los Inversionistas y RedGirasol.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>9</strong><strong><span
							style="width:30.5pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;IFC&quot;:&nbsp;</strong>instituci&oacute;n
					de financiamiento colectivo, bajo t&eacute;rminos de la Ley FinTech.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>10</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;Inversionistas&quot;:</strong>
					Es la o las personas f&iacute;sicas o morales que en conjunto financiar&aacute;n a los Solicitantes
					para el desarrollo de los Proyectos. Para efectos legales, los inversionistas ser&aacute;n los
					acreditantes de los Cr&eacute;ditos.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>11</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&ldquo;Ley
						FinTech&rdquo;:</strong> Significa la Ley para Regular las Instituciones de Tecnolog&iacute;a
					Financiera.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>12</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>&ldquo;<strong>Medios
						Oficiales</strong>&rdquo;: Significa (i) el Sitio, (ii) el correo electr&oacute;nico
					contacto@redgirasol.com, (iii) el tel&eacute;fono establecido en la cl&aacute;usula d&eacute;cimo
					segunda anterior y (iv) el estado de cuenta.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>13</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>&quot;M&eacute;xico&quot;</strong>:
					Estados Unidos Mexicanos.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>14</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;Monto&quot;:</strong>
					Es la cantidad que los Inversionistas deber&aacute;n financiar al Solicitante para desarrollo del
					Proyecto y el pago de comisiones en favor del Comisionista.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;"><span
						style="width:36pt; text-indent:0pt; display:inline-block;">&nbsp;</span></p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:35pt; margin-bottom:0pt; text-indent:-35pt; font-size:11pt;">
					<strong>15</strong><strong><span
							style="width:24pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;Operaciones&quot;</strong>:
					Significa<strong>&nbsp;</strong>recibir y publicar las solicitudes de operaciones de financiamiento
					colectivo de deuda, conforme el art&iacute;culo 16, fracci&oacute;n I de la Ley Fintech.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>16</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;Parte&quot;:</strong>
					Lo constituyen el Comisionista o el Comitente en forma indistinta y separada, seg&uacute;n
					corresponda.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>17</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;Partes&quot;:</strong>
					Lo constituyen el Comisionista y el Comitente en forma conjunta.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>18</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>&quot;<strong>Permuta&rdquo;
						o &ldquo;Permutas&rdquo;:&nbsp;</strong>Significa la permuta o permutas a ser realizadas previa
					instrucci&oacute;n del Comitente y a valor nominal de sus derechos de acreedor sobre la totalidad de
					sus Cr&eacute;ditos, obteniendo a cambio la Cartera de Cr&eacute;ditos o bien, una vez perteneciendo
					al Mercado Secundario, la permuta o permutas parcial (es) a valor nominal de la Cartera de
					Cr&eacute;ditos obteniendo a cambio derechos de acreedor de uno o m&aacute;s Cr&eacute;ditos.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>19</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>&quot;<strong>Persona&quot;:</strong>
					Significa e incluye personas f&iacute;sicas, corporaciones, sociedades, sociedades en comandita
					simple, sociedades de responsabilidad limitada, empresas de responsabilidad limitada, sociedades
					generales, sociedades an&oacute;nimas, coinversiones, asociaciones, empresas, fundaciones de
					intereses privados, fideicomisos, bancos, fiduciarios, fideicomisos inmobiliarios, fideicomisos
					comerciales u otras organizaciones, sean personas morales o no, y todas las Autoridades
					Gubernamentales.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>20</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;Proyecto&quot;:</strong>
					Es el destino de los recursos solicitado por el Solicitante para el financiamiento del Monto por
					Inversionistas y el pago de comisiones en favor del Comisionista, formalizado a trav&eacute;s de los
					Documentos del Cr&eacute;dito.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>21</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;RedGirasol&quot;:</strong>
					Es la persona moral Red Girasol, S.A.P.I. de C.V., Instituci&oacute;n de Financiamiento Colectivo.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>22</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>&ldquo;<strong>Rendimiento</strong>&rdquo;:
					Significa los intereses ordinarios y moratorios efectivamente cobrados por el Comisionista en
					representaci&oacute;n del Comitente.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>23</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>&ldquo;<strong>Retiro
						Ordinario</strong>&rdquo;: Significa el derecho a solicitar un retiro por d&iacute;a sin costo.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>24</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&ldquo;Servicios&rdquo;:</strong>
					Ser&aacute;n todo tipo de actividades tendientes a promover los Proyectos de los Solicitantes a los
					Inversionistas, las cuales entre otras incluir&aacute;n, la exhibici&oacute;n de los Proyectos en el
					Sitio, la realizaci&oacute;n de todo tipo de actividades mediante las cuales se promueva la
					inversi&oacute;n de los Inversionistas en los Proyectos, incluyendo la entrega de todo tipo de
					informaci&oacute;n relativa a los Proyectos. Una vez que el Comitente opte por invertir en un
					Proyecto, el Comisionista deber&aacute; conseguir el Monto a financiarse con m&aacute;s
					Inversionistas hasta financiar completamente el Proyecto y el Solicitante pueda obtener el Monto una
					vez que haya firmado los Documentos del Cr&eacute;dito . El Comisionista realizar&aacute; el cobro
					correspondiente al Cr&eacute;dito m&aacute;s sus intereses.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt;"><strong><span
							style="font-size:11pt;">25</span></strong><strong><span
							style="width:25pt; text-indent:0pt; font-size:11pt; display:inline-block;">&nbsp;</span></strong><strong><span
							style="font-size:11pt;">&quot;Sitio&quot;:&nbsp;</span></strong><a href="http://www.redgirasol.com"
						style="text-decoration:none;"><u><span
								style="font-size:11pt; color:#0000ff;">www.redgirasol.com</span></u></a></p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>26</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>&quot;Solicitante&quot;</strong>:
					Es toda persona f&iacute;sica o moral, susceptible de obtener el Monto para el Proyecto derivado de
					la promoci&oacute;n efectuada por el Comisionista a trav&eacute;s del financiamiento con los
					Inversionistas.
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>27</strong><strong><span
							style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span></strong><strong>&quot;Territorio&quot;:</strong>
					Significa el territorio que comprenden los Estados Unidos Mexicanos.
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>&nbsp;</strong>
				</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>28</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span><strong>&quot;UNE&quot;</strong>:
					Unidad Especializada de Atenci&oacute;n de Usuarios
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt;">&nbsp;</p>
				<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; font-size:11pt;">
					<strong>29</strong><span
						style="width:25pt; text-indent:0pt; display:inline-block;">&nbsp;</span>&ldquo;<strong>Usuario
						Inactivo</strong>&rdquo;: Significa el Comitente que (i) no ha realizado transacciones de
					aportaci&oacute;n, retiro o inversi&oacute;n en Proyectos en un periodo de 30 (treinta) d&iacute;as
					naturales y/o (ii) no tiene a su favor obligaciones generadas por uno o varios Proyectos.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import dateHelper from '@mixins/dateHelper';
import stpHelper from "@mixins/stpHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";

export default {
	name: "LatestInvestorMerchantContract",
	mixins: [dateHelper, stpHelper, investorInfoHelper],
	props: {
		fillUserData: {
			type: Boolean
			, default: false
		},
		signedDate: { required: false, default: null },
		onlyCoverPage: { required: false, default: false }
	},
	data() {
		return {
			stpClabe: null
		}
	},
	async mounted() {
		this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
	},
	computed: {
		userName: function () {
			if (this.fillUserData && this.user) {
				return this.user.complete_name;
			} else {
				return "";
			}
		},
		personTypeText: function () {
			if (this.fillUserData && this.user) {
				if (!this.userIsMoral(this.user.user_person_type)) {
					return " por su propio derecho";
				}
			} else {
				return "";
			}
		},
		userLegalRepresentativeName: function () {
			if (this.fillUserData && this.user) {
				let name = "";
				if (this.userIsMoral(this.user.user_person_type)) {
					name = " representada en este acto por " + this.user.legal_representative_name;
				}
				return name;
			} else {
				return "";
			}
		},
		userEmail: function () {
			if (this.fillUserData) {
				return (this.user === null) ? "-" : this.user.email;
			} else {
				return "";
			}
		},
		userPhone: function () {
			if (this.fillUserData) {
				return (this.user === null) ? "-" : this.user.phone;
			} else {
				return "";
			}
		},
		currentDate: function () {
			if (this.signedDate) {
				return this.signedDate;
			} else {
				return this.signatureFormatDate(new Date());
			}
		},
		currentClabe: function () {
			if (this.stpClabe) {
				return this.stpClabe;
			} else {
				return "____________";
			}
		},
	},
}
</script>

<style scoped>
ol {
	list-style-type: none;
	counter-reset: item;
	margin: 0;
	padding: 0;
}

ol>li {
	display: table;
	counter-increment: item;
	margin-bottom: 0.6em;
}

ol>li:before {
	content: counters(item, ".") ". ";
	display: table-cell;
	padding-right: 0.6em;
}

li ol>li {
	margin: 0;
}

li ol>li:before {
	content: counters(item, ".") " ";
}

.parent {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: auto 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	margin-top: 1rem;
	margin-bottom: 3rem;
	font-size: 14px;
}

.parent>div {
	border: solid .5px #d2d2d2;
	padding-left: 2rem;
}

.list-disc {
	list-style-type: disc !important;
	margin-left: 3rem !important;
}

.div1 {
	grid-area: 3 / 1 / 4 / 3;
	text-align: center;
}

.div2 {
	grid-area: 3 / 3 / 4 / 5;
	text-align: center;
}

.div3 {
	grid-area: 3 / 5 / 4 / 7;
	text-align: center;
}

.div4 {
	grid-area: 1 / 1 / 2 / 7;
}

.div5 {
	grid-area: 2 / 1 / 3 / 7;
}

.div6 {
	grid-area: 4 / 1 / 5 / 3;
}

.div7 {
	grid-area: 4 / 3 / 5 / 5;
}

.div8 {
	grid-area: 4 / 5 / 5 / 7;
}

.div9 {
	grid-area: 5 / 1 / 6 / 7;
	text-align: center;
}

.div10 {
	grid-area: 6 / 1 / 7 / 4;
}

.div11 {
	grid-area: 6 / 4 / 7 / 7;
}

.div12 {
	grid-area: 7 / 1 / 9 / 7;
}

.div13 {
	grid-area: 9 / 1 / 10 / 7;
	text-align: center;
}

.div14 {
	grid-area: 10 / 1 / 11 / 3;
}

.div15 {
	grid-area: 10 / 3 / 11 / 5;
}

.div16 {
	grid-area: 10 / 5 / 11 / 7;
}

.div17 {
	grid-area: 11 / 1 / 12 / 7;
}

.div18 {
	grid-area: 12 / 1 / 13 / 7;
}

.div19 {
	grid-area: 13 / 1 / 14 / 7;
}

.awlist1 {
	list-style: none;
	counter-reset: awlistcounter3_1 1
}

.awlist1>li:before {
	content: '1.' counter(awlistcounter3_1) '.';
	counter-increment: awlistcounter3_1
}

.awlist2 {
	list-style: none;
	counter-reset: awlistcounter3_1 2
}

.awlist2>li:before {
	content: '1.' counter(awlistcounter3_1) '.';
	counter-increment: awlistcounter3_1
}

.awlist3 {
	list-style: none;
	counter-reset: awlistcounter3_1 3
}

.awlist3>li:before {
	content: '1.' counter(awlistcounter3_1) '.';
	counter-increment: awlistcounter3_1
}

.awlist4 {
	list-style: none;
	counter-reset: awlistcounter3_1 4
}

.awlist4>li:before {
	content: '1.' counter(awlistcounter3_1) '.';
	counter-increment: awlistcounter3_1
}

.awlist5 {
	list-style: none;
	counter-reset: awlistcounter3_1 5
}

.awlist5>li:before {
	content: '1.' counter(awlistcounter3_1) '.';
	counter-increment: awlistcounter3_1
}

.awlist6 {
	list-style: none;
	counter-reset: awlistcounter3_1 6
}

.awlist6>li:before {
	content: '1.' counter(awlistcounter3_1) '.';
	counter-increment: awlistcounter3_1
}

.awlist7 {
	list-style: none;
	counter-reset: awlistcounter3_1 7
}

.awlist7>li:before {
	content: '1.' counter(awlistcounter3_1) '.';
	counter-increment: awlistcounter3_1
}

.awlist8 {
	list-style: none;
	counter-reset: awlistcounter3_1 8
}

.awlist8>li:before {
	content: '1.' counter(awlistcounter3_1) '.';
	counter-increment: awlistcounter3_1
}

.awlist9 {
	list-style: none;
	counter-reset: awlistcounter4_1
}

.awlist9>li:before {
	content: '3.' counter(awlistcounter4_1) '.';
	counter-increment: awlistcounter4_1
}

.awlist10 {
	list-style: none;
	counter-reset: awlistcounter4_1 1
}

.awlist10>li:before {
	content: '3.' counter(awlistcounter4_1) '.';
	counter-increment: awlistcounter4_1
}

.awlist11 {
	list-style: none;
	counter-reset: awlistcounter4_1 2
}

.awlist11>li:before {
	content: '3.' counter(awlistcounter4_1) '.';
	counter-increment: awlistcounter4_1
}

.awlist12 {
	list-style: none;
	counter-reset: awlistcounter7_0
}

.awlist12>li:before {
	content: counter(awlistcounter7_0);
	counter-increment: awlistcounter7_0
}
</style>